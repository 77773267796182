import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DocumentationIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M13 9a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm-1 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Z" />
      <path d="M11 3a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H11Zm14 2v18H11V5h14Z" />
      <path d="M5 7a2 2 0 0 1 2-2v22h18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7Z" />
    </BaseIcon>
  );
};
