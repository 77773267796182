import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import {
  CreateProjectBody,
  ProjectResponse,
  UploadProjectResponseType,
} from './types';

export const getProject = (projectId?: string) => {
  return doGet<ProjectResponse>(`projects/${projectId}`, LOGGER_TYPE.PROJECT);
};

export const createProject = (body: CreateProjectBody) => {
  return doPost('projects', LOGGER_TYPE.PROJECT, body);
};

export const importProject = (file: File, dryRun = true) => {
  const accountId = store.getState().accountInfo.id;
  const params = dryRun ? `?dry_run=true` : ``;

  const bodyFormData = new FormData();
  bodyFormData.append('project_name', file.name.split('.')[0]);
  const blob = new Blob([file], { type: 'application/zip' });
  bodyFormData.append('zip', blob);

  return doPut<UploadProjectResponseType>(
    `projects/zip/${accountId}${params}`,
    LOGGER_TYPE.PROJECT,
    bodyFormData,
    '',
    {
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
    },
    true,
  );
};

export const updateProject = ({
  projectId,
  projectName,
}: {
  projectId: string;
  projectName: string;
}) => {
  return doPut(`projects/${projectId}`, LOGGER_TYPE.PROJECT, {
    project_name: projectName,
  });
};

export const deleteProject = (projectId: string) => {
  return doDelete(`projects/${projectId}`, LOGGER_TYPE.PROJECT);
};

export const exportProject = (projectId: string) => {
  return doGet(
    `projects/zip/${projectId}`,
    LOGGER_TYPE.PROJECT,
    null,
    '',
    null,
    true,
  );
};

export const getEnabledFeatures = (accountId: string, projectId?: string) => {
  return doGet(`${accountId}/${projectId}/features`, LOGGER_TYPE.PROJECT);
};
