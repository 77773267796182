import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AlertMessage } from '@polyai/ui/components/atoms/Alert/AlertMessage';

import api from 'api';
import { Lock, Locks, UnLockResponse } from 'api/types';
import { setIsDraft } from 'ducks/lock/lockSlice';
import {
  setHasAccess,
  setPageNotFound,
} from 'ducks/pageStatus/pageStatusSlice';
import {
  setCurrentPolicyVersion,
  setRefreshProjectAfterDeletingDraft,
} from 'ducks/version/versionSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAlert } from 'hooks/useAlert';

export type LockError = {
  errorMessage: string;
} | null;

export type LockContextType = {
  readOnly: boolean;
  hasLock: boolean;
  requestLock: () => Promise<unknown>;
  releaseLock: () => Promise<unknown>;
  verifyLockStatus: () => void;
  locks: Locks;
  isDraft: boolean;
};

export const LockContext = createContext<LockContextType>({
  readOnly: false,
  hasLock: false,
  requestLock: () => new Promise(() => {}),
  releaseLock: () => new Promise(() => {}),
  verifyLockStatus: () => {},
  locks: {},
  isDraft: false,
});

export function LockProvider({ children }: { children: React.ReactNode }) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const login = useAppSelector((state) => state.auth.login);
  const projectId = useAppSelector((state) => state.projectInfo.id);
  const accountId = useAppSelector((state) => state.accountInfo.id);
  const isDraft = useAppSelector((state) => state.lock.isDraft);
  const currentPolicyVersion = useAppSelector(
    (state) => state.version.currentPolicyVersion,
  );
  const dispatch = useAppDispatch();

  const [readOnly, setReadOnly] = useState(false);
  const resolverRef = useRef<Function>();

  const [locks, setLocks] = useState<Locks>({});

  const hasLock = isDraft;
  const stringifiedLocks = JSON.stringify(locks);
  let isMounted = false;
  // Lock query
  const lock = useQuery<Lock, Error>(
    ['lock', projectId, currentPolicyVersion],
    () => api.lock(projectId, accountId, currentPolicyVersion),
    { enabled: false, retry: false },
  );

  // Unlock query
  const unlock = useQuery<UnLockResponse, Error>(
    ['unlock'],
    () => api.unlock(projectId, accountId),
    {
      enabled: false,
      retry: false,
    },
  );

  const verifyLockStatus = useCallback(async () => {
    if (!projectId || !accountId || !isMounted) return;
    const locksResponse = await api.getLockStatus().catch((e) => {
      const error = JSON.parse(e.message);
      if (error.error_code === 'forbidden') dispatch(setHasAccess(false));

      if (error.error_code === 'PROJECT_NOT_FOUND')
        dispatch(setPageNotFound(true));
      if (error.error_code === 'ACCOUNT_NOT_FOUND')
        dispatch(setPageNotFound(true));
    });
    if (JSON.stringify(locksResponse) !== JSON.stringify(locks[projectId])) {
      const updatedLocks: Locks = {};
      updatedLocks[locksResponse.project_id] = locksResponse;
      setLocks({ ...locks, ...updatedLocks });
      setReadOnly(locksResponse?.locked && !locksResponse?.caller_holds_lock);
    }
  }, [dispatch, accountId, isMounted, locks, setLocks, projectId]);

  useEffect(() => {
    // taken from https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    // to prevent this hook from loading when unmounted
    isMounted = true;
    verifyLockStatus();
    return () => {
      isMounted = false;
    };
  }, [hasLock, projectId, lock.error, unlock.error]);

  // Async lock function that returns a promise
  const requestLock = useCallback(async () => {
    if (hasLock) {
      return Promise.resolve();
    } else if (readOnly) {
      return Promise.reject(Error('Lock error. Read-only project.'));
    } else if (!currentPolicyVersion) {
      return Promise.reject(Error('Lock error. No policy version to lock.'));
    } else {
      return new Promise(async (resolve) => {
        resolverRef.current = resolve;
        lock.refetch({ throwOnError: true }).catch((error) => {
          if (error.error === 'login_required') {
            login(window.location.href);
            return;
          }
          const json = JSON.parse(error.message);
          alert.error(
            <AlertMessage title="Lock error detected">
              <>
                Message: {json.error_message}
                <br />
                Error code: {json.error_code}
              </>
            </AlertMessage>,
          );
        });
      });
    }
  }, [stringifiedLocks, projectId, currentPolicyVersion, readOnly, alert]);

  // Async unlock function
  const releaseLock = useCallback(async () => {
    if (!hasLock) {
      return Promise.resolve();
    } else if (readOnly) {
      return Promise.reject(Error('Unlock error. Read-only project.'));
    } else if (!currentPolicyVersion) {
      return Promise.reject(
        Error('Unlock error. No policy version to unlock.'),
      );
    } else {
      return new Promise(async (resolve) => {
        resolverRef.current = resolve;
        unlock
          .refetch({ throwOnError: true })
          .then(() => {
            dispatch(setIsDraft(false));
            alert.success(<AlertMessage title="Draft deleted successfully" />);
          })
          .catch((error) => {
            if (error.error === 'login_required') {
              login(window.location.href);
              return;
            }
            const json = JSON.parse(error.message);
            alert.error(
              <AlertMessage title="Unlock error detected">
                <>
                  Message: {json.error_message}
                  <br />
                  Error code: {json.error_code}
                </>
              </AlertMessage>,
            );
          });
      });
    }
  }, [
    unlock,
    stringifiedLocks,
    projectId,
    currentPolicyVersion,
    readOnly,
    alert,
  ]);

  // update lock state after lock request completes
  useEffect(() => {
    if (lock.data && resolverRef.current) {
      console.info('Obtained project lock', lock.data);
      const updatedLocks: Locks = {};
      updatedLocks[lock.data.project_id] = lock.data;
      setLocks({ ...locks, ...updatedLocks });
      resolverRef.current();
      resolverRef.current = undefined;
      dispatch(setIsDraft(true));
    }
  }, [lock.data, currentPolicyVersion, setLocks]);

  // update lock state after unlock request completes
  useEffect(() => {
    if (!projectId) return;
    if (unlock.data && resolverRef.current) {
      console.info('Released project lock', unlock.data);
      const newLocks = { ...locks };
      delete newLocks[projectId];
      setLocks(newLocks);
      // reload policy and layout
      dispatch(setCurrentPolicyVersion(unlock.data.version));
      dispatch(setRefreshProjectAfterDeletingDraft(true));
      queryClient.invalidateQueries();
      resolverRef.current();
      resolverRef.current = undefined;
      dispatch(setIsDraft(false));
    }
  }, [unlock.data, setLocks, queryClient]);

  const value: LockContextType = {
    readOnly,
    hasLock,
    requestLock,
    releaseLock,
    verifyLockStatus,
    locks,
    isDraft,
  };

  return (
    <LockContext.Provider value={value}> {children} </LockContext.Provider>
  );
}

export const useLock = () => useContext(LockContext);
