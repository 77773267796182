/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeSystemIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M20 16a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
      <path d="M14 4a1 1 0 0 0-.958.713l-.954 3.18a8.996 8.996 0 0 0-1.152.666l-3.232-.764a1 1 0 0 0-1.096.473l-2 3.464a1 1 0 0 0 .138 1.186l2.278 2.418a9.13 9.13 0 0 0 0 1.329l-2.278 2.417a1 1 0 0 0-.138 1.186l2 3.464a1 1 0 0 0 1.096.473l3.232-.764c.366.25.75.473 1.152.667l.954 3.18A1 1 0 0 0 14 28h4a1 1 0 0 0 .958-.713l.954-3.18a8.992 8.992 0 0 0 1.152-.666l3.232.764a1 1 0 0 0 1.096-.473l2-3.464a1 1 0 0 0-.138-1.186l-2.278-2.418a9.135 9.135 0 0 0 0-1.329l2.278-2.417a1 1 0 0 0 .138-1.186l-2-3.464a1 1 0 0 0-1.096-.473l-3.232.764a8.988 8.988 0 0 0-1.152-.667l-.954-3.18A1 1 0 0 0 18 4h-4Zm3.598 3.142.67 2.234a6.941 6.941 0 0 1 2.332 1.348l2.271-.537 1.161-.275 1.256 2.176-.816.866-1.601 1.7A6.956 6.956 0 0 1 23 16a7.064 7.064 0 0 1-.13 1.347l1.602 1.699.816.866-1.256 2.176-1.161-.275-2.27-.537a6.993 6.993 0 0 1-2.332 1.348l-.67 2.234L17.255 26h-2.512l-.342-1.142-.67-2.234a6.951 6.951 0 0 1-2.332-1.348l-2.271.537-1.161.275-1.256-2.176.816-.866 1.601-1.7A6.942 6.942 0 0 1 9 16a7.055 7.055 0 0 1 .13-1.347l-1.602-1.699-.816-.866 1.256-2.176 1.161.275 2.27.537a6.993 6.993 0 0 1 2.331-1.348l.672-2.234L14.744 6h2.512l.342 1.142Z" />
    </BaseIcon>
  );
};
