import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ConversationIssueField,
  ISSUE_OPTIONS,
  IssueOptions,
} from 'components/organisms/IssueReporter/IssueReporter.constants';

import {
  ConversationAlert,
  ConversationTag,
  ConversationTurn,
} from 'api/resources/conversation/types';

export interface ConversationState {
  activeTurn?: ConversationTurn;
  conversationAlert?: ConversationAlert;
  elapsedTimeAtReport: number | null;
  isIssueReporterOpen: boolean;
  isShowingOriginal: boolean;
  issues: IssueOptions;
  isLiveConversation: boolean;
  pollConversationDetails: boolean;
  hasUserExported: boolean;
}

const initialState: ConversationState = {
  activeTurn: undefined,
  conversationAlert: undefined,
  elapsedTimeAtReport: null,
  isIssueReporterOpen: false,
  isShowingOriginal: true,
  issues: ISSUE_OPTIONS,
  isLiveConversation: false,
  pollConversationDetails: false,
  hasUserExported: false,
};

const initialiseIssuesForTurn = (
  state: ConversationState,
  turn: ConversationTurn,
) => {
  turn?.reviews?.forEach((review) => {
    const { issue_type, correction } = review;
    if (issue_type in state.issues) {
      state.issues[issue_type] = {
        ...state.issues[issue_type],
        isActive: true,
        field: {
          ...state.issues[issue_type].field,
          value: correction,
        },
      };
    }
  });
};

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setActiveTurn: (
      state,
      { payload: turn }: PayloadAction<ConversationTurn>,
    ) => {
      state.activeTurn = turn;

      if (state.isIssueReporterOpen) {
        initialiseIssuesForTurn(state, turn);
      }
    },
    openIssueReporter: (
      state,
      { payload: turn }: PayloadAction<ConversationTurn>,
    ) => {
      state.isIssueReporterOpen = true;
      state.activeTurn = turn;
      initialiseIssuesForTurn(state, turn);
    },
    closeIssueReporter: (state) => {
      state.isIssueReporterOpen = false;
    },
    toggleIssue: (
      state,
      {
        payload: { issueName, defaultValue },
      }: PayloadAction<{
        issueName: keyof IssueOptions;
        defaultValue?: ConversationIssueField['value'];
      }>,
    ) => {
      if (issueName === ConversationTag.NO_ISSUE) {
        Object.values(state.issues).forEach((issue) => {
          const { tag } = issue;
          // tag true for 'no issue', false for everything else
          state.issues[tag].isActive = tag === ConversationTag.NO_ISSUE;
        });
      } else {
        const issue = state.issues[issueName];
        issue.isActive = !issue.isActive;

        if (issue.isActive && !issue?.field?.value) {
          issue.field = { ...(issue.field || {}), value: defaultValue };
        }

        state.issues[ConversationTag.NO_ISSUE].isActive = false; // set 'no issue' to false
      }
    },
    correctIssue: (
      state,
      {
        payload: { issueName, value },
      }: PayloadAction<{ issueName: keyof IssueOptions; value: string }>,
    ) => {
      const issue = state.issues[issueName];
      state.issues[issueName] = { ...issue, field: { ...issue.field, value } };
    },
    clearIssues: (state) => {
      state.issues = ISSUE_OPTIONS;
    },
    setConversationAlert: (
      state,
      { payload: alert }: PayloadAction<ConversationAlert>,
    ) => {
      state.conversationAlert = alert;
    },
    clearConversationAlert: (state) => {
      state.conversationAlert = undefined;
    },
    setIsShowingOriginal: (
      state,
      { payload: isShowingOriginal }: PayloadAction<boolean>,
    ) => {
      state.isShowingOriginal = isShowingOriginal;
    },
    setElapsedTimeAtReport: (
      state,
      { payload: timeInSeconds }: PayloadAction<number | null>,
    ) => {
      state.elapsedTimeAtReport = timeInSeconds;
    },
    setIsLiveConversation: (
      state,
      { payload: isLiveConversation }: PayloadAction<boolean>,
    ) => {
      state.isLiveConversation = isLiveConversation;
    },
    setPollConversationDetails: (
      state,
      { payload: pollConversationDetails }: PayloadAction<boolean>,
    ) => {
      state.pollConversationDetails = pollConversationDetails;
    },
    setHasUserExported: (state) => {
      state.hasUserExported = true;
    },
  },
});

export const {
  clearConversationAlert,
  clearIssues,
  closeIssueReporter,
  correctIssue,
  openIssueReporter,
  setActiveTurn,
  setConversationAlert,
  setElapsedTimeAtReport,
  setIsShowingOriginal,
  setIsLiveConversation,
  setPollConversationDetails,
  toggleIssue,
  setHasUserExported,
} = conversationSlice.actions;

export default conversationSlice.reducer;
