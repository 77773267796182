import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

type LoaderProps = {
  $spinner: boolean;
  $fullScreen: boolean;
  $inProjectPage: boolean;
};

export const Loader = styled.div<LoaderProps>`
  ${(p) =>
    p.$spinner &&
    css`
      display: flex;
      flex-direction: column;
      gap: ${themeSpacing.sm2};
      align-items: center;
      justify-content: center;

      svg {
        transform-origin: top;
        transform: scale(2);
        color: ${themeColors.iconPrimaryBrand};
      }
    `}

  ${(p) =>
    p.$fullScreen &&
    p.$inProjectPage &&
    css`
      width: 100%;
      height: 70vh;
    `}
  
  ${(p) =>
    p.$fullScreen &&
    !p.$inProjectPage &&
    css`
      width: 100%;
      height: 100vh;
    `}
`;
