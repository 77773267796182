import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DeploymentIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M13 13.5h6v6h-6v-6Zm-1-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8Z" />
      <path d="M27 8.414a2 2 0 0 0 0-2.828l-2.293-2.293a1 1 0 1 0-1.414 1.414L24.586 6H7.5a2 2 0 0 0-2 2v17a2 2 0 0 0 2 2H20a1 1 0 1 0 0-2H7.5V8h17.086l-1.293 1.293a1 1 0 0 0 1.414 1.414L27 8.414Z" />
    </BaseIcon>
  );
};
