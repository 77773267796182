import { RefObject, useCallback, useLayoutEffect, useRef } from "react";

const useHideAfterClose = (
  open: boolean,
  overrideRef?: RefObject<HTMLDivElement>
) => {
  const ref = useRef<HTMLDivElement>(null);

  const onAnimationStart = useCallback(() => {
    const refToUse = overrideRef || ref;
    if (!refToUse.current) {
      return;
    }

    if (open) {
      refToUse.current.style.removeProperty("display");
    }
  }, [open, overrideRef]);

  const onAnimationEnd = useCallback(() => {
    const refToUse = overrideRef || ref;
    if (!refToUse.current) {
      return;
    }

    if (!open) {
      refToUse.current.style.display = "none";
    }
  }, [open, overrideRef]);

  useLayoutEffect(() => {
    const refToUse = overrideRef || ref;
    if (!open && refToUse.current) {
      refToUse.current.style.display = "none";
    }
  }, [overrideRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ref, onAnimationStart, onAnimationEnd };
};

export default useHideAfterClose;
