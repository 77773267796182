import React from "react";

import { AlertTemplateProps } from "react-alert";

import { IconButton } from "components/atoms/IconButton";
import { CloseIcon, ErrorIcon } from "components/atoms/Icons";
import { SuccessIcon } from "components/atoms/Icons/SuccessIcon";

import { InfoIcon } from "../Icons/InfoIcon";

import * as Styled from "./Alert.styled";

export const ALERT_TIMEOUT = 5000;

export const Alert: React.FC<AlertTemplateProps> = ({
  style,
  options,
  message,
  close,
}) => (
  <Styled.AlertContainer
    data-test-id="alert-message"
    style={style}
    type={options.type}
  >
    <Styled.AlertIcon>
      {options.type === "info" && <InfoIcon color="iconPrimaryInverse" />}
      {options.type === "success" && <SuccessIcon color="iconPrimaryInverse" />}
      {options.type === "error" && <ErrorIcon color="iconPrimaryInverse" />}
    </Styled.AlertIcon>
    <Styled.AlertBody>
      {message}
      <IconButton data-test-id="alert-window-close-button" onClick={close}>
        <CloseIcon />
      </IconButton>
    </Styled.AlertBody>
  </Styled.AlertContainer>
);
