import { forwardRef, TextareaHTMLAttributes, useCallback } from "react";

export type KeyboardSafeTextAreaPropTypes = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "onKeyPress" | "onKeyPressCapture" | "onKeyDownCapture" | "onKeyUpCapture"
> & {
  isKeyboardSafe?: boolean;
};

/**
 * This component stops propagation of keyboard events to the document
 * so that our keyboard shortcut listeners are not triggered.
 *
 * It should be used instead of <textarea/> tags unless absolutely
 * necessary.
 */
export const KeyboardSafeTextArea = forwardRef<
  HTMLTextAreaElement,
  KeyboardSafeTextAreaPropTypes
>((props, ref) => {
  const wrapKeyboardFn = useCallback(
    (fnName: keyof KeyboardSafeTextAreaPropTypes) =>
      (...args: any[]) => {
        props[fnName]?.(...args);
        if (!props.isKeyboardSafe) {
          args[0]?.stopPropagation?.();
        }
      },
    [props]
  );

  return (
    <textarea
      {...props}
      ref={ref}
      role="textbox"
      onKeyDown={wrapKeyboardFn("onKeyDown")}
      onKeyUp={wrapKeyboardFn("onKeyUp")}
    />
  );
});
