import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const TriggerIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M19.892 5.303c.366-1.617-1.775-2.544-2.705-1.172L8.701 16.659C8.026 17.655 8.74 19 9.943 19h3.403l-1.738 7.697c-.366 1.617 1.775 2.544 2.705 1.172l8.486-12.528c.675-.996-.039-2.341-1.242-2.341h-3.403l1.738-7.697Z" />
    </BaseIcon>
  );
};
