import { createSlice } from '@reduxjs/toolkit';

import { createNewDeployment } from 'actions';

export interface ChatPanelState {
  isDeploymentPanelOpen: boolean;
}

const initialState: ChatPanelState = {
  isDeploymentPanelOpen: false,
};

const deploymentPanelSlice = createSlice({
  name: 'deploymentPanel',
  initialState,
  reducers: {
    openDeploymentPanel: (state) => {
      state.isDeploymentPanelOpen = true;
    },
    closeDeploymentPanel: (state) => {
      state.isDeploymentPanelOpen = false;
    },
    toggleDeploymentPanel: (state) => {
      state.isDeploymentPanelOpen = !state.isDeploymentPanelOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewDeployment, (state) => {
      state.isDeploymentPanelOpen = true;
    });
  },
});

export const {
  openDeploymentPanel,
  closeDeploymentPanel,
  toggleDeploymentPanel,
} = deploymentPanelSlice.actions;

export default deploymentPanelSlice.reducer;
