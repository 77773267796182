export const COLUMN_ID_SELECT_BOX = 'selectBox';
export const COLUMN_ID_AUDIO_PLAY = 'audio-play';
export const COLUMN_ID_TEXT = 'text';
export const COLUMN_ID_FLOW = 'flows';
export const COLUMN_ID_TAGS = 'tags';
export const COLUMN_ID_STATUS = 'statuses';
export const COLUMN_ID_NODES = 'nodes';
export const COLUMN_ID_LAST_UPDATED = 'last_modified';

export const AUDIO_TAGS_MISSING_AUDIO = 'No audio';
export const AUDIO_TAGS_NOT_USED_IN_POLICY = 'Unmapped';
export const AUDIO_TABLE_PAGE_SIZE = 20;

export const UPLOAD_STATUS_NOT_UPLOADING = 'not uploading';
export const UPLOAD_STATUS_UPLOADING = 'uploading';
export const UPLOAD_STATUS_COMPLETED = 'completed';
