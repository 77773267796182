import { UploadMethod } from '@polyai/common/constants/GlobalNew.constants';

export interface FileData {
  files: File[] | DropzoneFile[];
  dryRun: boolean;
  successCB: Function;
  errorCB: Function;
}

export const FILE_TOO_LARGE_ERROR = 'File too large';
export const SUBFOLDERS_ERROR = 'Folders cannot contain subfolders';
export const WRONG_FILE_TYPE = 'Wrong file type';

export enum UploadType {
  AUDIO = 'audio',
  INTENTS = 'intents',
  DATA_STORE = 'data_store',
  SCENARIOS = 'scenarios',
}

export const text = {
  [UploadType.INTENTS]: {
    title: 'Import intents',
    choose_file_title: 'Choose a file',
    choose_file_body:
      'Import a CSV to add, replace or delete intents and their corresponding training data. You can download a template for the CVS format here.',
    choose_file_button_text: 'Select file...',
    replace_file_button_text: 'Replace file...',
    choose_folder_button_text: 'Select folder...',
    replace_folder_button_text: 'Replace folder...',
    choose_env_body: 'Data will only be imported to the selected environment.',
    preview_changes: {
      radio_1: {
        text: 'Add NLU data',
        value: UploadMethod.ADD,
      },
      radio_2: {
        text: 'Replace NLU data',
        value: UploadMethod.UPDATE,
      },
    },
  },
  [UploadType.DATA_STORE]: {
    title: 'Import data',
    choose_file_title: 'Choose files',
    choose_file_body: `Import JSON files to create advanced logic for the virtual agent.
      \nTo upload multiple folders, drag and drop them onto the box.
      \nMax file size is 200kb.
      `,
    choose_file_button_text: 'Select files...',
    replace_file_button_text: 'Replace files...',
    choose_folder_button_text: 'Select folder...',
    replace_folder_button_text: 'Replace folder...',
    choose_env_body: 'Data will only be imported to the selected environment.',
    preview_changes: {
      radio_1: {
        text: 'Skip import for files with same names',
        value: UploadMethod.SKIP,
      },
      radio_2: {
        text: 'Replace files with same names',
        value: UploadMethod.UPDATE,
      },
    },
  },
  [UploadType.AUDIO]: {
    title: '',
    choose_file_title: 'Choose files',
    choose_file_body: '',
    choose_file_button_text: 'Select files...',
    replace_file_button_text: 'Replace files...',
    choose_folder_button_text: 'Select folder...',
    replace_folder_button_text: 'Replace folder...',
    choose_env_body: 'Data will only be imported to the selected environment.',
    preview_changes: {
      radio_1: {
        text: 'Add data',
        value: UploadMethod.ADD,
      },
      radio_2: {
        text: 'Update data',
        value: UploadMethod.UPDATE,
      },
    },
  },
  [UploadType.SCENARIOS]: {
    title: 'Import data',
    choose_file_title: 'Choose files',
    choose_file_body: `Import a CSV to add, replace scenarios. Your CSV needs to have specific headers: you can download a template for the CSV format here.`,
    choose_file_button_text: 'Select files...',
    replace_file_button_text: 'Replace files...',
    choose_folder_button_text: 'Select folder...',
    replace_folder_button_text: 'Replace folder...',
    choose_env_body:
      'Scenarios will only be imported to the selected environment.',
    preview_changes: {
      radio_1: {
        text: 'Add and merge scenarios',
        value: UploadMethod.ADD,
      },
      radio_2: {
        text: 'Replace scenario set',
        value: UploadMethod.REPLACE,
      },
    },
  },
};
export interface DropzoneFile extends File {
  fullPath: string;
}

export const fileRestrictions = {
  csv: {
    extension: '.csv',
    fileType: 'text/csv',
    fileSize: 5000,
  },
  json: {
    extension: '.json',
    fileType: 'application/json',
    fileSize: 200,
  },
  wav: {
    extension: '.wav',
    fileType: '',
    fileSize: 10000,
  },
  default: {
    extension: '*',
    fileType: '',
    fileSize: 5000,
  },
};
