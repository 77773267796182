import dayjs from "dayjs";

export const extractDomainFromEmail = (email?: string): string => {
  // Regular expression to match the domain part of an email address
  const regex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

  if (!email) {
    return "Unknown";
  }

  // Extract domain using regex.exec and return the matched domain or null if no match
  const match = regex.exec(email);
  if (match && match[1]) {
    return match[1];
  } else {
    return "Unknown";
  }
};

const lineBreak = "%0D%0A";

export const createSupportBody = (
  extraBullets?: string[],
  firstName?: string,
  lastName?: string
) => `Dear Support Team,${lineBreak}${lineBreak}
I am writing to report an error I encountered while using your platform. Below are the details of the issue:${lineBreak}${lineBreak}
Date and time: ${dayjs().toString()}${lineBreak}${lineBreak}
URL: ${window.location.pathname}${lineBreak}${lineBreak}
${extraBullets?.join(`${lineBreak}${lineBreak}`) ?? ""}${
  (extraBullets && `${lineBreak}${lineBreak}`) ?? ""
}
I would appreciate your assistance in resolving this matter. Please let me know if you require any further information or clarification.${lineBreak}${lineBreak}
Best regards${lineBreak}
${firstName ? `${firstName} ${lastName ?? ""}` : ""}`;

export const createAddCreditBody = (
  firstName?: string,
  lastName?: string
) => `Dear Support Team,${lineBreak}${lineBreak}
I am writing to request that my balance be topped up by $X.${lineBreak}${lineBreak}
Best regards${lineBreak}
${firstName ? `${firstName} ${lastName ?? ""}` : ""}`;
