import { doDelete, doGet, doPatch, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { RegisterAudioBodyType } from 'hooks/useAudioUpload';
import { store } from 'reduxStore';
import {
  AudioPath,
  AudioTagResponse,
  EditPromptResponse,
  PromptResponse,
  PromptsResponse,
  SlotResponse,
  TTSCoverageResponse,
} from './types';

export const getPrompts = (
  language: string = 'en-GB',
  queryString?: string,
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<PromptsResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}`,
    LOGGER_TYPE.PROMPTS,
    undefined,
    queryString,
  );
};

export const getPrompt = (language: string = 'en-GB', promptId: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<PromptResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}/${promptId}`,
    LOGGER_TYPE.PROMPTS,
  );
};

export const putPrompts = (texts: string[], language: string = 'en-GB') => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut<PromptsResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}`,
    LOGGER_TYPE.PROMPTS,
    {
      version: gitVersion,
      texts,
    },
  );
};

export const deletePrompts = (
  promptIds: string[] | number[],
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doDelete(
    `${accountId}/${projectId}/tts/prompts/${language}`,
    LOGGER_TYPE.PROMPTS,
    {
      prompt_ids: promptIds,
      version: gitVersion,
    },
  );
};

export const patchPrompt = (
  promptId: string,
  newPromptText: string,
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPatch<EditPromptResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}/${promptId}`,
    LOGGER_TYPE.PROMPTS,
    {
      text: newPromptText,
      version: gitVersion,
    },
  );
};

export const getAudio = (promptId: string, language: string = 'en-GB') => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<AudioPath>(
    `${accountId}/${projectId}/tts/audios/${language}/${promptId}`,
    LOGGER_TYPE.PROMPTS,
  );
};

export const registerAudios = (
  promptAudioMap: RegisterAudioBodyType,
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/tts/audios/${language}`,
    LOGGER_TYPE.PROMPTS,
    {
      prompt_audio_mapping: promptAudioMap,
      version: gitVersion,
    },
  );
};

export const putAudioTags = (
  promptIds: string[],
  tags: string[],
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut<AudioTagResponse>(
    `${accountId}/${projectId}/tts/audios/${language}/tags`,
    LOGGER_TYPE.PROMPTS,
    {
      prompt_ids: promptIds,
      tags,
      version: gitVersion,
    },
  );
};

export const deleteAudioTags = (
  promptIds: string[],
  tags: string[],
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doDelete<AudioTagResponse>(
    `${accountId}/${projectId}/tts/audios/${language}/tags`,
    LOGGER_TYPE.PROMPTS,
    {
      prompt_ids: promptIds,
      tags,
      version: gitVersion,
    },
  );
};

export const patchSlot = (promptIds: string[], language: string = 'en-GB') => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPatch<SlotResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}/slot`,
    LOGGER_TYPE.PROMPTS,
    {
      prompt_ids: promptIds,
      version: gitVersion,
    },
  );
};

export const getTTSCoverageForText = (
  text: string,
  language: string = 'en-GB',
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPost<TTSCoverageResponse>(
    `${accountId}/${projectId}/tts/prompts/${language}/tts_coverage`,
    LOGGER_TYPE.PROMPTS,
    {
      text,
    },
  );
};
