import { ReactElement } from 'react';

import {
  AlertContainerFactory,
  AlertCustomOptions as DefaultAlertCustomOptions,
  useAlert as useAlertLib,
} from 'react-alert';

import {
  AlertMessageType,
  useBaseAlert,
} from '@polyai/common/hooks/useBaseAlert';
import { usePathname } from '@polyai/common/hooks/useRouter';

import { getAlertMessage, logToDataDog } from 'lib/ddlogHelper';
import { getPageTab } from 'lib/miscHelper';

const getLogContent = (
  message: ReactElement<AlertMessageType> | string,
  pathname: string,
) => {
  const page = getPageTab(pathname);
  if (typeof message !== 'string') {
    const { innerText, title } = getAlertMessage(message);
    return {
      page,
      alert_message: innerText,
      alert_title: title,
    };
  }
  return {
    page,
    alert_message: message,
  };
};

export interface AlertCustomOptions extends DefaultAlertCustomOptions {
  additionalType?: 'warning';
}
// This is a simple wrapper over `react-alert` that makes it possible for us to tailor
// the different methods how we want, e.g we don't want errors to automatically disappear
export const useAlert = () => {
  const alert = useAlertLib<AlertContainerFactory<AlertCustomOptions>>();
  const pathname = usePathname();

  return useBaseAlert(alert, pathname, getLogContent, logToDataDog);
};
