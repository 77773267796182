import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ZoomInIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M14 11a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3Z" />
    <path d="M15 25a9.958 9.958 0 0 0 6.329-2.257l4.464 4.464a1 1 0 0 0 1.414-1.414l-4.464-4.464A9.958 9.958 0 0 0 25 15c0-5.523-4.477-10-10-10S5 9.477 5 15s4.477 10 10 10Zm0-2a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z" />
  </BaseIcon>
);
