import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataItem } from '@polyai/common/constants/GlobalNew.constants';

import { projectInit, resetUploadStatus } from 'actions';
import { LanguageType } from 'api/resources/language/types';
import { UploadProjectResponseType } from 'api/resources/project/types';
import { VariantResponse } from 'api/resources/variant/types';

export interface ProjectInfoState {
  id: string;
  isDeployedToSandbox: boolean;
  projectImportError: string;
  projectImportFileStatus: DataItem[];
  projectImportPanelOpen: boolean;
  activeLanguage: LanguageType | undefined;
  activeVariant: VariantResponse | undefined;
}

export const initialState: ProjectInfoState = {
  id: '',
  isDeployedToSandbox: false,
  projectImportError: '',
  projectImportFileStatus: [],
  projectImportPanelOpen: false,
  activeLanguage: undefined,
  activeVariant: undefined,
};

const projectSlice = createSlice({
  name: 'projectInfo',
  initialState,
  reducers: {
    setProjectId: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
    setIsDeployedToSandbox: (
      state,
      isDeployedToSandbox: PayloadAction<boolean>,
    ) => {
      state.isDeployedToSandbox = isDeployedToSandbox.payload;
    },
    setProjectImportError: (
      state,
      { payload: error }: PayloadAction<string>,
    ) => {
      state.projectImportError = error;
    },
    setProjectImportFileStatus: (
      state,
      {
        payload: fileStatusMetadata,
      }: PayloadAction<UploadProjectResponseType['file_status_metadata']>,
    ) => {
      state.projectImportFileStatus = Object.entries(fileStatusMetadata).map(
        ([key, fileStatus]) => ({
          title: key,
          status: fileStatus,
        }),
      );
    },
    openProjectImportPanel: (state) => {
      state.projectImportPanelOpen = true;
    },
    closeProjectImportPanel: (state) => {
      state.projectImportPanelOpen = false;
    },
    setActiveLanguage: (
      state,
      { payload: language }: PayloadAction<LanguageType>,
    ) => {
      state.activeLanguage = language;
    },
    setActiveVariant: (
      state,
      { payload: variant }: PayloadAction<VariantResponse>,
    ) => {
      state.activeVariant = variant;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(resetUploadStatus, (state) => {
        state.projectImportFileStatus = [];
        state.projectImportError = '';
      });
  },
});

export const {
  closeProjectImportPanel,
  openProjectImportPanel,
  setProjectImportError,
  setProjectImportFileStatus,
  setActiveLanguage,
  setActiveVariant,
  setIsDeployedToSandbox,
  setProjectId,
} = projectSlice.actions;

export default projectSlice.reducer;
