/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const SupportIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M9.5 13.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm5 1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm7-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Z" />
      <path d="m12.5 24 3.5 4 3.5-4H26a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6.5Zm3.5.963L13.408 22H6V8h20v14h-7.407L16 24.963Z" />
    </BaseIcon>
  );
};
