/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const WeatherIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M17 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0V4Zm11 10a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2h1Zm-.902-7.134a1 1 0 0 0-1.366-.366l-.866.5a1 1 0 1 0 1 1.732l.866-.5a1 1 0 0 0 .366-1.366ZM9.866 6.5a1 1 0 1 0-1 1.732l.866.5a1 1 0 0 0 1-1.732l-.866-.5Z" />
    <path d="m24.94 20.688.225.017A4.154 4.154 0 0 1 24.846 29H7.808A4.808 4.808 0 0 1 7 19.45a5.502 5.502 0 0 1 4.003-5.244 7 7 0 1 1 12.741 3.795 5.477 5.477 0 0 1 1.196 2.687ZM23 14a5 5 0 0 0-10 0v.022a5.498 5.498 0 0 1 4.133 2.512A5.478 5.478 0 0 1 19.5 16c.983 0 1.905.258 2.703.71A4.986 4.986 0 0 0 23 14Zm-6.598 5.1-.953-1.486A3.5 3.5 0 0 0 9 19.468l-.013 1.675-1.652.28A2.808 2.808 0 0 0 7.808 27h17.038a2.154 2.154 0 0 0 .166-4.3l-1.816-.14-.234-1.58a3.501 3.501 0 0 0-4.967-2.641l-1.593.76Z" />
  </BaseIcon>
);
