import { ReactNode } from "react";

import { ComponentWithChildren } from "@polyai/common/types/helpers";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import Tooltip from "../Tooltip";

import * as Styled from "./HelpText.styled";

export interface HelpTextProps {
  help?: ReactNode;
}

const HelpText: ComponentWithChildren<HelpTextProps> = ({ children, help }) => {
  return (
    <ConditionalWrapper
      condition={!!help}
      wrapper={(children) => (
        <Styled.TooltipWrapper>
          <Tooltip content={help} placement="top-start">
            <Styled.LabelWithHelp data-test-id="help-text-label" role="tooltip">
              {children}
            </Styled.LabelWithHelp>
          </Tooltip>
        </Styled.TooltipWrapper>
      )}
    >
      <span>{children}</span>
    </ConditionalWrapper>
  );
};

export default HelpText;
