import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { BattlebotResponse, BattlebotsReview } from './types';

export const getBattlebots = (candidate_quantity: number = 2) => {
  return doGet<BattlebotResponse>(
    `battlebots/candidates?num_candidates=${candidate_quantity}`,
    LOGGER_TYPE.BATTLEBOTS,
  );
};

export const postBattlebots = (review: BattlebotsReview) => {
  return doPost('battlebots/reviews', LOGGER_TYPE.BATTLEBOTS, review);
};
