import styled from 'styled-components';

import { Logo as BaseLogo } from '@polyai/ui/components/atoms/Logo';
import themeColors from '@polyai/ui/styles/config/colors';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
`;

export const Content = styled.div`
  width: ${themeSpacing.xxl1};
  margin-top: ${themeSpacing.md3};

  a {
    width: 100%;
    margin-top: ${themeSpacing.sm4};
  }

  button {
    width: 100%;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing.sm4};
  align-items: center;

  padding: ${themeSpacing.xs3};

  text-align: center;
`;

export const Logo = styled(BaseLogo)`
  color: ${themeColors.textBrand};
`;

export const LogoutButton = styled.button`
  margin-top: ${themeSpacing.sm4};

  svg {
    width: ${themeSpacing.sm1};
    height: ${themeSpacing.sm1};
    color: ${themeColors.textSecondary};
    transition: color 200ms;
  }

  &:hover {
    svg {
      color: ${themeColors.textBrand};
    }
  }
`;

export const RefreshLink = styled.span`
  cursor: pointer;
  color: ${themeColors.textBrand};
`;

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${themeSpacing.xs3};

  button {
    width: auto;
  }
`;
