import { MutableRefObject, useLayoutEffect, useState } from "react";

const useIsMoreScroll = (
  scrollableRef: MutableRefObject<HTMLDivElement | null>,
  { isDisabled, height }: { isDisabled?: boolean; height: number } = {
    height: 80,
  }
) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMoreScrollTop, setIsMoreScrollTop] = useState(0);
  const [isMoreScrollBottom, setIsMoreScrollBottom] = useState(0);

  useLayoutEffect(() => {
    const sidebar = scrollableRef.current;
    if (!sidebar || isDisabled) {
      return;
    }

    let timeout: NodeJS.Timeout | undefined;

    const handleScroll = () => {
      setIsMoreScrollTop(Math.min(height, sidebar.scrollTop));
      setIsMoreScrollBottom(
        Math.min(
          height,
          Math.abs(
            sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight
          )
        )
      );

      if (timeout) {
        clearTimeout(timeout);
      }
      setIsScrolling(true);
      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    handleScroll();

    sidebar.addEventListener("scroll", handleScroll);
    return () => {
      sidebar.removeEventListener("scroll", handleScroll);
    };
  }, [height, isDisabled, scrollableRef]);

  return {
    isScrolling,
    isMoreScrollTop,
    isMoreScrollBottom,
  };
};

export default useIsMoreScroll;
