import { ReactNode } from 'react';

import { usePathname } from '@polyai/common/hooks/useRouter';
import { ComponentWithChildren } from '@polyai/common/types/helpers';
import { Loader } from '@polyai/ui/components/atoms/Loader';

import { useAppSelector } from 'hooks/reduxHooks';
import useFeatureSwitch from 'hooks/useFeatureSwitch';
import ErrorPage from 'screens/ErrorPage';
import { ErrorStatus } from 'screens/ErrorPage';

/*
 A wrapper for a page, which will redirect to error pages if necessary,
 based on
  - feature switches
  - authentication
  - API response codes
 If no error page is required, it will display the normal route
 */
const PrivatePage: ComponentWithChildren<{ loader?: ReactNode }> = ({
  children,
  loader,
}) => {
  const hasAccess = useAppSelector((state) => state.pageStatus.hasAccess);
  const pageNotFound = useAppSelector((state) => state.pageStatus.pageNotFound);
  const isSiteUnavailable = useAppSelector(
    (state) => state.pageStatus.isSiteUnavailable,
  );
  const pathname = usePathname();

  const { isLoadingFeatures } = useFeatureSwitch();

  if (isLoadingFeatures && pathname === '/') {
    return loader || <Loader inProjectPage={false} fullScreen spinner />;
  }

  if (isSiteUnavailable) {
    return <ErrorPage type={ErrorStatus.UNAVAILABLE} />;
  }
  if (!hasAccess) {
    return <ErrorPage type={ErrorStatus.FORBIDDEN} />;
  }
  if (pageNotFound) {
    return <ErrorPage type={ErrorStatus.NOT_FOUND} />;
  }

  return <>{children}</>;
};

export default PrivatePage;
