import styled from 'styled-components';

import { PopupMenuItem } from '@polyai/ui/components/molecules/PopupMenu';
import themeColors from '@polyai/ui/styles/config/colors';
import sizes from '@polyai/ui/styles/config/sizes';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const LandingNavWrapper = styled.div`
  position: relative;
  z-index: 11;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${sizes.headerHeight}px;
  padding: ${themeSpacing.xs1} 28px 0;

  background-color: ${themeColors.backgroundInverse};
  box-shadow: 0 1px 1px 0 ${themeColors.shadow};
`;

export const LogoWrapper = styled.div`
  color: ${themeColors.textPrimaryInverse};
`;

export const AccountActionsMenu = styled.div`
  color: ${themeColors.background};
`;

export const AccountActionsMenuButton = styled.button`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
  color: ${themeColors.background};
`;

export const PopupMenuButton = styled(PopupMenuItem)`
  display: flex;
  gap: ${themeSpacing.xs2};
  align-items: center;
`;
