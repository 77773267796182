/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const SendMessageIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M6.447 5.106a1 1 0 0 0-1.39 1.224L8.44 16l-3.385 9.67a1 1 0 0 0 1.391 1.224l20-10a1 1 0 0 0 0-1.788l-20-10ZM10.21 15 7.758 7.997 23.764 16 7.759 24.003 10.209 17H18a1 1 0 1 0 0-2h-7.79Z" />
  </BaseIcon>
);
