import { useCallback, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

import { Loader } from '@polyai/ui/components/atoms/Loader';

import api from 'api';
import {
  setGetAuthToken,
  setIsAuthorised,
  setLogin,
  setLogout,
  setShowUnauthorised,
  setShowUserQuestionnaire,
  setUser,
} from 'ducks/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import PortalErrorPage, { ErrorStatus } from 'screens/ErrorPage';
import Login from 'screens/LoginScreen';

export const Auth = ({ children }: any) => {
  const isAuthorised = useAppSelector((state) => state.auth.isAuthorised);
  const showUnauthorised = useAppSelector(
    (state) => state.auth.showUnauthorised,
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  const login = useCallback(
    async (path: string) => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            returnTo: path,
          },
        });
      }
    },
    [isAuthenticated, loginWithRedirect],
  );

  const authUser = useCallback(async () => {
    try {
      const { isAuthorised, user } = await api.authoriseUser();
      dispatch(setIsAuthorised(isAuthorised));
      dispatch(setShowUnauthorised(!isAuthorised));
      if (user && user.is_first_login) {
        dispatch(setShowUserQuestionnaire(true));
      }
    } catch (e) {
      dispatch(setIsAuthorised(false));
      dispatch(setShowUnauthorised(true));
    }
  }, [dispatch]);

  useEffect(() => {
    const logoutActions = () => {
      dispatch(setShowUnauthorised(false));
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    };

    dispatch(setLogin(login));

    dispatch(
      setLogout(() => {
        logoutActions();
      }),
    );

    dispatch(
      setGetAuthToken(async () => {
        try {
          return await getAccessTokenSilently();
        } catch (e) {
          logoutActions();
        }
      }),
    );
  }, [dispatch, getAccessTokenSilently, login, logout]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated || !user) {
      if (location.pathname !== '/') {
        login(window.location.pathname);
      }
      return;
    }

    if (isAuthenticated && !isAuthorised && location.pathname !== 'callback') {
      authUser();
    }
  }, [isAuthenticated, isAuthorised, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  if (isAuthenticated && user && isAuthorised) {
    return children;
  }

  if (showUnauthorised) {
    return <PortalErrorPage type={ErrorStatus.UNAUTHORIZED} />;
  }

  if (location.pathname === '/' && !isAuthenticated) {
    return <Login />;
  }

  return <Loader inProjectPage={false} fullScreen spinner />;
};
