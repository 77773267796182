/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HeadsetIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 5a8 8 0 0 0-8 8v11H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h2v-2C6 7.477 10.477 3 16 3s10 4.477 10 10v2h2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2v1a3 3 0 0 1-3 3h-5.268A2 2 0 0 1 14 27a2 2 0 0 1 3.732-1H23a1 1 0 0 0 1-1V13a8 8 0 0 0-8-8ZM4 17v5h2v-5H4Zm22 0v5h2v-5h-2Z" />
    </BaseIcon>
  );
};
