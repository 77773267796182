import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from './reduxHooks';

const useAuthToken = () => {
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  const [isTokenError, setIsTokenError] = useState(false);
  const [token, setToken] = useState();
  const getAuthToken = useAppSelector((state) => state.auth.getAuthToken);

  const getToken = useCallback(async () => {
    try {
      const token = await getAuthToken();
      setToken(token);
      setIsTokenLoading(false);
    } catch (e) {
      console.error('Error occurred. Could not get auth token.', e);
      setIsTokenLoading(false);
      setIsTokenError(true);
    }
  }, [getAuthToken]);

  useEffect(() => {
    void getToken();
  }, [getToken]);

  return { token, isTokenLoading, isTokenError };
};

export default useAuthToken;
