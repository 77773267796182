/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeInteractiveIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M9.5 12.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm5 1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm7-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Z" />
      <path d="M3 7v21l6.5-5.308H27a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Zm24 13.692H8.787L5 23.785V7h22v13.692Z" />
    </BaseIcon>
  );
};
