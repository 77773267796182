/* eslint-disable max-len */
import { FC } from "react";

import { useTheme } from "styled-components";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ErrorIcon: FC<IconProps> = ({ customFill, ...props }) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();

  return (
    <BaseIcon customFill={customFill} {...props}>
      <path
        d="M16 6.656 26.62 26H5.38L16 6.656Zm-1.753-.963L3.627 25.038C2.895 26.37 3.859 28 5.38 28h21.24c1.521 0 2.485-1.63 1.754-2.962L17.754 5.693c-.76-1.383-2.748-1.383-3.507 0Z"
        fill={customFill ? colors.borderStrong : undefined}
      />
      {customFill && (
        <path d="M4.5 26.5 16 5l11.5 21.5h-23Z" fill={colors.iconDanger} />
      )}
      <path
        d="M15 13a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0v-6Zm0 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
        fill={customFill ? colors.background : undefined}
      />
    </BaseIcon>
  );
};
