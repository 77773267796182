import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { projectInit } from 'actions';
import {
  NotificationActivity,
  NotificationDescriptor,
} from 'api/resources/notifications';

interface NotificationsState {
  notifications: Record<string, NotificationDescriptor>;
  preflightNotifications: Record<string, NotificationDescriptor>;
  isPolling: boolean;
  lastPollDateUnix: number;
}

const initialState: NotificationsState = {
  notifications: {},
  preflightNotifications: {},
  isPolling: false,
  lastPollDateUnix: dayjs().subtract(2, 'weeks').unix(),
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<NotificationDescriptor[]>,
    ) => {
      for (const descriptor of action.payload) {
        state.notifications[descriptor.id] = descriptor;
      }
    },
    setPreflightNotifications: (
      state,
      action: PayloadAction<NotificationDescriptor[]>,
    ) => {
      for (const descriptor of action.payload) {
        state.preflightNotifications[descriptor.id] = descriptor;
      }
    },
    pushPreflightNotifications: (
      state,
      action: PayloadAction<{ preflightId: string; notificationId: string }[]>,
    ) => {
      for (const { preflightId, notificationId } of action.payload) {
        const descriptor = state.preflightNotifications[preflightId];
        state.notifications[notificationId] = {
          ...descriptor,
          id: notificationId,
        };
        delete state.preflightNotifications[preflightId];
      }
    },
    updateNotificationStates: (
      state,
      action: PayloadAction<NotificationActivity[]>,
    ) => {
      for (const activity of action.payload) {
        state.notifications[activity.notification_id].state = activity.state;
      }
    },
    updatePreflightNotificationStates: (
      state,
      action: PayloadAction<NotificationActivity[]>,
    ) => {
      for (const activity of action.payload) {
        state.preflightNotifications[activity.notification_id].state =
          activity.state;
      }
    },
    setIsPolling: (state, action: PayloadAction<boolean>) => {
      state.isPolling = action.payload;
    },
    setLastPollDateUnix: (state, action: PayloadAction<number>) => {
      state.lastPollDateUnix = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const {
  setIsPolling,
  setNotifications,
  updateNotificationStates,
  updatePreflightNotificationStates,
  setLastPollDateUnix,
  setPreflightNotifications,
  pushPreflightNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
