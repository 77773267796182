import { doDelete, doGet, doPatch, doPost, doPut } from 'api/request';
import { LOGGER_TYPE, Node } from 'api/types';
import { store } from '../../reduxStore';

export type FlowsJsonType = {
  id: string;
  name: string;
  tags: string[];
  description: string;
  trigger: boolean;
  numberOfVariants?: number;
};

export type FlowsType = {
  flows: FlowsJsonType[];
};

export interface FlowsResponse {
  flows: FlowMetadata[];
  tags: string[];
  version: string;
  draft: boolean;
}

export interface FlowMetadata {
  description: string;
  id: string;
  name: string;
  tags: string[];
  trigger: boolean;
}

export interface PolicyResponse {
  imports: string;
  nodes: Node[];
  path: string;
  type: string;
  valid: boolean;
}

export interface FlowResponse {
  all_tags: string[];
  description: string;
  id: string;
  name: string;
  policy: PolicyResponse;
  tags: string[];
  trigger: boolean;
  version: string;
  draft: boolean;
}

export interface CreateFlowResponse {
  all_tags: [];
  description: string;
  id: string;
  name: string;
  policy: {
    imports: string;
    nodes: [];
    path: string;
    type: string;
    valid: boolean;
  };
  tags: [];
  trigger: boolean;
  version: string;
}

export const MISSING_PROMPT_ERROR = 'MISSING_PROMPT';

export const getFlows = (keyword: string = '', language: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet<FlowsResponse>(
    `${accountId}/${projectId}/flows`,
    LOGGER_TYPE.FLOWS,
    {
      keyword,
      tts_lang_code: language,
    },
  );
};

export const createFlow = ({
  name,
  variantId,
}: {
  name: string;
  variantId?: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost<CreateFlowResponse>(
    `${accountId}/${projectId}/flows?version=${gitVersion}`,
    LOGGER_TYPE.FLOWS,
    {
      name: name,
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
      variant_id: variantId,
    },
  );
};

export const duplicateFlow = ({
  flowId,
  variantId,
}: {
  flowId: string;
  variantId?: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost<CreateFlowResponse>(
    `${accountId}/${projectId}/flows`,
    LOGGER_TYPE.FLOWS,
    {
      source_flow_id: flowId,
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
      variant_id: variantId,
    },
  );
};

export const duplicateFlows = (ids: string[]) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost(`${accountId}/${projectId}/flows`, LOGGER_TYPE.FLOWS, {
    source_flow_ids: ids,
    account_id: accountId,
    project_id: projectId,
    version: gitVersion,
  });
};

export const detachFlow = ({
  flowId,
  variantId,
}: {
  flowId: string;
  variantId: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost<FlowResponse>(
    `${accountId}/${projectId}/flows`,
    LOGGER_TYPE.FLOWS,
    {
      source_flow_id: flowId,
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
      variant_id: variantId,
      detach: true,
    },
  );
};

export const updateFlow = ({
  id,
  name,
  tags,
  description,
}: {
  id: string;
  name: string;
  tags: string[];
  description: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut<CreateFlowResponse>(
    `${accountId}/${projectId}/flows/${id}`,
    LOGGER_TYPE.FLOWS,
    {
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
      flow: {
        id: id,
        name,
        tags,
        description,
      },
    },
  );
};

export const deleteFlow = (id: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doDelete(`${accountId}/${projectId}/flows/${id}`, LOGGER_TYPE.FLOWS, {
    account_id: accountId,
    project_id: projectId,
    version: gitVersion,
  });
};
export const deleteFlows = (ids: string[]) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPatch(`${accountId}/${projectId}/flows?`, LOGGER_TYPE.FLOWS, {
    flows: ids,
    account_id: accountId,
    project_id: projectId,
    version: gitVersion,
  });
};

export const getFlow = (
  projectId: string,
  flowId: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;

  return doGet(`${accountId}/${projectId}/flows/${flowId}`, LOGGER_TYPE.FLOWS, {
    tts_lang_code: language,
  });
};

export const putFlows = ({
  flows,
  tags,
}: {
  flows: string[];
  tags: string[];
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut(`${accountId}/${projectId}/flows`, LOGGER_TYPE.FLOWS, {
    tags,
    flows,
    version: gitVersion,
  });
};
