import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NoNumberIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12.5919 21.7424L6.41421 27.9201L5 26.5059L25.5061 5.99976L26.9203 7.41398L17.5768 16.7575C17.7232 16.8327 17.8676 16.8833 18.032 16.9246C18.3743 17.0105 18.6375 16.8891 19.325 16.4023L19.4092 16.3423C19.99 15.928 20.9487 15.244 22.2273 15.4997C22.5457 15.5634 22.8254 15.7242 23.0302 15.863C23.2516 16.013 23.4736 16.1988 23.6853 16.3957C24.1097 16.7906 24.5537 17.2875 24.953 17.7858C25.3532 18.2851 25.7277 18.8096 26.0079 19.2686C26.1473 19.4969 26.2735 19.7253 26.3682 19.9369C26.4455 20.1093 26.5667 20.4063 26.5667 20.7229C26.5667 21.3632 26.3114 21.8797 25.993 22.3132C25.703 22.7082 25.3029 23.1082 24.899 23.512L24.8596 23.5514C24.2161 24.1948 23.247 24.7339 22.2337 25.0546C21.2205 25.3753 20.0283 25.5202 18.9396 25.2232C17.6456 24.8703 15.1125 23.7975 12.5919 21.7424ZM16.1269 18.2074C16.5474 18.5052 16.9933 18.7259 17.5451 18.8644C18.8516 19.1923 19.8313 18.496 20.421 18.0769L20.4807 18.0345C21.1602 17.5534 21.4482 17.3959 21.8033 17.455C21.818 17.4625 21.8524 17.4808 21.9083 17.5187C22.0166 17.592 22.1565 17.7051 22.3229 17.86C22.6549 18.1688 23.0325 18.5875 23.3924 19.0366C23.7515 19.4847 24.0741 19.9392 24.3009 20.3109C24.4152 20.4979 24.4951 20.6477 24.5431 20.7547C24.5496 20.7692 24.555 20.7819 24.5596 20.7929C24.545 20.8621 24.5024 20.9642 24.3811 21.1294C24.1918 21.3871 23.9015 21.681 23.4454 22.1371C23.0821 22.5004 22.4188 22.8982 21.6302 23.1478C20.8414 23.3975 20.0621 23.4563 19.4658 23.2937C18.4447 23.0152 16.2479 22.1011 14.0142 20.3201L16.1269 18.2074Z"
      fillRule="evenodd"
    />
    <path d="M12.5759 13.8952C12.7306 14.5115 12.988 14.9958 13.3407 15.4601L14.7754 14.0253C14.6458 13.8196 14.572 13.6324 14.5158 13.4083C14.4298 13.0659 14.5513 12.8027 15.0381 12.1152L15.098 12.031C15.5124 11.4502 16.1964 10.4915 15.9406 9.21295C15.877 8.89453 15.7161 8.61481 15.5774 8.40999C15.4274 8.18864 15.2416 7.96667 15.0446 7.75496C14.6497 7.33055 14.1528 6.88657 13.6545 6.4872C13.1553 6.08703 12.6307 5.71257 12.1717 5.4323C11.9435 5.29294 11.715 5.16677 11.5034 5.07198C11.3311 4.99476 11.034 4.87354 10.7174 4.87354C10.0772 4.87354 9.56061 5.12883 9.12713 5.44718C8.73213 5.73727 8.33214 6.13737 7.92838 6.54124L7.88899 6.58064C7.24551 7.22412 6.70645 8.19325 6.38575 9.20655C6.06509 10.2197 5.92019 11.4119 6.21712 12.5006C6.57655 13.8185 7.68271 16.4217 9.81281 18.988L11.2338 17.567C9.37797 15.2852 8.43099 13.017 8.14664 11.9744C7.98404 11.3782 8.04288 10.5988 8.29253 9.81004C8.54213 9.02138 8.93995 8.3581 9.3032 7.99486C9.7593 7.53876 10.0532 7.24845 10.311 7.05917C10.4762 6.93784 10.5783 6.89518 10.6475 6.88067C10.6585 6.8852 10.6712 6.89065 10.6857 6.89716C10.7927 6.9451 10.9424 7.02505 11.1295 7.13927C11.5011 7.36617 11.9557 7.68869 12.4037 8.04781C12.8528 8.40772 13.2715 8.78533 13.5803 9.11728C13.7352 9.28375 13.8483 9.42365 13.9217 9.53193C13.9595 9.58779 13.9779 9.62218 13.9853 9.63694C14.0445 9.99203 13.887 10.28 13.4058 10.9595L13.3635 11.0192C12.9443 11.6089 12.248 12.5886 12.5759 13.8952Z" />
  </BaseIcon>
);
