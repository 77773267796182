import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const StopIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M6 8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8Z" />
    </BaseIcon>
  );
};
