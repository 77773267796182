import baseColors from "./baseColors";
import {
  ColorMapping,
  ColorScheme,
  DefaultColor,
  nodeColors,
  systemColors,
} from "./defaultTheme";
import newBrandTheme from "./newBrandTheme";

const BARBIE_OVERRIDES = {
  [baseColors.newBrand["05"]]: "#fff5fb",
  [baseColors.newBrand["40"]]: "#ED7DBB",
  [baseColors.newBrand["50"]]: "#E64CA1",
  [baseColors.newBrand["60"]]: "#E01F89",
  [baseColors.newBrand["100"]]: "#400024",

  [baseColors.newGrey["05"]]: "#F8F7F8",
  [baseColors.newGrey["10"]]: "#EFEDEF",
  [baseColors.newGrey["20"]]: "#E1DEE2",
  [baseColors.newGrey["40"]]: "#C4BEC5",
  [baseColors.newGrey["50"]]: "#8B808E",
  [baseColors.newGrey["60"]]: "#736876",
  [baseColors.newGrey["70"]]: "#5A525C",
  [baseColors.newGrey["80"]]: "#433D44",
  [baseColors.newGrey["90"]]: "#2C282D",
  [baseColors.newGrey["100"]]: "#181618",
};

let barbieColors = {
  ...newBrandTheme.colors,
  backgroundPage: baseColors.newBrand["05"],
  backgroundPrimary: baseColors.newBrand["60"],
  backgroundPrimaryHover: baseColors.newBrand["40"],
  backgroundInverse: baseColors.newBrand["100"],

  textButtonBrand: baseColors.newBrand["40"],
  textSecondaryInverse: baseColors.newGrey["20"],

  iconButtonBrand: baseColors.newBrand["40"],
  iconPrimaryBrand: baseColors.newBrand["40"],
  iconDisabled: baseColors.newGrey["100"],

  borderSubtleInverse: baseColors.newGrey["60"],
};

barbieColors = Object.entries(barbieColors).reduce(
  (acc, [colorName, colorValue]) => {
    if (colorValue in BARBIE_OVERRIDES) {
      acc[colorName as DefaultColor] = BARBIE_OVERRIDES[colorValue];
    } else {
      acc[colorName as DefaultColor] = colorValue;
    }
    return acc;
  },
  {} as ColorMapping
);

const colors = {
  ...systemColors,
  ...barbieColors,
  ...nodeColors,
};

const barbieTheme: ColorScheme = {
  colors,
  type: "light",
};

export default barbieTheme;
