import React from "react";

import { motion } from "framer-motion";

function circlePath(cx: number, cy: number, r: number) {
  return (
    "M " +
    cx +
    " " +
    cy +
    " m -" +
    r +
    ", 0 a " +
    r +
    "," +
    r +
    " 0 1,0 " +
    r * 2 +
    ",0 a " +
    r +
    "," +
    r +
    " 0 1,0 -" +
    r * 2 +
    ",0"
  );
}

type LoadingIconProps = {
  size?: number;
  strokeWidth?: number;
  className?: string;
};

export default function LoadingIcon({
  size = 12,
  strokeWidth = 1.4,
  className,
}: LoadingIconProps) {
  return (
    <motion.svg
      className={className}
      data-test-id="loading-icon"
      height={size}
      role="progressbar"
      width={size}
    >
      <motion.g
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <motion.path
          animate={{ pathLength: [0.9, 0.14, 0.9], pathOffset: [0, 0.4, 0] }}
          d={circlePath(size / 2, size / 2, size / 2 - strokeWidth)}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          transition={{ duration: 2, repeat: Infinity }}
        />
      </motion.g>
    </motion.svg>
  );
}
