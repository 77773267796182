import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingRule } from 'react-table';

import { projectInit } from 'actions';
import { ScenarioTableRow } from 'api/resources/scenarios/types';

export interface ScenariosState {
  isPolling: boolean;
  isPollingError: boolean;
  resultsVersion: string;
  currentPage: number;
  currentTableSort: SortingRule<ScenarioTableRow>[];
}

export const initialState: ScenariosState = {
  isPolling: false,
  isPollingError: false,
  resultsVersion: '',
  currentPage: 0,
  currentTableSort: [],
};

const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    setIsPolling: (state, isPolling: PayloadAction<boolean>) => {
      state.isPolling = isPolling.payload;
    },
    setIsPollingError: (state, isPollingError: PayloadAction<boolean>) => {
      state.isPollingError = isPollingError.payload;
    },
    setResultsVersion: (state, resultsVersion: PayloadAction<string>) => {
      state.resultsVersion = resultsVersion.payload;
    },
    setCurrentPage: (state, currentPage: PayloadAction<number>) => {
      state.currentPage = currentPage.payload;
    },
    setCurrentTableSort: (
      state,
      currentTableSort: PayloadAction<SortingRule<ScenarioTableRow>[]>,
    ) => {
      state.currentTableSort = [...currentTableSort.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const {
  setIsPolling,
  setIsPollingError,
  setResultsVersion,
  setCurrentPage,
  setCurrentTableSort,
} = scenariosSlice.actions;

export default scenariosSlice.reducer;
