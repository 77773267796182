import { Link } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";

import themeColors, { ColorName } from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import { IconSize } from "../Icons/BaseIcon";

type KeyType = keyof typeof IconSize;

type PropsType = {
  $iconSize?: KeyType;
  $hoverSize?: KeyType;
  $hoverTargetColor?: ColorName;
};

const getDefaultedSize = (defaultSize: KeyType) => (size?: KeyType) =>
  IconSize[size || defaultSize];
const getHoverSize = getDefaultedSize("xxl");
const getIconSize = getDefaultedSize("md");

export const HoverTargetCss = css<PropsType>`
  position: relative;

  &&& {
    z-index: 1;

    &::before {
      content: "";

      position: absolute;
      z-index: -1;
      inset: -${({ $iconSize, $hoverSize }) =>
          (getHoverSize($hoverSize) - getIconSize($iconSize)) / 2}px -${({
          $iconSize,
          $hoverSize,
        }) => (getHoverSize($hoverSize) - getIconSize($iconSize)) / 2}px -${({
          $iconSize,
          $hoverSize,
        }) => (getHoverSize($hoverSize) - getIconSize($iconSize)) / 2}px -${({
          $iconSize,
          $hoverSize,
        }) => (getHoverSize($hoverSize) - getIconSize($iconSize)) / 2}px;

      opacity: 0;
      background: ${({ theme, $hoverTargetColor }) =>
        theme.activeColorScheme.colors[$hoverTargetColor || "backgroundPage"]};
      border-radius: ${themeSpacing.radiusFull};

      transition: all 0.2s ease-in-out;
    }

    &:hover:not(:disabled),
    &:focus-within:not(:disabled) {
      outline: none;

      &::before {
        opacity: 1;
      }
    }
  }
`;

export const CircleCss = css<HoverProps>`
  ${({ $circle }) =>
    $circle &&
    css`
      &&& {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${themeSpacing.md1};
        height: ${themeSpacing.md1};
        padding: 0 !important;

        background-color: ${themeColors.backgroundPage};
        border: 1px solid ${themeColors.borderStrong};
        border-radius: ${themeSpacing.radiusFull};

        &:hover,
        &:focus {
          background-color: ${themeColors.borderSubtle};
        }
      }
    `}
`;

export type HoverProps = {
  $hoverTarget?: boolean;
  $hoverIconColor?: ColorName;
  $circle?: boolean;
} & PropsType;

const HoverCss = css<HoverProps>`
  ${({ $hoverIconColor }) =>
    $hoverIconColor &&
    css`
      &:hover,
      &:focus {
        svg {
          fill: ${({ theme }) =>
            theme.activeColorScheme.colors[$hoverIconColor]} !important;
        }
      }
    `}
  ${({ $hoverTarget, $circle }) => $hoverTarget && !$circle && HoverTargetCss}
  ${CircleCss}
`;

export const HoverButton = styled.button<HoverProps>`
  ${HoverCss}
`;

export const HoverLink = styled(Link)<HoverProps>`
  ${HoverCss}
`;

export const HoverAnchor = styled.a<HoverProps>`
  ${HoverCss}
`;
