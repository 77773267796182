/* eslint-disable max-len */
import { FC } from "react";

import { useTheme } from "styled-components";

import { BaseIcon, IconProps } from "./BaseIcon";

export const SuccessIcon: FC<IconProps> = ({ customFill, ...props }) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();
  return (
    <BaseIcon customFill={customFill} {...props}>
      <path
        d="M16 26c-5.523 0-10-4.477-10-10S10.477 6 16 6s10 4.477 10 10-4.477 10-10 10Zm0 2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
        fill={customFill ? colors.borderStrong : undefined}
      />
      {customFill && (
        <path
          d="M26 16c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10Z"
          fill={colors.iconSuccess}
        />
      )}
      <path
        d="M21.313 12.707a1 1 0 0 0-1.414 0l-5.657 5.657-2.12-2.121a1 1 0 0 0-1.415 1.414l2.818 2.818.01.01a.998.998 0 0 0 1.414 0l.012-.011 6.352-6.353a1 1 0 0 0 0-1.414Z"
        fill={customFill ? colors.background : undefined}
      />
    </BaseIcon>
  );
};
