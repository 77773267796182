// @ts-nocheck
import { m, minv, refU, refV } from "./constant.js";
import {
  dotProduct,
  fromLinear,
  lToY,
  maxChromaForLH,
  toLinear,
  yToL,
} from "./helpers.js";

export function luvToXyz(tuple) {
  const L = tuple[0];
  const U = tuple[1];
  const V = tuple[2];
  if (L == 0) return [0, 0, 0];
  const varU = U / (13 * L) + refU;
  const varV = V / (13 * L) + refV;
  const Y = lToY(L);
  const X = 0 - (9 * Y * varU) / ((varU - 4) * varV - varU * varV);
  const Z = (9 * Y - 15 * varV * Y - varV * X) / (3 * varV);
  return [X, Y, Z];
}

export function lchToLuv(tuple) {
  const L = tuple[0];
  const C = tuple[1];
  const H = tuple[2];
  const Hrad = (H / 360) * 2 * Math.PI;
  const U = Math.cos(Hrad) * C;
  const V = Math.sin(Hrad) * C;
  return [L, U, V];
}

export function hsluvToLch(tuple) {
  const H = tuple[0];
  const S = tuple[1];
  const L = tuple[2];
  if (L > 99.9999999) return [100, 0, H];
  if (L < 0.00000001) return [0, 0, H];
  const max = maxChromaForLH(L, H);
  const C = (max / 100) * S;
  return [L, C, H];
}

export function lchToHsluv(tuple) {
  const L = tuple[0];
  const C = tuple[1];
  const H = tuple[2];
  if (L > 99.9999999) return { l: H, u: 0, v: 100 };
  if (L < 0.00000001) return { l: H, u: 0, v: 0 };
  const max = maxChromaForLH(L, H);
  const S = (C / max) * 100;
  return { l: H, u: S, v: L };
}

export function luvToLch(tuple) {
  const L = tuple[0];
  const U = tuple[1];
  const V = tuple[2];
  const C = Math.sqrt(U * U + V * V);
  let H;
  if (C < 0.00000001) H = 0;
  else {
    const Hrad = Math.atan2(V, U);
    H = (Hrad * 180) / Math.PI;
    if (H < 0) H = 360 + H;
  }
  return [L, C, H];
}

export function xyzToLuv(tuple) {
  const X = tuple[0];
  const Y = tuple[1];
  const Z = tuple[2];
  const divider = X + 15 * Y + 3 * Z;
  let varU = 4 * X;
  let varV = 9 * Y;
  if (divider != 0) {
    varU /= divider;
    varV /= divider;
  } else {
    varU = NaN;
    varV = NaN;
  }
  const L = yToL(Y);
  if (L == 0) return [0, 0, 0];
  const U = 13 * L * (varU - refU);
  const V = 13 * L * (varV - refV);
  return [L, U, V];
}

export function xyzToRgb(tuple) {
  const RGB = [
    fromLinear(dotProduct(m[0], tuple)),
    fromLinear(dotProduct(m[1], tuple)),
    fromLinear(dotProduct(m[2], tuple)),
  ];
  return { r: RGB[0], g: RGB[1], b: RGB[2] };
}

export function rgbToXyz(tuple) {
  const rgbl = [toLinear(tuple[0]), toLinear(tuple[1]), toLinear(tuple[2])];
  return [
    dotProduct(minv[0], rgbl),
    dotProduct(minv[1], rgbl),
    dotProduct(minv[2], rgbl),
  ];
}
