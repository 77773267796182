/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const RetryIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M7 16c0-4.969 4.037-9 9.02-9a9.018 9.018 0 0 1 7.503 4H20a1 1 0 1 0 0 2h7V6a1 1 0 1 0-2 0v3.62A11.013 11.013 0 0 0 16.02 5C9.937 5 5 9.923 5 16s4.936 11 11.02 11a11.038 11.038 0 0 0 9.642-5.669 1 1 0 0 0-1.747-.972 9.042 9.042 0 0 1-1.493 1.982A9 9 0 0 1 16.021 25C11.037 25 7 20.969 7 16Z" />
  </BaseIcon>
);
