import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FlowsState {
  searchQueryString: string;
  searchLang: string;
}

const initialState: FlowsState = {
  searchQueryString: '',
  searchLang: '',
};

const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    setSearchQueryString: (state, searchQueryString: PayloadAction<string>) => {
      state.searchQueryString = searchQueryString.payload;
    },
    setSearchLang: (state, searchLang: PayloadAction<string>) => {
      state.searchLang = searchLang.payload;
    },
  },
});

export const { setSearchQueryString, setSearchLang } = flowsSlice.actions;

export default flowsSlice.reducer;
