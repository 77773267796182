import { datadogLogs } from '@datadog/browser-logs';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { setMergeConflict } from 'ducks/deployments/deploymentsSlice';
import { setIsDraft } from 'ducks/lock/lockSlice';
import { setCurrentPolicyVersion } from 'ducks/version/versionSlice';
import { getErrorCategory } from 'lib/ddlogHelper';
import { store } from '../reduxStore';
import { HttpStatusCode, LOG_ERROR_CATEGORY, LOGGER_TYPE } from './types';

const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

async function _fetch(
  resource: string,
  logger: LOGGER_TYPE,
  opts: any,
  overwriteUrl: string = '',
  additionalHeaders: any = {},
  downloadingFile: boolean = false,
  formData: boolean = false,
  returnStatusCode: boolean = false,
  version: string = 'v1',
) {
  const correlationID = uuidv4();
  const ddLogger = datadogLogs.getLogger(logger);

  opts = {
    ...opts,
    credentials: 'same-origin', // pass cookies, for authentication
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Polyai-Correlation-Id': correlationID,
    },
  };

  if (formData) {
    delete opts.headers['Content-Type'];
  }

  opts['headers'] = { ...opts.headers, ...additionalHeaders };

  const token = await store.getState().auth.getAuthToken();
  const login = store.getState().auth.login;
  if (token) {
    opts.headers['Authorization'] = `Bearer ${token}`;
  } else if (!!ddLogger) {
    const project_id = store.getState().projectInfo.id || '*';
    const account_id = store.getState().accountInfo.id || '*';

    ddLogger.error('Auth token not found', {
      correlation_id: correlationID,
      resource,
      error_message: 'Auth token not found',
      error_category: LOG_ERROR_CATEGORY.SYSTEM,
      project_id,
      account_id,
    });
  }

  const res = await fetch(
    overwriteUrl ||
      `${process.env.NEXT_PUBLIC_POLYAI_API}/api/${version}/${resource}`,
    opts,
  );

  if (res.ok) {
    const message = `Successful response from ${resource}`;
    if (!!ddLogger) {
      const project_id = store.getState().projectInfo.id || '*';
      const account_id = store.getState().accountInfo.id || '*';
      ddLogger.info(message, {
        correlation_id: correlationID,
        resource,
        success_message: message,
        status_code: res.status,
        project_id,
        account_id,
      });
    } else {
      console.log(`Missing DataDog Logger: ${logger}`);
    }

    if (downloadingFile) {
      const response = await res.blob();

      if (returnStatusCode) {
        return {
          data: response,
          statusCode: res.status as HttpStatusCode,
        };
      }

      return response;
    }
    const json =
      res.status !== HttpStatusCode.SuccessNoContent ? await res.json() : {};

    if (
      'version' in json &&
      json.version !== store.getState().version.currentPolicyVersion &&
      json.version !== null
    ) {
      store.dispatch(setCurrentPolicyVersion(json.version));
      store.dispatch(setMergeConflict(null));
    }
    if ('draft' in json) {
      store.dispatch(setIsDraft(json.draft));
    }

    if (returnStatusCode) {
      return {
        data: json,
        statusCode: res.status as HttpStatusCode,
      };
    }

    return json;
  } else {
    let errorMsg = '';
    let errorJson;
    if (res.status === HttpStatusCode.ServerErrorInternal) {
      const txt = await res.text();
      if (isJson(txt)) {
        errorMsg = txt;
        errorJson = JSON.parse(txt);
      } else
        errorMsg = JSON.stringify({
          errorMessage: txt,
          error_code: 'not_assigned_500',
        });
    } else if (res.status === HttpStatusCode.ClientErrorUnauthorized) {
      login(window.location.href);
      return;
    } else {
      errorJson = await res.json();
      if (errorJson.error === 'login_required') {
        login(window.location.href);
        return;
      }
      if (res.status === HttpStatusCode.ClientErrorForbidden)
        errorJson.error_code = 'forbidden';
      errorMsg = JSON.stringify(errorJson);
    }
    const error = new Error(errorMsg);

    let message = errorMsg;
    if (isJson(errorMsg))
      message = JSON.parse(errorMsg)?.error_message || errorMsg;

    // log responses on DataDog
    if (!!ddLogger) {
      const project_id = store.getState().projectInfo.id || '*';
      const account_id = store.getState().accountInfo.id || '*';

      ddLogger.error(message, {
        correlation_id: correlationID,
        resource,
        error_message: errorMsg,
        status_code: res.status,
        error_category: getErrorCategory(errorJson?.error_id, res.status),
        project_id,
        account_id,
      });
    } else {
      console.log(`Missing DataDog Logger: ${logger}`);
    }
    throw error;
  }
}

// TODO: multiple args into a single object otherwise we will be passing in undefined for many args if wanting to use v2
export async function doGet<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  params?: any,
  query = '',
  acceptHeader: any = {},
  downloadingFile: boolean = false,
  returnStatusCode?: boolean,
  version?: string,
): Promise<T> {
  const opts = {
    method: 'GET',
  };
  const queryString = generateQueryString(params, query);
  return _fetch(
    resource + queryString,
    logger,
    opts,
    '',
    acceptHeader,
    downloadingFile,
    false,
    returnStatusCode,
    version,
  );
}

export function generateQueryString(params: any, query: string) {
  const qs = params
    ? queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
      })
    : '';

  const cleanedQueryString = qs ? '?' + qs : '';

  let _query = cleanedQueryString ? `&${query}` : `?${query}`;
  if (_query.length === 1) {
    _query = '';
  }

  return cleanedQueryString + _query;
}

// TODO: multiple args into a single object otherwise we will be passing in undefined for many args if wanting to use v2
export async function doPatch<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  additionalHeaders: any = {},
  postingFile: boolean = false,
  returnStatusCode?: boolean,
  version?: string,
) {
  const opts = {
    method: 'PATCH',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    undefined,
    additionalHeaders,
    false,
    postingFile,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

// TODO: multiple args into a single object otherwise we will be passing in undefined for many args if wanting to use v2
export async function doPut<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  overwriteUrl: string = '',
  additionalHeaders: any = {},
  postingFile: boolean = false,
  returnStatusCode?: boolean,
  version?: string,
) {
  const opts = {
    method: 'PUT',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    overwriteUrl,
    additionalHeaders,
    false,
    postingFile,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

// TODO: multiple args into a single object otherwise we will be passing in undefined for many args if wanting to use v2
export async function doPost<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  overwriteUrl: string = '',
  additionalHeaders: any = {},
  postingFile: boolean = false,
  downloadingFile: boolean = false,
  returnStatusCode?: boolean,
  version?: string,
) {
  const opts = {
    method: 'POST',
    body: postingFile ? body : JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    overwriteUrl,
    additionalHeaders,
    postingFile || downloadingFile,
    false,
    returnStatusCode,
    version,
  ) as Promise<T>;
}

// TODO: multiple args into a single object otherwise we will be passing in undefined for many args if wanting to use v2
export async function doDelete<T extends any = any>(
  resource: string,
  logger: LOGGER_TYPE,
  body: any = {},
  returnStatusCode?: boolean,
  version?: string,
) {
  const opts = {
    method: 'DELETE',
    body: JSON.stringify(body),
  };

  return _fetch(
    resource,
    logger,
    opts,
    '',
    {},
    false,
    false,
    returnStatusCode,
    version,
  ) as Promise<T>;
}
