import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';

export const getDailyPlaylists = (
  accountId: string,
  projectId: string,
  effectiveDate: string,
) =>
  doGet(
    `accounts/${accountId}/projects/${projectId}/conversations/daily_playlists`,
    LOGGER_TYPE.CONVERSATIONS,
    { effective_date: effectiveDate },
  );

export const getDailyPlaylistDetails = (
  accountId: string,
  projectId: string,
  effectiveDate: string,
  playlistName: string,
  playlistFilterParams: string[],
) =>
  doPost(
    `accounts/${accountId}/projects/${projectId}/conversations/daily_playlists/${playlistName}`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      effective_date: effectiveDate,
      playlist_filter_params: playlistFilterParams,
    },
  );
