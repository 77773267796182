/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const AnalyticsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path
        clipRule="evenodd"
        d="M15 5C15 4.44772 15.4477 4 16 4C16.5523 4 17 4.44772 17 5V6H27C27.5523 6 28 6.44772 28 7V22C28 22.5523 27.5523 23 27 23H18.7618L22.6402 26.2319C23.0644 26.5855 23.1218 27.2161 22.7682 27.6403C22.4146 28.0646 21.7841 28.1219 21.3598 27.7684L16 23.3019L10.6402 27.7684C10.2159 28.1219 9.58537 28.0646 9.2318 27.6403C8.87824 27.2161 8.93556 26.5855 9.35984 26.2319L13.2382 23H5C4.44772 23 4 22.5523 4 22V7C4 6.44772 4.44772 6 5 6H15V5ZM6 8V21H26V8H6Z"
        fillRule="evenodd"
      />
      <path d="M11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V15Z" />
      <path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V13Z" />
      <path d="M19 11C19 10.4477 19.4477 10 20 10C20.5523 10 21 10.4477 21 11V18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18V11Z" />
    </BaseIcon>
  );
};
