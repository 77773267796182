import { FeatureFlag } from '@polyai/common/types/resources/featureFlags';

import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';

export type FeaturesResponse = FeatureFlag[];

export const getFeatures = () => {
  return doGet('features', LOGGER_TYPE.FEATURES);
};

export const createFeature = ({
  name,
  description,
  app,
  user_scope,
  whitelist,
}: FeatureFlag) => {
  return doPost<FeaturesResponse>('features', LOGGER_TYPE.FEATURES, [
    {
      name,
      description,
      enabled: true,
      app,
      user_scope,
      whitelist,
    },
  ]);
};

export const updateFeature = ({
  name,
  enabled,
  app,
  user_scope,
  whitelist,
}: FeatureFlag) => {
  return doPut<FeaturesResponse>('features', LOGGER_TYPE.FEATURES, {
    name,
    enabled,
    app,
    user_scope,
    whitelist,
  });
};

export const deleteFeature = ({ name }: { name: string }) => {
  return doDelete('features', LOGGER_TYPE.FEATURES, {
    name,
  });
};
