import { doDelete, doGet, doPatch, doPost, doPut } from 'api/request';
import { LOGGER_TYPE, Node } from 'api/types';
import { store } from '../../reduxStore';
import { PolicyResponse } from './flows';

export interface UpdateNodeResponse {
  node: Node;
  nodes: Node[];
  policy: PolicyResponse;
  version: string;
  draft: boolean;
}

export interface NodeMetadata {
  name: string;
  path: string;
  user_path: string;
  type: string;
}

export const createNode = (
  nodes?: Node[],
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPost(`${accountId}/${projectId}/nodes`, LOGGER_TYPE.NODE, {
    nodes,
    id: flowId,
    flow_id: flowId,
    version: gitVersion,
    tts_lang_code: language,
    asr_lang_code: language,
  });
};

export const duplicateNode = (
  nodeName?: string,
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPost(
    `${accountId}/${projectId}/nodes/${nodeName}`,
    LOGGER_TYPE.NODE,
    {
      version: gitVersion,
      id: flowId,
      flow_id: flowId,
      tts_lang_code: language,
      asr_lang_code: language,
    },
  );
};

export const updateNode = (
  nodeName?: string,
  nodeData?: Node,
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPut(
    `${accountId}/${projectId}/nodes/${nodeName}`,
    LOGGER_TYPE.NODE,
    {
      node: nodeData,
      version: gitVersion,
      id: flowId,
      flow_id: flowId,
      tts_lang_code: language,
      asr_lang_code: language,
    },
  );
};

export const deleteNode = (
  nodeName?: string,
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doDelete(
    `${accountId}/${projectId}/nodes/${nodeName}`,
    LOGGER_TYPE.NODE,
    {
      version: gitVersion,
      id: flowId,
      flow_id: flowId,
      tts_lang_code: language,
      asr_lang_code: language,
    },
  );
};

export const deleteNodes = (
  nodeNames?: string[],
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doDelete(`${accountId}/${projectId}/nodes`, LOGGER_TYPE.NODE, {
    nodes: nodeNames,
    id: flowId,
    flow_id: flowId,
    version: gitVersion,
    tts_lang_code: language,
    asr_lang_code: language,
  });
};

export const moveNode = (
  nodeName?: string,
  nodeData?: Node,
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPatch(
    `${accountId}/${projectId}/nodes/${nodeName}`,
    LOGGER_TYPE.NODE,
    {
      node: nodeData,
      version: gitVersion,
      flow_id: flowId,
      tts_lang_code: language,
      asr_lang_code: language,
    },
  );
};

export const moveNodes = (
  nodes: Node[],
  flowId?: string,
  language?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPatch(`${accountId}/${projectId}/nodes`, LOGGER_TYPE.NODE, {
    nodes: nodes,
    version: gitVersion,
    flow_id: flowId,
    tts_lang_code: language,
    asr_lang_code: language,
  });
};

export const getNodesMetadata = (variant_id?: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  return doGet(
    `${accountId}/${projectId}/nodes`,
    LOGGER_TYPE.NODE,
    null,
    variant_id ? `variant_id=${variant_id}` : '',
  );
};
