/* eslint-disable max-len */
import React from "react";

import { BaseIcon } from "./BaseIcon";
import { IconProps } from "./BaseIcon";

export const CopyIcon: React.FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M20 20h5a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H14a2 2 0 0 0-2 2v5H7a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-5Zm5-13v11h-5v-4a2 2 0 0 0-2-2h-4V7h11Zm-7 7v11H7V14h11Z" />
  </BaseIcon>
);
