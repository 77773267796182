/* eslint-disable max-len */
import React from "react";

import { BaseIcon } from "./BaseIcon";

export const FilterIcon = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M26 6v1.672L19.172 14.5A4 4 0 0 0 18 17.328v7.87l-4-2.75v-5.12a4 4 0 0 0-1.172-2.828L6 7.672V6h20ZM6 4a2 2 0 0 0-2 2v1.672a2 2 0 0 0 .586 1.414l6.828 6.828A2 2 0 0 1 12 17.328v5.12a2 2 0 0 0 .867 1.648l4 2.75c1.327.912 3.133-.038 3.133-1.648v-7.87a2 2 0 0 1 .586-1.414l6.828-6.828A2 2 0 0 0 28 7.672V6a2 2 0 0 0-2-2H6Z" />
  </BaseIcon>
);
