/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DashboardIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M7 7v18h18V7H7ZM5 7a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7Zm12 3h-2v12h2V10Zm-5 6h-2v6h2v-6Zm8-3h2v9h-2v-9Z" />
    </BaseIcon>
  );
};
