import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const VoiceIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M13 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 2a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM23.319 4.902a1 1 0 0 1 1.413-.06 9.12 9.12 0 0 1 2.962 6.736 9.12 9.12 0 0 1-2.962 6.737 1 1 0 1 1-1.353-1.474 7.12 7.12 0 0 0 2.315-5.263 7.12 7.12 0 0 0-2.315-5.263 1 1 0 0 1-.06-1.413Z" />
    <path d="M20.319 7.902a1 1 0 0 1 1.413-.06 5.06 5.06 0 0 1 1.643 3.736 5.06 5.06 0 0 1-1.643 3.737 1 1 0 1 1-1.353-1.474 3.06 3.06 0 0 0 .996-2.263c0-.896-.383-1.7-.996-2.263a1 1 0 0 1-.06-1.413ZM7.191 25H18.81c-.73-3.756-3.309-6-5.809-6s-5.08 2.244-5.809 6Zm11.812 0H19ZM6.999 25h-.002Zm-1.84.008C5.897 20.44 9.127 17 13 17s7.103 3.44 7.841 8.008C21.018 26.098 20.105 27 19 27H7c-1.105 0-2.018-.901-1.841-1.992Z" />
  </BaseIcon>
);
