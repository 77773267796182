/* eslint-disable max-len */
import React from "react";

import { useTheme } from "styled-components";

import { ColorName } from "styles/config/colors";

export const Logo = ({
  dotsColor: dotsColorName = "iconBrand",
  textColor: textColorName = "textPrimaryInverse",
  width = 84,
  height = 36,
  className,
}: {
  dotsColor?: ColorName;
  textColor?: ColorName;
  width?: number;
  height?: number;
  className?: string;
}) => {
  const theme = useTheme();
  const dotsColor = theme.activeColorScheme.colors[dotsColorName];
  const textColor = theme.activeColorScheme.colors[textColorName];

  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 122 36"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1289_19381)">
        <path
          d="M122 10.569V8.21875H113.734V10.569H116.427V23.8413C116.427 24.9542 115.511 25.8606 114.378 25.8606H113.729V28.2067H121.996V25.8606H119.303V10.569H122Z"
          fill={textColor}
        />
        <path
          d="M69.4756 28.2064V25.9763H69.9026C71.0792 25.9763 72.0335 25.0354 72.0335 23.8753V10.4311H69.7675V8.20117H74.7702V25.9806H77.3283V28.2106H69.4799L69.4756 28.2064Z"
          fill={textColor}
        />
        <path
          d="M111.676 28.2067H108.42L106.529 22.9175H97.5738L95.6564 28.2067H92.5146L100.162 8.19727H104.176L111.676 28.2067ZM100.511 14.857L98.5326 20.288H105.57L103.623 14.857C102.838 12.597 102.402 11.3982 102.08 10.569C101.762 11.3982 101.295 12.7431 100.511 14.857Z"
          fill={textColor}
        />
        <path
          d="M40.3396 28.2106H37.4331V8.20117H45.6912C49.9921 8.20117 52.4673 10.6889 52.4673 14.2895C52.4673 17.8901 49.9659 20.4079 45.6649 20.4079H40.3442V28.2106H40.3396ZM40.3396 10.8307V17.9201H45.1376C47.9877 17.9201 49.4126 16.3777 49.4126 14.2895C49.4126 12.2013 48.0442 10.8307 45.1376 10.8307H40.3396Z"
          fill={textColor}
        />
        <path
          d="M60.5075 14.1465C56.6073 14.1465 53.4478 17.3818 53.4478 21.3777C53.4478 25.3736 56.6073 28.6089 60.5075 28.6089C64.4076 28.6089 67.567 25.3736 67.567 21.3777C67.567 17.3861 64.4076 14.1465 60.5075 14.1465ZM60.5075 25.8117C58.1149 25.8117 56.1758 23.8268 56.1758 21.3777C56.1758 18.9286 58.1149 16.9436 60.5075 16.9436C62.9 16.9436 64.8389 18.9286 64.8389 21.3777C64.8389 23.8268 62.8998 25.8117 60.5075 25.8117Z"
          fill={textColor}
        />
        <path
          d="M89.5214 14.0312L87.0504 21.3785C86.3837 23.2948 86.2093 23.8362 85.9436 24.6094C85.6822 23.9221 85.4206 23.2948 84.462 21.0949L81.4942 14.0355H78.4395L84.8063 28.0426L84.0783 30.0705C83.6733 31.1576 83.0892 31.3853 82.0999 31.3853H80.248L79.1934 33.7055V33.7828H82.1871C84.7146 33.7828 85.7911 32.8978 86.7235 30.2124L92.3931 14.0355H89.5125L89.5214 14.0312Z"
          fill={textColor}
        />
        <path
          d="M14.0692 31.4258C12.8142 31.4258 11.7988 32.4355 11.7988 33.6859C11.7988 34.9362 12.8142 35.9457 14.0692 35.9457C15.3242 35.9457 16.3396 34.936 16.3396 33.6859C16.3396 32.4358 15.3242 31.4258 14.0692 31.4258Z"
          fill={dotsColor}
        />
        <path
          d="M14.0692 0C12.8142 0 11.7988 1.00971 11.7988 2.26002C11.7988 3.51034 12.8142 4.52005 14.0692 4.52005C15.3242 4.52005 16.3396 3.51036 16.3396 2.26002C16.3396 1.00969 15.3242 0 14.0692 0Z"
          fill={dotsColor}
        />
        <path
          d="M25.868 6.6543C24.613 6.6543 23.5977 7.66401 23.5977 8.91432C23.5977 10.1646 24.613 11.1743 25.868 11.1743C27.123 11.1743 28.1383 10.1646 28.1383 8.91432C28.1383 7.66401 27.123 6.6543 25.868 6.6543Z"
          fill={dotsColor}
        />
        <path
          d="M2.2704 6.6543C1.01536 6.6543 0 7.66829 0 8.91432C0 10.1604 1.01536 11.1743 2.2704 11.1743C3.52544 11.1743 4.54077 10.1646 4.54077 8.91432C4.54077 7.66401 3.52542 6.6543 2.2704 6.6543Z"
          fill={dotsColor}
        />
        <path
          d="M25.868 24.7715C24.613 24.7715 23.5977 25.781 23.5977 27.0314C23.5977 28.2817 24.613 29.2914 25.868 29.2914C27.123 29.2914 28.1383 28.2817 28.1383 27.0314C28.1383 25.781 27.123 24.7715 25.868 24.7715Z"
          fill={dotsColor}
        />
        <path
          d="M2.2704 24.7715C1.01536 24.7715 0 25.781 0 27.0314C0 28.2775 1.01536 29.2914 2.2704 29.2914C3.52544 29.2914 4.54077 28.2817 4.54077 27.0314C4.54077 25.781 3.52542 24.7715 2.2704 24.7715Z"
          fill={dotsColor}
        />
        <path
          d="M8.16979 24.7524C9.42483 24.7524 10.4402 23.7429 10.4402 22.4924C10.4402 21.242 9.42483 20.2324 8.16979 20.2324C6.91475 20.2324 5.89941 21.2421 5.89941 22.4924C5.89941 23.7428 6.91477 24.7524 8.16979 24.7524Z"
          fill={dotsColor}
        />
        <path
          d="M14.0692 20.2154C15.3242 20.2154 16.3396 19.2056 16.3396 17.9553C16.3396 16.705 15.3242 15.6953 14.0692 15.6953C12.8142 15.6953 11.7988 16.705 11.7988 17.9553C11.7988 19.2057 12.8142 20.2154 14.0692 20.2154Z"
          fill={dotsColor}
        />
        <path
          d="M14.0692 29.2914C15.3242 29.2914 16.3396 28.2817 16.3396 27.0314C16.3396 25.781 15.3242 24.7715 14.0692 24.7715C12.8142 24.7715 11.7988 25.781 11.7988 27.0314C11.7988 28.2817 12.8142 29.2914 14.0692 29.2914Z"
          fill={dotsColor}
        />
        <path
          d="M14.0692 11.1743C15.3242 11.1743 16.3396 10.1646 16.3396 8.91432C16.3396 7.66401 15.3242 6.6543 14.0692 6.6543C12.8142 6.6543 11.7988 7.66401 11.7988 8.91432C11.7988 10.1646 12.8142 11.1743 14.0692 11.1743Z"
          fill={dotsColor}
        />
        <path
          d="M25.868 20.2154C27.123 20.2154 28.1383 19.2056 28.1383 17.9553C28.1383 16.705 27.123 15.6953 25.868 15.6953C24.613 15.6953 23.5977 16.705 23.5977 17.9553C23.5977 19.2057 24.613 20.2154 25.868 20.2154Z"
          fill={dotsColor}
        />
        <path
          d="M2.2704 20.2154C3.52542 20.2154 4.54077 19.2056 4.54077 17.9553C4.54077 16.705 3.52542 15.6953 2.2704 15.6953C1.01538 15.6953 0 16.705 0 17.9553C0 19.2057 1.01536 20.2154 2.2704 20.2154Z"
          fill={dotsColor}
        />
        <path
          d="M19.9686 24.7524C21.2237 24.7524 22.239 23.7429 22.239 22.4924C22.239 21.242 21.2237 20.2324 19.9686 20.2324C18.7136 20.2324 17.6982 21.2421 17.6982 22.4924C17.6982 23.7428 18.7136 24.7524 19.9686 24.7524Z"
          fill={dotsColor}
        />
        <path
          d="M8.16979 15.6958C9.42483 15.6958 10.4402 14.6861 10.4402 13.4358C10.4402 12.1855 9.42483 11.1758 8.16979 11.1758C6.91475 11.1758 5.89941 12.1855 5.89941 13.4358C5.89941 14.6861 6.91477 15.6958 8.16979 15.6958Z"
          fill={dotsColor}
        />
        <path
          d="M19.9686 15.6958C21.2237 15.6958 22.239 14.6861 22.239 13.4358C22.239 12.1855 21.2237 11.1758 19.9686 11.1758C18.7136 11.1758 17.6982 12.1855 17.6982 13.4358C17.6982 14.6861 18.7136 15.6958 19.9686 15.6958Z"
          fill={dotsColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1289_19381">
          <rect fill="white" height="35.9464" width="122" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
