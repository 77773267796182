import { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'api';
import { FeaturesResponse } from 'api/resources/features';
import { setIsSiteUnavailable } from 'ducks/pageStatus/pageStatusSlice';
import {
  GLOBAL_PERMISSION,
  useGlobalPermissions,
} from 'hooks/useProjectResources/useGlobalPermissions';
import { isFeatureEnabled } from 'lib/featuresHelper';
import { useAppDispatch } from '../reduxHooks';

export type FeatureFlag =
  | 'agent_config'
  | 'api_integrations'
  | 'audio_testing'
  | 'call_flows'
  | 'call_listening'
  | 'chat_reports'
  | 'live_transcript'
  | 'notification_center'
  | 'playlist_conversations'
  | 'portal_analytics_external'
  | 'portal_analytics_internal'
  | 'portal_analytics'
  | 's3_project_backing'
  | 'scenarios'
  | 'speech-to-speech'
  | 'stripe-payments'
  | 'telephony'
  | 'user_management'
  | 'user-settings'
  | 'utilities'
  | 'draft_chat'
  | 'battlebots'
  | 'simplified_conversation_review'
  | 'saferide_api_logs';

export const useIsFeatureOn = () => {
  const dispatch = useAppDispatch();
  const { userHasGlobalPermission } = useGlobalPermissions();

  const queryClient = useQueryClient();

  const {
    data: features,
    isLoading: isLoadingFeatures,
    isStale,
    refetch,
  } = useQuery<FeaturesResponse>(['features'], () => api.getFeatures(), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    onSuccess: (features) => {
      queryClient.setQueryData(['features'], features);
      const isUnderMaintenance = isFeatureEnabled(
        features ?? [],
        'site_maintenance',
      );
      dispatch(
        setIsSiteUnavailable(
          isUnderMaintenance &&
            !userHasGlobalPermission(GLOBAL_PERMISSION.PLATFORM_ADMIN),
        ),
      );
    },
  });

  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFeatureOn = (featureName: FeatureFlag) => {
    return isFeatureEnabled(features ?? [], featureName);
  };

  return {
    features,
    isLoadingFeatures,
    isFeatureOn,
  };
};
