import rem from "styles/functions/rem";

export interface Text {
  font: {
    /** '"Proxima Nova", sans-serif;' */
    heading: string;
    /** '"Proxima Nova", sans-serif;' */
    body: string;
    /** '"Roboto Mono", monospace' */
    monospace: string;
  };
  size: {
    /** 16px */
    base: string;
    body: {
      /** 24px */
      body1: string;
      /** 16px */
      body2: string;
      /** 14px */
      body3: string;
      /** 12px */
      body4: string;
      /** 11px */
      body5: string;
    };
    heading: {
      /** 40px */
      h1: string;
      /** 18px */
      h2: string;
      /** 16px */
      h3: string;
    };
  };
  weight: {
    /** 200 */
    light: number;
    /** 300 */
    normal: number;
    /** 400 */
    regular: number;
    /** 500 */
    medium: number;
    /** 600 */
    semibold: number;
    /** 700 */
    bold: number;
  };
  lineHeight: {
    /** 1 */
    exact: number;
    /** 1.2 */
    tight: number;
    /** 1.5 */
    normal: number;
    /** 2 */
    loose: number;
  };
  letterSpacing: {
    /** -0.2px */
    tight: string;
    /** 0px */
    normal: string;
    /** 0.2px */
    loose: string;
  };
}

const text: Text = {
  font: {
    heading: '"Proxima Nova", sans-serif;',
    body: '"Proxima Nova", sans-serif;',
    monospace: '"Roboto Mono", monospace',
  },
  size: {
    base: rem(16),
    body: {
      body1: rem(24),
      body2: rem(16),
      body3: rem(14),
      body4: rem(12),
      body5: rem(11),
    },
    heading: {
      h1: rem(40),
      h2: rem(18),
      h3: rem(16),
    },
  },
  weight: {
    light: 200,
    normal: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeight: {
    exact: 1,
    tight: 1.2,
    normal: 1.5,
    loose: 2,
  },
  letterSpacing: {
    tight: rem(-0.2),
    normal: rem(0),
    loose: rem(0.2),
  },
};

export default text;
