/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeInformationIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="m12.707 12.707-1.414 1.414 3.536 3.536 6.363-6.364-1.414-1.414-4.95 4.95-2.12-2.122Z" />
      <path d="M3 7v21l6.5-5.308H27a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Zm24 13.692H8.787L5 23.785V7h22v13.692Z" />
    </BaseIcon>
  );
};
