import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientEnvironment } from '@polyai/common/constants/GlobalNew.constants';

import { createNewDeployment, projectInit } from 'actions';
import {
  DeploymentErrorCode,
  DeploymentEventName,
  DeploymentEventStatus,
  MergeConflict,
} from 'api/resources/deploy/types';

type DeploymentEvent = {
  name: DeploymentEventName;
  status: DeploymentEventStatus;
};

export interface DeploymentError {
  title: string;
  message: string;
  type?: DeploymentErrorCode;
  events?: {
    [event in DeploymentEventName]: {
      status: DeploymentEventStatus;
    };
  };
}

type Deployment = {
  version: string;
  id?: string;
  isValidated?: boolean;
  currentEvent?: DeploymentEvent;
  ready?: boolean;
  clientEnv?: ClientEnvironment;
};

export interface DeploymentState {
  currentDeployment: Deployment | null;
  mergeConflict: MergeConflict | null;
  isOngoing: boolean;
  isPolling: boolean;
  error: DeploymentError | null;
  isPublishOnGoing?: boolean;
}

export const initialState: DeploymentState = {
  currentDeployment: null,
  mergeConflict: null,
  isOngoing: false,
  isPolling: false,
  error: null,
  isPublishOnGoing: false,
};

const deploymentsSlice = createSlice({
  name: 'deployments',
  initialState,
  reducers: {
    setCurrentDeployment: (
      state,
      { payload: deployment }: PayloadAction<Deployment>,
    ) => {
      state.currentDeployment = {
        ...state.currentDeployment,
        ...deployment,
      };
    },
    setError: (state, { payload: error }: PayloadAction<DeploymentError>) => {
      state.error = error;
    },
    setMergeConflict: (
      state,
      { payload: conflict }: PayloadAction<MergeConflict | null>,
    ) => {
      state.mergeConflict = conflict;
    },
    setIsOngoing: (state, { payload: isOngoing }: PayloadAction<boolean>) => {
      state.isOngoing = isOngoing;
    },
    setIsPublishOngoing: (
      state,
      { payload: isPublishOnGoing }: PayloadAction<boolean>,
    ) => {
      state.isPublishOnGoing = isPublishOnGoing;
    },
    startPolling: (state) => {
      state.isPolling = true;
    },
    stopPolling: (state) => {
      state.isPolling = false;
    },
    resetCurrentDeployment: (state) => {
      state.currentDeployment = null;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetMergeConflict: (state) => {
      state.mergeConflict = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(createNewDeployment, (state) => {
        state.currentDeployment = null;
        state.error = null;
        state.mergeConflict = null;
        state.isOngoing = true;
      });
  },
});

export const {
  setCurrentDeployment,
  setError,
  setIsOngoing,
  startPolling,
  stopPolling,
  setMergeConflict,
  resetCurrentDeployment,
  resetError,
  resetMergeConflict,
  setIsPublishOngoing,
} = deploymentsSlice.actions;

export default deploymentsSlice.reducer;
