import { ClientEnvironment } from '@polyai/common/constants/GlobalNew.constants';

import { LOGGER_TYPE } from 'api/types';
import { store } from '../../reduxStore';
import { doGet, doPatch, doPut } from '../request';

export interface DataFile {
  name: string;
  path?: string;
  data?: JSON;
  client_env: ClientEnvironment;
}

export interface FileResponse {
  account_id: string;
  client_env: ClientEnvironment;
  created: boolean;
  ended_at: string;
  name: string;
  path: string;
  project_id: string;
  schema_version: string;
  started_at: string;
  updated: boolean;
}

export interface UpsertFilesResponse {
  dry_run: boolean;
  files: FileResponse[];
  success: boolean;
}

export const getFiles = async (clientEnv?: ClientEnvironment) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  const response = doGet(
    `${accountId}/${projectId}/files`,
    LOGGER_TYPE.DATA_STORE,
    {
      client_env: clientEnv,
    },
  );

  return response;
};

export const upsertFiles = (
  clientEnv: ClientEnvironment,
  files: DataFile[],
  dryRun?: boolean,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  const qs = dryRun
    ? `?client_env=${clientEnv}&dry_run=true`
    : `?client_env=${clientEnv}`;

  return doPut<UpsertFilesResponse>(
    `${accountId}/${projectId}/files${qs}`,
    LOGGER_TYPE.DATA_STORE,
    {
      files,
      client_env: clientEnv.toLowerCase(),
    },
  );
};

export const deleteFiles = (files: DataFile[]) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPatch(`${accountId}/${projectId}/files`, LOGGER_TYPE.DATA_STORE, {
    files,
  });
};

export const getFileDetails = async (str: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<DataFile>(
    `${accountId}/${projectId}/file${str}`,
    LOGGER_TYPE.DATA_STORE,
  );
};

export const updateFileDetails = async (
  queryString: string,
  payload: DataFile,
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPut<FileResponse>(
    `${accountId}/${projectId}/file${queryString}`,
    LOGGER_TYPE.DATA_STORE,
    payload,
  );
};
