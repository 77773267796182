import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const RevisionIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M13.5558 7.68509C15.2004 7.0039 17.01 6.82567 18.7558 7.17294C20.5016 7.5202 22.1053 8.37737 23.364 9.63604C24.6226 10.8947 25.4798 12.4984 25.8271 14.2442C26.1743 15.99 25.9961 17.7996 25.3149 19.4442C24.6337 21.0887 23.4802 22.4943 22.0001 23.4832C20.5201 24.4722 18.78 25 17 25C16.4477 25 16 25.4477 16 26C16 26.5523 16.4477 27 17 27C19.1756 27 21.3023 26.3549 23.1113 25.1462C24.9202 23.9375 26.3301 22.2195 27.1627 20.2095C27.9952 18.1995 28.2131 15.9878 27.7886 13.854C27.3642 11.7202 26.3165 9.76021 24.7782 8.22183C23.2398 6.68345 21.2798 5.6358 19.146 5.21137C17.0122 4.78693 14.8005 5.00477 12.7905 5.83733C10.7805 6.66989 9.06253 8.07979 7.85383 9.88873C6.64514 11.6977 6 13.8244 6 16L4.20706 16C3.76161 16 3.53852 16.5386 3.8535 16.8536L6.6464 19.6464C6.84166 19.8417 7.15824 19.8417 7.3535 19.6464L10.1464 16.8536C10.4614 16.5386 10.2383 16 9.79284 16L8 16C8 14.22 8.52784 12.4799 9.51677 10.9999C10.5057 9.51983 11.9113 8.36628 13.5558 7.68509Z" />
    <path d="M17 10C17.5523 10 18 10.4477 18 11V15.5858L21.2071 18.7929C21.5976 19.1834 21.5976 19.8166 21.2071 20.2071C20.8166 20.5976 20.1834 20.5976 19.7929 20.2071L16.5858 17C16.2107 16.6249 16 16.1162 16 15.5858V11C16 10.4477 16.4477 10 17 10Z" />
  </BaseIcon>
);
