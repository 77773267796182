// merge two arrays of objects + dedupe using defined prop

import { matchPath } from 'react-router-dom';

import { PAGE_TITLES, PATHS } from 'routes/routes.constants';

export const getPageTab = (pathname: string) => {
  const paths = Object.entries(PATHS) as [keyof typeof PATHS, string][];
  const path = paths.find((p: [string, string]) =>
    matchPath({ path: p[1] }, pathname),
  );
  if (path?.length) return PAGE_TITLES[path[0]];
  return 'Unknown tab';
};
