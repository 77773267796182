import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const WandIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M14.4088 2.71147C14.3328 2.48355 14.0104 2.48355 13.9345 2.71147L13.1683 5.01005C13.1434 5.0847 13.0848 5.14328 13.0102 5.16817L10.7116 5.93436C10.4836 6.01033 10.4836 6.33273 10.7116 6.4087L13.0102 7.1749C13.0848 7.19978 13.1434 7.25836 13.1683 7.33301L13.9345 9.63159C14.0104 9.85952 14.3328 9.85952 14.4088 9.63159L15.175 7.33301C15.1999 7.25836 15.2585 7.19978 15.3331 7.1749L17.6317 6.4087C17.8596 6.33273 17.8596 6.01033 17.6317 5.93436L15.3331 5.16817C15.2585 5.14328 15.1999 5.0847 15.175 5.01005L14.4088 2.71147Z" />
    <path d="M25.4088 2.71147C25.3328 2.48355 25.0104 2.48355 24.9345 2.71147L24.1683 5.01005C24.1434 5.0847 24.0848 5.14328 24.0102 5.16817L21.7116 5.93436C21.4836 6.01033 21.4836 6.33273 21.7116 6.4087L24.0102 7.1749C24.0848 7.19978 24.1434 7.25836 24.1683 7.33301L24.9345 9.63159C25.0104 9.85952 25.3328 9.85952 25.4088 9.63159L26.175 7.33301C26.1999 7.25836 26.2585 7.19978 26.3331 7.1749L28.6317 6.4087C28.8596 6.33273 28.8596 6.01033 28.6317 5.93436L26.3331 5.16817C26.2585 5.14328 26.1999 5.0847 26.175 5.01005L25.4088 2.71147Z" />
    <path d="M24.9345 13.7115C25.0104 13.4835 25.3328 13.4835 25.4088 13.7115L26.175 16.0101C26.1999 16.0847 26.2585 16.1433 26.3331 16.1682L28.6317 16.9344C28.8596 17.0103 28.8596 17.3327 28.6317 17.4087L26.3331 18.1749C26.2585 18.1998 26.1999 18.2584 26.175 18.333L25.4088 20.6316C25.3328 20.8595 25.0104 20.8595 24.9345 20.6316L24.1683 18.333C24.1434 18.2584 24.0848 18.1998 24.0102 18.1749L21.7116 17.4087C21.4836 17.3327 21.4836 17.0103 21.7116 16.9344L24.0102 16.1682C24.0848 16.1433 24.1434 16.0847 24.1683 16.0101L24.9345 13.7115Z" />
    <path
      clipRule="evenodd"
      d="M20.6816 9.9041C19.9006 9.12305 18.6342 9.12306 17.8532 9.9041L2.72348 25.0338C1.94243 25.8149 1.94243 27.0812 2.72348 27.8622L4.1377 29.2764C4.91874 30.0575 6.18507 30.0575 6.96612 29.2764L22.0958 14.1467C22.8769 13.3657 22.8769 12.0994 22.0958 11.3183L20.6816 9.9041ZM20.6816 12.7325L19.2674 11.3183L16 14.5857L17.4142 16L20.6816 12.7325ZM4.1377 26.448L14.5858 16L16 17.4142L5.55191 27.8622L4.1377 26.448Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
