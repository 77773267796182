/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const AdvancedSettingsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M24.768 14.303c1.751-1.751 1.717-4.789-.283-6.788-1.999-2-5.037-2.034-6.788-.283l-3.111 3.111a1 1 0 0 1-1.414-1.414l3.11-3.111c2.623-2.623 6.93-2.404 9.617.282 2.688 2.687 2.906 6.995.283 9.617l-3.11 3.111a1 1 0 0 1-1.415-1.414l3.111-3.111ZM7.232 17.697c-1.752 1.751-1.717 4.789.283 6.788 1.999 2 5.037 2.034 6.788.283l3.111-3.111a1 1 0 1 1 1.414 1.414l-3.111 3.111c-2.623 2.623-6.93 2.404-9.617-.283-2.687-2.687-2.905-6.994-.283-9.616L8.93 13.17a1 1 0 0 1 1.414 1.415l-3.111 3.111Z" />
      <path d="M11.05 19.535a1 1 0 0 0 1.414 1.415l8.486-8.486a1 1 0 0 0-1.414-1.414l-8.486 8.485Z" />
    </BaseIcon>
  );
};
