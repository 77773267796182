import styled, { css } from "styled-components";

import themeBreakpoints from "styles/config/breakpoints";
import sizes from "styles/config/sizes";
import themeSpacing from "styles/config/spacing";

export const ScreenContentWrapper = styled.div<{ $isFirstRender?: boolean }>`
  padding: ${themeSpacing.md2};
  ${({ $isFirstRender }) =>
    !$isFirstRender &&
    css`
      transition: padding 0.5s ease-in-out;
    `}

  @media (max-width: ${themeBreakpoints.md}px) {
    padding: ${themeSpacing.sm4};
  }

  @media (max-width: ${themeBreakpoints.sm}px) {
    padding: ${themeSpacing.sm3};
  }
`;

export const CenteredContent = styled.div`
  max-width: ${sizes.modalWidthLarge};
  margin: 0 auto;
`;
