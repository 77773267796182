/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const PolyIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M9.327 5.76 10 5.5h4.5l.095.005 10.5 1 .786.52 3.5 6.5-.049 1.03-4.89 7.335-.403 2.015.89 2.224-1.28 1.307-4-1.5-.204-.104-1.5-1-.152-.125-1.314-1.314-8.747-2.43-.61-.484-3-5.5-.108-.315-.5-3 .313-.904 5.5-5Zm1.06 1.74-4.81 4.373.202 1.219 4.533-1.889L12.534 7.5h-2.147ZM8.675 19.15l7.14 1.983 1.884-3.767-6.791-4.244-4.498 1.874 2.265 4.154Zm10.456-3.248 7.849-2.616-2.607-4.841-9.53-.908-2.48 4.135 6.768 4.23Zm.578 1.916-1.342 2.684 5.515-1.378 2.312-3.468-6.485 2.162Zm-1.253 4.724.681.68 1.322.882 1.771.664-.158-.397-.052-.567.453-2.266-4.017 1.004Z" />
  </BaseIcon>
);
