import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortingRule } from 'react-table';

import { projectInit } from 'actions';
import { ApiIntegration } from 'api/resources/apiIntegrations/types';

export interface ApiIntegrationsState {
  searchInput: string;
  currentPage: number;
  currentTableSort: SortingRule<ApiIntegration>[];
}

const initialState: ApiIntegrationsState = {
  searchInput: '',
  currentPage: 0,
  currentTableSort: [],
};

const apiIntegrationsSlice = createSlice({
  name: 'apiIntegrations',
  initialState,
  reducers: {
    setSearchInput: (state, searchInput: PayloadAction<string>) => {
      state.searchInput = searchInput.payload;
    },
    setCurrentPage: (state, currentPage: PayloadAction<number>) => {
      state.currentPage = currentPage.payload;
    },
    setCurrentTableSort: (
      state,
      currentTableSort: PayloadAction<SortingRule<ApiIntegration>[]>,
    ) => {
      state.currentTableSort = [...currentTableSort.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const { setSearchInput, setCurrentPage, setCurrentTableSort } =
  apiIntegrationsSlice.actions;

export default apiIntegrationsSlice.reducer;
