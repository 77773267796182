/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const PromoteIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M28.3901 6.99765C28.6019 5.65633 27.444 4.49841 26.1026 4.7102L24.5978 4.94781C21.8839 5.37631 19.3756 6.6536 17.4329 8.59634L12.3533 13.6759L8.93911 13.6759C8.40868 13.6759 7.89997 13.8866 7.5249 14.2617L4.57515 17.2114C3.31522 18.4714 4.20755 20.6256 5.98936 20.6256L7.8178 20.6256C8.37009 20.6256 8.8178 20.1779 8.8178 19.6256C8.81779 19.0733 8.37008 18.6256 7.81779 18.6256L5.98936 18.6256L8.93911 15.6759L12.3533 15.6759C12.8838 15.6759 13.3925 15.4652 13.7675 15.0901L18.8471 10.0106C20.491 8.36669 22.6134 7.28591 24.9097 6.92334L26.4146 6.68572L26.177 8.19061C25.8144 10.4869 24.7336 12.6093 23.0897 14.2532L18.0102 19.3328C17.6351 19.7078 17.4244 20.2165 17.4244 20.747V24.1612L14.4746 27.1109L14.4747 25.2825C14.4747 24.7302 14.0269 24.2825 13.4747 24.2825C12.9224 24.2825 12.4747 24.7302 12.4747 25.2825L12.4746 27.1109C12.4746 28.8927 14.6289 29.7851 15.8889 28.5251L18.8386 25.5754C19.2137 25.2003 19.4244 24.6916 19.4244 24.1612L19.4244 20.747L24.504 15.6674C26.4467 13.7247 27.724 11.2164 28.1525 8.50253L28.3901 6.99765Z" />
    <path d="M14.5353 18.5649C14.1448 18.1744 13.5116 18.1744 13.1211 18.5649L8.87843 22.8076C8.4879 23.1981 8.4879 23.8313 8.87843 24.2218C9.26895 24.6123 9.90212 24.6123 10.2926 24.2218L14.5353 19.9791C14.9258 19.5886 14.9258 18.9555 14.5353 18.5649Z" />
  </BaseIcon>
);
