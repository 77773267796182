/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ConversationsLogIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="m15.908 23 2.592 2.963L21.093 23H27V10H10v13h5.908Zm2.592 6L15 25h-5a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h17a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-5l-3.5 4Z" />
      <path d="M6 22V6h19a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2Z" />
    </BaseIcon>
  );
};
