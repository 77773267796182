import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const BuildIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path
      clipRule="evenodd"
      d="M19.368 14.4007L20.5195 14.7558C22.4106 15.339 24.5455 14.88 26.0359 13.3896C27.2424 12.1831 27.7727 10.5534 27.6222 8.97485L25.3288 11.2683C24.3525 12.2446 22.7696 12.2446 21.7933 11.2683L20.3791 9.85404C19.4028 8.87773 19.4028 7.29482 20.3791 6.31851L22.6725 4.0251C21.0939 3.87465 19.4642 4.40491 18.2577 5.6114C16.7673 7.10182 16.3083 9.23675 16.8915 11.1279L17.2466 12.2794L4.00952 25.5165L6.13084 27.6378L19.368 14.4007ZM19.9301 16.667C22.5014 17.4599 25.415 16.8389 27.4501 14.8038C29.713 12.5409 30.2276 9.19171 28.9939 6.43478C28.7356 5.85771 27.9867 5.78195 27.5396 6.229L23.9146 9.85404C23.7193 10.0493 23.4028 10.0493 23.2075 9.85404L21.7933 8.43983C21.598 8.24457 21.598 7.92798 21.7933 7.73272L25.4183 4.10768C25.8654 3.66063 25.7896 2.9117 25.2125 2.65346C22.4556 1.41971 19.1064 1.93428 16.8435 4.19719C14.8084 6.23227 14.1874 9.14592 14.9803 11.7172L2.59531 24.1023C1.81426 24.8833 1.81426 26.1496 2.59531 26.9307L4.71663 29.052C5.49768 29.8331 6.76401 29.8331 7.54505 29.052L19.9301 16.667Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
