/* eslint-disable max-len */
import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const QuotesIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M13.568 9.584C12.642 8.514 11.188 8 9.744 8c-1.37 0-2.537.478-3.5 1.433-.962.994-1.444 2.217-1.444 3.669 0 1.338.444 2.484 1.333 3.44.852.993 2.807 1.768 4.389 1.43-.37 1.414-1.214 2.307-2.251 3.262-1.016.637-2.082 1.266-2.582 1.266l.5 2.22.007-.001c2.697-.726 4.327-1.165 6.13-3.22 1.853-2.255 2.566-4.59 2.566-7.495 0-1.796-.398-3.35-1.324-4.42Zm12.308 0C24.95 8.514 23.496 8 22.052 8c-1.37 0-2.537.478-3.5 1.433-.963.994-1.444 2.217-1.444 3.669 0 1.338.444 2.484 1.333 3.44.852.993 2.807 1.768 4.389 1.43-.37 1.414-1.214 2.307-2.251 3.262-1.016.637-2.082 1.266-2.582 1.266l.5 2.22.006-.001c2.698-.726 4.328-1.165 6.132-3.22 1.851-2.255 2.565-4.59 2.565-7.495 0-1.796-.398-3.35-1.324-4.42Z" />
    </BaseIcon>
  );
};
