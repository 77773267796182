import React, { useRef } from 'react';

import { Link } from 'react-router-dom';
import { PopupActions } from 'reactjs-popup/dist/types';

import { useRouter } from '@polyai/common/hooks/useRouter';
import {
  CatalogIcon,
  ChevronDownIcon,
  LogoutIcon,
  UserIcon,
} from '@polyai/ui/components/atoms/Icons';
import { Logo } from '@polyai/ui/components/atoms/Logo';
import { BodyStrong } from '@polyai/ui/components/atoms/Text';
import { PopupMenu } from '@polyai/ui/components/molecules/PopupMenu';

import {
  GLOBAL_PERMISSION,
  useGlobalPermissions,
} from 'hooks/useProjectResources/useGlobalPermissions';

import * as Styled from './LandingNav.styled';

interface NavHeaderProps {
  userName: string;
  handleLogout: () => void;
  handleSettingsButton?: () => void;
  logoLink: string;
  isUserSettingsEnabled?: boolean;
  isHomePage?: boolean;
}

const LandingNav: React.FC<NavHeaderProps> = ({
  userName,
  handleLogout,
  handleSettingsButton = () => {},
  logoLink,
  isUserSettingsEnabled = false,
}) => {
  const accountMenuRef = useRef<PopupActions>();
  const { pushUrl } = useRouter();
  const { userHasGlobalPermission } = useGlobalPermissions();

  const closePopupWithCallback = (callback: () => void) => {
    accountMenuRef.current?.close();

    callback();
  };

  return (
    <Styled.LandingNavWrapper>
      <Styled.LogoWrapper>
        <Link data-test-id="logo-button" to={logoLink}>
          <Logo dotsColor="textPrimaryInverse" />
        </Link>
      </Styled.LogoWrapper>
      <Styled.AccountActionsMenu>
        <PopupMenu
          contentStyle={{
            width: 'auto',
            marginTop: '8px',
          }}
          popupRef={accountMenuRef}
          position="bottom right"
          size="sm"
          trigger={
            <Styled.AccountActionsMenuButton data-test-id="menu-popup-button">
              <BodyStrong>{userName}</BodyStrong>
              <ChevronDownIcon />
            </Styled.AccountActionsMenuButton>
          }
        >
          {isUserSettingsEnabled && (
            <Styled.PopupMenuButton
              onClick={() => closePopupWithCallback(handleSettingsButton)}
            >
              <UserIcon color="iconSecondaryInverse" />
              <span data-test-id="user-settings-menu-button">
                User settings
              </span>
            </Styled.PopupMenuButton>
          )}
          {userHasGlobalPermission(GLOBAL_PERMISSION.PLATFORM_ADMIN) && (
            <Styled.PopupMenuButton
              onClick={() =>
                closePopupWithCallback(() => {
                  pushUrl('/settings/features');
                })
              }
            >
              <CatalogIcon color="iconSecondaryInverse" />
              <span>Feature flags</span>
            </Styled.PopupMenuButton>
          )}
          <Styled.PopupMenuButton
            onClick={() => closePopupWithCallback(handleLogout)}
          >
            <LogoutIcon color="iconSecondaryInverse" />
            <span>Log out</span>
          </Styled.PopupMenuButton>
        </PopupMenu>
      </Styled.AccountActionsMenu>
    </Styled.LandingNavWrapper>
  );
};

export default LandingNav;
