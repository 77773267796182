import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CreditIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 5a1 1 0 0 0-1 1v3h-1.143a4 4 0 1 0 0 8h4.286a2 2 0 1 1 0 4h-3.907c-.992 0-1.898-.56-2.341-1.447a1 1 0 0 0-1.79.894A4.618 4.618 0 0 0 14.237 23H15v3a1 1 0 1 0 2 0v-3h1.143a4 4 0 0 0 0-8h-4.286a2 2 0 1 1 0-4h3.907c.992 0 1.898.56 2.342 1.447a1 1 0 1 0 1.788-.894A4.618 4.618 0 0 0 17.765 9H17V6a1 1 0 0 0-1-1Z" />
    </BaseIcon>
  );
};
