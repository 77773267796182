import { doDelete, doGet, doPatch, doPost, doPut } from 'api/request';
import {
  LOGGER_TYPE,
  TelephonyFallbackResponse,
  TelephonyRouteResponse,
} from 'api/types';
import { store } from 'reduxStore';

export interface TelephonyRoutePrimaryKeys {
  extension: string;
  integration: string;
}

export interface TelephonyRouteRequestBody {
  client_env: string;
  language: string;
  fallback_route?: string;
  variant_id?: string;
  public_facing_number?: string;
  call_notes?: string;
}

export type CreateTelephonyRouteRequest = TelephonyRoutePrimaryKeys &
  TelephonyRouteRequestBody;

export interface UpdateTelephonyRouteRequest extends TelephonyRoutePrimaryKeys {
  body: Partial<CreateTelephonyRouteRequest>;
}

export type DeleteTelephonyRouteRequest = TelephonyRoutePrimaryKeys;

export interface CreateTelephonyFallbackRequest {
  integration: string;
  use_sip_refer: boolean;
  refer_domain: string;
  audio_location: string;
  routes: {
    [key: string]: string;
  };
}

export interface UpdateTelephonyFallbackRequest
  extends CreateTelephonyFallbackRequest {
  fallback_id: string;
}

// Routes
export const getTelephonyRoutes = (
  integration: string,
  queryString: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  return doGet<TelephonyRouteResponse>(
    `${accountId}/${projectId}/telephony/routes`,
    LOGGER_TYPE.TELEPHONY,
    { integration },
    queryString,
  );
};

export const createTelephonyRoute = ({
  extension,
  integration,
  client_env,
  language,
  fallback_route,
  variant_id,
  public_facing_number,
  call_notes,
}: CreateTelephonyRouteRequest) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  return doPost<TelephonyRouteResponse>(
    `${accountId}/${projectId}/telephony/routes`,
    LOGGER_TYPE.TELEPHONY,
    {
      extension,
      integration,
      client_env,
      language,
      fallback_route,
      variant_id,
      public_facing_number,
      call_notes,
    },
  );
};

export const updateTelephonyRoute = ({
  extension,
  integration,
  body,
}: UpdateTelephonyRouteRequest) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const encodedExtension = encodeURIComponent(extension);
  return doPatch<TelephonyRouteResponse>(
    `${accountId}/${projectId}/telephony/routes/${integration}/${encodedExtension}`,
    LOGGER_TYPE.TELEPHONY,
    body,
  );
};

export const deleteTelephonyRoute = ({
  extension,
  integration,
}: DeleteTelephonyRouteRequest) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const encodedExtension = encodeURIComponent(extension);
  return doDelete<TelephonyRouteResponse>(
    `${accountId}/${projectId}/telephony/routes/${integration}/${encodedExtension}`,
    LOGGER_TYPE.TELEPHONY,
  );
};

// Fallback
export const createTelephonyFallback = ({
  integration,
  use_sip_refer,
  refer_domain,
  audio_location,
  routes,
}: CreateTelephonyFallbackRequest) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  return doPost<TelephonyFallbackResponse>(
    `${accountId}/${projectId}/telephony/fallback/${integration}`,
    LOGGER_TYPE.TELEPHONY,
    {
      use_sip_refer,
      refer_domain,
      audio_location,
      routes,
    },
  );
};

export const updateTelephonyFallback = ({
  integration,
  fallback_id,
  use_sip_refer,
  refer_domain,
  audio_location,
  routes,
}: UpdateTelephonyFallbackRequest) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  return doPut<TelephonyFallbackResponse>(
    `${accountId}/${projectId}/telephony/fallback/${integration}/${fallback_id}`,
    LOGGER_TYPE.TELEPHONY,
    {
      use_sip_refer,
      refer_domain,
      audio_location,
      routes,
    },
  );
};
