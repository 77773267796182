import useIsFirstRender from "@polyai/common/hooks/useIsFirstRender";
import { ComponentWithChildren } from "@polyai/common/types/helpers";
import ConditionalWrapper from "@polyai/common/utils/conditionalWrapper";

import * as Styled from "./ScreenContentWrapper.styled";

type ScreenContentWrapperProps = {
  className?: string;
  center?: boolean;
};

const ScreenContentWrapper: ComponentWithChildren<
  ScreenContentWrapperProps
> = ({ className, center, children }) => {
  const isFirstRender = useIsFirstRender();
  return (
    <Styled.ScreenContentWrapper
      $isFirstRender={isFirstRender}
      className={className}
    >
      <ConditionalWrapper
        condition={!!center}
        wrapper={(children) => (
          <Styled.CenteredContent>{children}</Styled.CenteredContent>
        )}
      >
        {children}
      </ConditionalWrapper>
    </Styled.ScreenContentWrapper>
  );
};

export default ScreenContentWrapper;
