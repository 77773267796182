/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const EntitiesIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M10 2a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h5v4H8a3 3 0 0 0-3 3v2H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H7v-2a1 1 0 0 1 1-1h7v3h-1a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-1v-3h7a1 1 0 0 1 1 1v2h-1a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-1v-2a3 3 0 0 0-3-3h-7v-4h5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H10Zm12 2v7H10V4h12Zm-4 20v4h-4v-4h4Zm6 0h4v4h-4v-4Z" />
    </BaseIcon>
  );
};
