import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ZoomOutIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M20 15a1 1 0 0 0-1-1h-8a1 1 0 1 0 0 2h8a1 1 0 0 0 1-1Z" />
    <path d="M15 25a9.958 9.958 0 0 0 6.329-2.257l4.464 4.464a1 1 0 0 0 1.414-1.414l-4.464-4.464A9.958 9.958 0 0 0 25 15c0-5.523-4.477-10-10-10S5 9.477 5 15s4.477 10 10 10Zm0-2a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z" />
  </BaseIcon>
);
