/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const SearchIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M20.473 21.887A9.46 9.46 0 0 1 14.5 24a9.5 9.5 0 1 1 9.5-9.5 9.46 9.46 0 0 1-2.113 5.973l5.32 5.32a1 1 0 0 1-1.414 1.414l-5.32-5.32Zm-.785-1.97a1.014 1.014 0 0 1 .228-.23 7.5 7.5 0 1 0-.229.229Z" />
  </BaseIcon>
);
