/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const MaximiseIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <path d="M25.6065 12.4641C25.6065 13.0164 26.0542 13.4641 26.6065 13.4641C27.1588 13.4641 27.6065 13.0164 27.6065 12.4641V5.40374L27.6066 5.39349C27.6066 5.35134 27.604 5.30981 27.5989 5.26904C27.5378 4.77528 27.1168 4.39307 26.6065 4.39307C26.5968 4.39307 26.587 4.39321 26.5773 4.39349L19.5355 4.39348C18.9832 4.39348 18.5355 4.8412 18.5355 5.39348C18.5355 5.94577 18.9832 6.39348 19.5355 6.39348L24.1923 6.39349L17.4142 13.1715C17.0237 13.5621 17.0237 14.1952 17.4142 14.5858C17.8048 14.9763 18.4379 14.9763 18.8284 14.5858L25.6065 7.80766V12.4641Z" />
      <path d="M14.5858 18.8282C14.9763 18.4377 14.9763 17.8045 14.5858 17.414C14.1952 17.0235 13.5621 17.0235 13.1715 17.414L6.39349 24.192L6.39348 19.5358C6.39348 18.9835 5.94577 18.5358 5.39348 18.5358C4.8412 18.5358 4.39348 18.9835 4.39348 19.5358L4.39349 26.596L4.39343 26.6064C4.39343 26.6493 4.39613 26.6916 4.40137 26.733C4.46343 27.2257 4.88395 27.6068 5.39349 27.6068C5.40325 27.6068 5.41298 27.6067 5.42267 27.6064L12.4645 27.6064C13.0168 27.6064 13.4645 27.1587 13.4645 26.6064C13.4645 26.0541 13.0168 25.6064 12.4645 25.6064L7.80755 25.6064L14.5858 18.8282Z" />
    </BaseIcon>
  );
};
