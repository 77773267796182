/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const PlayIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M7 6.505v18.99c0 1.549 1.684 2.51 3.018 1.722l16.068-9.495c1.31-.774 1.31-2.67 0-3.444L10.018 4.783C8.684 3.995 7 4.956 7 6.505Z" />
    </BaseIcon>
  );
};
