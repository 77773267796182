import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ConfirmationIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 26c-5.523 0-10-4.477-10-10S10.477 6 16 6s10 4.477 10 10-4.477 10-10 10Zm0 2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z" />
      <path d="M21.314 12.707a1 1 0 0 0-1.414 0l-5.657 5.657-2.122-2.121a1 1 0 0 0-1.414 1.414l2.829 2.828a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0 0-1.414Z" />
    </BaseIcon>
  );
};
