import { forwardRef, InputHTMLAttributes, useCallback } from "react";

type PropTypes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onKeyPress" | "onKeyPressCapture" | "onKeyDownCapture" | "onKeyUpCapture"
> & {
  isKeyboardSafe?: boolean;
};

/**
 * This component stops propagation of keyboard events to the document
 * so that our keyboard shortcut listeners are not triggered.
 *
 * It should be used instead of <input/> tags unless absolutely
 * necessary.
 */
export const KeyboardSafeInput = forwardRef<HTMLInputElement, PropTypes>(
  (props, ref) => {
    const wrapKeyboardFn = useCallback(
      (fnName: keyof PropTypes) =>
        (...args: any[]) => {
          props[fnName]?.(...args);
          if (!props.isKeyboardSafe) {
            args[0]?.stopPropagation?.();
          }
        },
      [props]
    );

    return (
      <input
        {...props}
        ref={ref}
        role="input"
        onKeyDown={wrapKeyboardFn("onKeyDown")}
        onKeyUp={wrapKeyboardFn("onKeyUp")}
      />
    );
  }
);
