import React, { FC } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { useRouter } from '@polyai/common/hooks/useRouter';
import AccountsSidebar, {
  SidebarAccount,
} from '@polyai/ui/components/molecules/AccountsSidebar';

import LandingNav from 'components/molecules/LandingNav';

import { useAppSelector } from 'hooks/reduxHooks';
import useAccounts from 'hooks/useAccounts';
import useFeatureSwitch from 'hooks/useFeatureSwitch';
import { PATHS } from 'routes/routes.constants';

import * as Styled from './LandingLayout.styled';

const StudioLandingLayout: FC = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();

  useAccounts();
  const { accounts } = useAppSelector((state) => state.accountInfo);

  const { user } = useAuth0();
  const { pushUrl } = useRouter();

  const logout = useAppSelector((state) => state.auth.logout);

  const { isFeatureOn } = useFeatureSwitch();
  const isCreatingProject = useAppSelector(
    (state) => state.layout.isCreatingProject,
  );

  const sidebarAccounts: SidebarAccount[] = accounts.map((account) => ({
    id: account.account_id,
    name: account.account_name,
    abbreviation: account.short_name,
  }));

  return (
    <>
      {!isCreatingProject && (
        <LandingNav
          handleLogout={() => logout()}
          handleSettingsButton={() => pushUrl(PATHS.USER_SETTINGS)}
          isUserSettingsEnabled={isFeatureOn('user-settings')}
          logoLink={accounts[0]?.account_id ?? '/'}
          userName={user?.name as string}
        />
      )}
      <Styled.PageWrapper>
        {!isCreatingProject && (
          <AccountsSidebar
            accounts={sidebarAccounts}
            activeAccountId={accountId}
          />
        )}
        <Outlet />
      </Styled.PageWrapper>
    </>
  );
};

export default StudioLandingLayout;
