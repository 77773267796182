import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get as getFromLocalStorage } from 'local-storage';

import { closeSidePanel, projectInit } from 'actions';

export type SidePanelType = 'node' | 'audio-testing';

export const MIN_SIDE_PANEL_WIDTH = 415;

export interface LayoutState {
  isCreatingProject: boolean;
  isMainNavOpen: boolean;
  sidePanelOpen: SidePanelType | null;
  sidePanelWidths: { [id in SidePanelType]: number };
}
const initialState: LayoutState = {
  isCreatingProject: false,
  isMainNavOpen: true,
  sidePanelOpen: null,
  sidePanelWidths: {
    node: getFromLocalStorage('polyai.sidePanelWidth') || MIN_SIDE_PANEL_WIDTH,
    'audio-testing': 650,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openMainNav: (state) => {
      state.isMainNavOpen = true;
    },
    closeMainNav: (state) => {
      state.isMainNavOpen = false;
    },
    toggleMainNav: (state) => {
      state.isMainNavOpen = !state.isMainNavOpen;
    },
    openSidePanel: (state, { payload: id }: PayloadAction<SidePanelType>) => {
      state.sidePanelOpen = id;
    },
    setSidePanelWidth: (
      state,
      {
        payload: { id, width },
      }: PayloadAction<{
        id: SidePanelType;
        width: number;
      }>,
    ) => {
      state.sidePanelWidths[id] = width;
    },
    setIsCreatingProject: (
      state,
      { payload: creating }: PayloadAction<boolean>,
    ) => {
      state.isCreatingProject = creating;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeSidePanel, (state) => {
        state.sidePanelOpen = null;
      })
      .addCase(projectInit, () => {
        return { ...initialState };
      });
  },
});

export const {
  closeMainNav,
  openMainNav,
  openSidePanel,
  setIsCreatingProject,
  setSidePanelWidth,
  toggleMainNav,
} = layoutSlice.actions;

export default layoutSlice.reducer;
