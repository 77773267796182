import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const FunctionIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M16.35 22.665c-.5 0-.905-.138-1.215-.413-.31-.276-.465-.646-.465-1.111 0-.448.138-.81.414-1.085a1.36 1.36 0 0 1 1.033-.44 1.9 1.9 0 0 1 .982.26c.327.171.576.257.749.257.31 0 .594-.223.852-.671l1.343-2.17-.8-4.211c-.12-.603-.267-1.008-.44-1.214-.172-.224-.49-.336-.955-.336h-.233l-.568.026v-.62a36.376 36.376 0 0 0 2.583-.413 5.99 5.99 0 0 1 .775-.13 3.67 3.67 0 0 1 .517-.051c.344 0 .602.146.775.439.086.138.163.336.232.594.07.258.138.577.207.956l.31 1.524c.775-1.206 1.455-2.101 2.04-2.687.603-.585 1.258-.878 1.964-.878.465 0 .835.146 1.11.44.294.275.44.628.44 1.058 0 .43-.12.784-.362 1.06a1.079 1.079 0 0 1-.852.387c-.12 0-.293-.034-.517-.103a6.091 6.091 0 0 1-.594-.233 2.811 2.811 0 0 0-.336-.129 1.242 1.242 0 0 0-.361-.078c-.276 0-.491.087-.646.259a18.17 18.17 0 0 0-.543.723c-.275.362-.646.878-1.11 1.55l.748 3.72c.224 1.102.603 1.653 1.137 1.653.224 0 .465-.137.724-.413.137-.138.284-.293.439-.465.155-.19.327-.396.516-.62l.517.388c-.74 1.05-1.395 1.834-1.963 2.35-.551.517-1.171.775-1.86.775-.69 0-1.232-.31-1.628-.93-.379-.637-.706-1.636-.981-2.996l-1.266 2.04c-.792 1.292-1.697 1.938-2.713 1.938Z" />
    <path d="M6.427 27.6c-.671 0-1.214-.199-1.627-.595-.43-.396-.646-.896-.646-1.498 0-.414.12-.758.362-1.034.24-.258.56-.387.956-.387.344 0 .628.103.852.31.224.224.336.517.336.878 0 .293-.069.534-.207.723-.138.173-.206.293-.206.362 0 .258.146.388.439.388.361 0 .689-.19.981-.569.276-.361.517-.835.724-1.42.24-.655.482-1.525.723-2.61.258-1.085.534-2.385.827-3.9l1.317-6.588H9.476l.206-1.059h1.809c.55-2.153 1.317-3.763 2.299-4.83.981-1.068 2.084-1.602 3.306-1.602.861 0 1.524.19 1.99.568.482.362.723.844.723 1.447 0 .93-.448 1.395-1.344 1.395-.344 0-.645-.12-.904-.362a1.166 1.166 0 0 1-.361-.853c0-.24.069-.456.206-.645.138-.19.207-.345.207-.465 0-.19-.138-.284-.413-.284-.517 0-.982.439-1.395 1.317-.43.913-.861 2.35-1.292 4.314h2.196l-.155 1.06h-2.196l-1.343 6.25c-.465 2.188-.99 3.996-1.576 5.426-.568 1.446-1.257 2.514-2.067 3.203-.792.706-1.774 1.06-2.945 1.06Z" />
  </BaseIcon>
);
