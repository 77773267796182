import styled from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";

export const LabelWithHelp = styled.div`
  cursor: pointer;
  text-decoration: underline dashed ${themeColors.borderStrong};
  text-underline-offset: ${themeSpacing.xxs2};
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;
