import { UPLOAD_STATUS_NOT_UPLOADING } from 'constants/Screen.constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadType } from 'components/organisms/UploadPanel/UploadPanel.constants';

import {
  closeAllPanels,
  closeOtherPanels,
  projectInit,
  resetUploadStatus,
} from 'actions';

interface UploadPanelState {
  openUploadPanels: {
    [key in UploadType]: boolean;
  };
  uploadsInProgress: {
    [key in UploadType]: boolean;
  };
  uploadStatus: {
    [key in UploadType]: {
      status: string;
      error: boolean;
      numberOfFiles: number;
    };
  };
  isUploadPanelMinimized: {
    [key in UploadType]: boolean;
  };
}

const initialState: UploadPanelState = {
  openUploadPanels: {
    [UploadType.AUDIO]: false,
    [UploadType.INTENTS]: false,
    [UploadType.DATA_STORE]: false,
    [UploadType.SCENARIOS]: false,
  },
  uploadsInProgress: {
    [UploadType.AUDIO]: false,
    [UploadType.INTENTS]: false,
    [UploadType.DATA_STORE]: false,
    [UploadType.SCENARIOS]: false,
  },
  uploadStatus: {
    [UploadType.AUDIO]: {
      status: UPLOAD_STATUS_NOT_UPLOADING,
      error: false,
      numberOfFiles: 0,
    },
    [UploadType.INTENTS]: {
      status: UPLOAD_STATUS_NOT_UPLOADING,
      error: false,
      numberOfFiles: 0,
    },
    [UploadType.DATA_STORE]: {
      status: UPLOAD_STATUS_NOT_UPLOADING,
      error: false,
      numberOfFiles: 0,
    },
    [UploadType.SCENARIOS]: {
      status: UPLOAD_STATUS_NOT_UPLOADING,
      error: false,
      numberOfFiles: 0,
    },
  },
  isUploadPanelMinimized: {
    [UploadType.AUDIO]: false,
    [UploadType.INTENTS]: false,
    [UploadType.DATA_STORE]: false,
    [UploadType.SCENARIOS]: false,
  },
};

const uploadPanelSlice = createSlice({
  name: 'uploadPanel',
  initialState,
  reducers: {
    openUploadPanel: (state, data: PayloadAction<UploadType>) => {
      state.openUploadPanels[data.payload] = true;
    },
    closeUploadPanel: (state, data: PayloadAction<UploadType>) => {
      state.openUploadPanels[data.payload] = false;
    },
    startUploading: (state, data: PayloadAction<UploadType>) => {
      state.uploadsInProgress[data.payload] = true;
    },
    terminateUploading: (state, data: PayloadAction<UploadType>) => {
      state.uploadsInProgress[data.payload] = false;
      state.openUploadPanels[data.payload] = false;
      state.isUploadPanelMinimized[data.payload] = false;
    },
    setUploadStatus: (
      state,
      data: PayloadAction<{
        uploadType: UploadType;
        data: {
          status: string;
          error: boolean;
          numberOfFiles: number;
        };
      }>,
    ) => {
      const { payload } = data;
      state.uploadStatus[payload.uploadType] = payload.data;
    },
    setIsUploadPanelMinimized: (
      state,
      data: PayloadAction<{ uploadType: UploadType; isMinimized: boolean }>,
    ) => {
      state.isUploadPanelMinimized[data.payload.uploadType] =
        data.payload.isMinimized;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(closeAllPanels, (state) => {
        state.openUploadPanels = { ...initialState.openUploadPanels };
      })
      .addCase(
        closeOtherPanels,
        (state, data: PayloadAction<UploadType | undefined>) => {
          Object.keys(state.openUploadPanels).forEach((key) => {
            if (key !== data.payload) {
              if (state.openUploadPanels[key as UploadType]) {
                state.isUploadPanelMinimized[key as UploadType] = true;
              }
              state.openUploadPanels[key as UploadType] = false;
            } else {
              state.openUploadPanels[key as UploadType] = true;
            }
          });
        },
      )
      .addCase(
        resetUploadStatus,
        (state, data: PayloadAction<UploadType | undefined>) => {
          const uploadType = data.payload;
          if (uploadType) {
            state.uploadStatus[uploadType] = {
              status: UPLOAD_STATUS_NOT_UPLOADING,
              error: false,
              numberOfFiles: 0,
            };
          }
        },
      );
  },
});

export const {
  openUploadPanel,
  closeUploadPanel,
  startUploading,
  terminateUploading,
  setUploadStatus,
  setIsUploadPanelMinimized,
} = uploadPanelSlice.actions;

export default uploadPanelSlice.reducer;
