import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const MailIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path
      d="M3 8a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8Zm23.197 0H5.803l9.642 6.428a1 1 0 0 0 1.11 0L26.197 8ZM5 9.869V24h22V9.869l-9.336 6.224a3 3 0 0 1-3.328 0L5 9.869Z"
      xmlns="http://www.w3.org/2000/svg"
    />
  </BaseIcon>
);
