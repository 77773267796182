import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { projectInit } from 'actions';
import {
  MatchingPrompt,
  MatchingPromptWithIndices,
} from 'api/resources/prompts/types';

export type AudioTestingPrompt = MatchingPrompt & {
  uuid: string;
  elapsedTime: number;
  duration: number;
  isLoaded: boolean;
};

type AudioTestingState = {
  highlightedPrompt?: MatchingPromptWithIndices;
  selectedPrompts: AudioTestingPrompt[];
};

const initialState: AudioTestingState = {
  selectedPrompts: [],
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setAudioTestingPrompts: (
      state,
      { payload: prompts }: PayloadAction<MatchingPrompt[]>,
    ) => {
      state.selectedPrompts = prompts.map((prompt) => ({
        ...prompt,
        uuid: uuidv4(),
        elapsedTime: 0,
        duration: 0,
        isLoaded: false,
      }));
    },
    addAudioTestingPrompt: (
      state,
      { payload: prompt }: PayloadAction<MatchingPrompt>,
    ) => {
      state.selectedPrompts.push({
        ...prompt,
        uuid: uuidv4(),
        elapsedTime: 0,
        duration: 0,
        isLoaded: false,
      });
    },
    removeAudioTestingPrompt: (
      state,
      { payload: uuid }: PayloadAction<string>,
    ) => {
      state.selectedPrompts = state.selectedPrompts.filter(
        (prompt) => prompt.uuid !== uuid,
      );
    },
    setAudioTestingPromptDuration: (
      state,
      {
        payload: { uuid, duration },
      }: PayloadAction<{ uuid: string; duration: number }>,
    ) => {
      state.selectedPrompts = state.selectedPrompts.map((p) =>
        p.uuid === uuid ? { ...p, duration } : p,
      );
    },
    setAudioTestingPromptElapsedTime: (
      state,
      {
        payload: { uuid, elapsedTime },
      }: PayloadAction<{ uuid: string; elapsedTime: number }>,
    ) => {
      state.selectedPrompts = state.selectedPrompts.map((p) =>
        p.uuid === uuid ? { ...p, elapsedTime } : p,
      );
    },
    setAudioTestingPromptLoaded: (
      state,
      {
        payload: { uuid, isLoaded },
      }: PayloadAction<{ uuid: string; isLoaded: boolean }>,
    ) => {
      state.selectedPrompts = state.selectedPrompts.map((p) =>
        p.uuid === uuid ? { ...p, isLoaded } : p,
      );
    },
    clearAudioTestingPrompts: (state) => {
      state.selectedPrompts = [];
    },
    clearAudioTesting: () => {
      return { ...initialState };
    },
    setHighlightedPrompt: (
      state,
      { payload: prompt }: PayloadAction<MatchingPromptWithIndices>,
    ) => {
      state.highlightedPrompt = prompt;
    },
    clearHighlightedPrompt: (state) => {
      state.highlightedPrompt = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const {
  addAudioTestingPrompt,
  clearAudioTesting,
  clearAudioTestingPrompts,
  removeAudioTestingPrompt,
  setAudioTestingPromptDuration,
  setAudioTestingPromptElapsedTime,
  setAudioTestingPromptLoaded,
  setAudioTestingPrompts,
  setHighlightedPrompt,
  clearHighlightedPrompt,
} = audioSlice.actions;

export default audioSlice.reducer;
