import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DownloadIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="m10.26 16.173 4.991 5.49.042.044a.996.996 0 0 0 1.414 0 .957.957 0 0 0 .042-.044l4.99-5.49a1 1 0 0 0-1.479-1.346L17 18.413V7a1 1 0 1 0-2 0v11.413l-3.26-3.586a1 1 0 0 0-1.48 1.346Z" />
    <path d="M25 26a1 1 0 0 0 1-1v-3a1 1 0 1 0-2 0v2H8v-2a1 1 0 1 0-2 0v3a1 1 0 0 0 .987 1H25Z" />
  </BaseIcon>
);
