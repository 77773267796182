import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface PageStatusState {
  pageNotFound: boolean;
  hasAccess: boolean;
  isSiteUnavailable: boolean;
}

const initialState: PageStatusState = {
  pageNotFound: false,
  hasAccess: true,
  isSiteUnavailable: false,
};

const pageStatusSlice = createSlice({
  name: 'pageStatus',
  initialState,
  reducers: {
    setPageNotFound: (state, pageNotFound: PayloadAction<boolean>) => {
      state.pageNotFound = pageNotFound.payload;
    },
    setHasAccess: (state, hasAccess: PayloadAction<boolean>) => {
      state.hasAccess = hasAccess.payload;
    },
    setIsSiteUnavailable: (
      state,
      isSiteUnavailable: PayloadAction<boolean>,
    ) => {
      state.isSiteUnavailable = isSiteUnavailable.payload;
    },
  },
});

export const { setPageNotFound, setHasAccess, setIsSiteUnavailable } =
  pageStatusSlice.actions;

export default pageStatusSlice.reducer;
