import { useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { extractDomainFromEmail } from '@polyai/common/lib/emailHelper';

const POLY_EMAILS = ['poly-ai.com', 'polyai.com', 'poly.ai'];

export const useUserAccess = () => {
  const { user } = useAuth0();
  const isInternalUser = useMemo(
    () => POLY_EMAILS.includes(extractDomainFromEmail(user?.email)),
    [user?.email],
  );

  const userDomain = useMemo(
    () => extractDomainFromEmail(user?.email),
    [user?.email],
  );

  return { isInternalUser, userDomain };
};
