/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ExternalLinkIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M20 7a1 1 0 0 1 1-1h6v6a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L23.586 8H21a1 1 0 0 1-1-1Z" />
    <path d="M16 7a1 1 0 1 1 0 2H8v16h16v-8a1 1 0 1 1 2 0v8a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h8Z" />
  </BaseIcon>
);
