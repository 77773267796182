/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeStartIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M7 5a1 1 0 0 1 2 0v.494c.126-.049.257-.098.394-.148 1.01-.369 2.38-.79 3.56-.845 1.457-.068 2.54.398 3.443.79l.013.005C17.317 5.69 18.032 6 19 6c1.047 0 2.425-.334 3.607-.704a27.384 27.384 0 0 0 1.921-.683A1 1 0 0 1 26 5.495V16a1 1 0 0 1-.616.924l.002.004h-.002l-.002.002-.01.004-.034.014c-.03.012-.072.03-.126.05a29.45 29.45 0 0 1-2.008.711c-1.213.38-2.836.797-4.204.797-1.388 0-2.437-.457-3.299-.832l-.1-.043c-.89-.387-1.6-.671-2.555-.627-.857.04-1.987.369-2.967.726-.42.154-.793.305-1.079.426V27a1 1 0 1 1-2 0V5Zm17 10.323v-8.38c-.244.083-.511.172-.796.261C21.991 7.584 20.368 8 19 8c-1.388 0-2.437-.456-3.299-.831l-.1-.044c-.89-.386-1.6-.67-2.555-.626-.857.04-1.987.368-2.967.726-.42.153-.793.304-1.079.426v8.348c.126-.048.257-.098.394-.147 1.01-.37 2.38-.79 3.56-.845 1.457-.068 2.54.398 3.443.79l.013.005c.907.393 1.622.704 2.59.704 1.047 0 2.425-.335 3.607-.705.55-.172 1.036-.345 1.393-.478Z" />
    </BaseIcon>
  );
};
