/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const BellIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <path
        d="M14.194 5.14a2 2 0 0 1 3.612 0c4.329.699 6.527 3.952 6.527 7.86v3.5a1 1 0 0 0 .025.22l1.367 6.06a1 1 0 0 1-.976 1.22H20a4 4 0 0 1-8 0H7.25a1 1 0 0 1-.975-1.22l1.367-6.06a1 1 0 0 0 .025-.22V13c0-3.908 2.198-7.161 6.527-7.86Zm8.213 12.02a3.003 3.003 0 0 1-.074-.66V13c0-1.666-.49-3.056-1.338-4.05-.831-.977-2.134-1.726-4.071-1.908A9.859 9.859 0 0 0 16 7a9.86 9.86 0 0 0-.924.042c-1.937.182-3.24.931-4.071 1.907-.848.995-1.338 2.385-1.338 4.051v3.5a3 3 0 0 1-.074.66L8.501 22H23.5l-1.092-4.84ZM16 26a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2Z"
        xmlns="http://www.w3.org/2000/svg"
      />
    </BaseIcon>
  );
};
