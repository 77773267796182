import { hsl, rgb } from "d3";

import interpolateHsluv from "./interpolator/interpolateHsluv";

export type ColorRamp = {
  "05": string;
  "10": string;
  "20": string;
  "30": string;
  "40": string;
  "50": string;
  "60": string;
  "70": string;
  "80": string;
  "90": string;
  "100": string;
};

const exponentialRamp = (x: number, coefficient: number, clamp: number) =>
  Math.min(2 ** (x * coefficient) - 1, clamp);

const generateColorRamp = (
  start: string,
  target: string,
  end: string,
  exponentialCoefficient?: number
): ColorRamp => {
  const values = [];

  for (let i = 0; i < 5; i++) {
    let x = i / 5;

    if (exponentialCoefficient) {
      x = exponentialRamp(x, exponentialCoefficient, 4 / 5);
    }

    const value = interpolateHsluv(hsl(start), hsl(target))(x);
    values.push(rgb(value).formatHex());
  }

  values.push(target);

  for (let i = 1; i <= 5; i++) {
    const value = interpolateHsluv(hsl(target), hsl(end))(i / 5);
    values.push(rgb(value).formatHex());
  }

  return {
    "05": values[0],
    "10": values[1],
    "20": values[2],
    "30": values[3],
    "40": values[4],
    "50": values[5],
    "60": values[6],
    "70": values[7],
    "80": values[8],
    "90": values[9],
    "100": values[10],
  };
};

export default generateColorRamp;
