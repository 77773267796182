import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PromptsAudioMap = {
  [prompt: string]: { prompt: string; id: string };
};

export type PromptsAudioUrlMap = {
  [prompt: string]: {
    public_url: string;
    recording_key: string;
    recording_duration?: string;
  };
};

interface AudioState {
  promptsAudioMap: PromptsAudioMap;
  promptsAudioUrlMap: PromptsAudioUrlMap;
}

const initialState: AudioState = {
  promptsAudioMap: {},
  promptsAudioUrlMap: {},
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    updatePromptsAudioMap: (
      { promptsAudioMap },
      { payload: updates }: PayloadAction<PromptsAudioMap>,
    ) => {
      Object.assign(promptsAudioMap, updates);
    },
    updatePromptsAudioUrlMap: (
      { promptsAudioUrlMap },
      { payload: updates }: PayloadAction<PromptsAudioUrlMap>,
    ) => {
      Object.assign(promptsAudioUrlMap, updates);
    },
    resetPromptsAudioMap: (state) => {
      state.promptsAudioMap = {};
    },
    resetPromptsAudioUrlMap: (state) => {
      state.promptsAudioUrlMap = {};
    },
  },
});

export const {
  updatePromptsAudioMap,
  updatePromptsAudioUrlMap,
  resetPromptsAudioMap,
  resetPromptsAudioUrlMap,
} = audioSlice.actions;

export default audioSlice.reducer;
