export enum ErrorStatus {
  NOT_FOUND = '404',
  FORBIDDEN = '403',
  UNAUTHORIZED = '401',
  UNAVAILABLE = '503',
  UNHANDLED_ERROR = '500',
  EMAIL_UNVERIFIED = '300',
}

export type ErrorPageProps = {
  type: ErrorStatus;
  customMessage?: string;
  className?: string;
};
