import React, { MouseEvent, Ref, useCallback, useRef, useState } from "react";

import { EventType, PopupPosition, PopupProps } from "reactjs-popup/dist/types";

import useButtonNavigation from "@polyai/common/hooks/useButtonNavigation";

import { IconButton } from "components/atoms/IconButton";
import { ContextMenuIcon } from "components/atoms/Icons";
import { IconSize } from "components/atoms/Icons/BaseIcon";

import { ColorName } from "styles/config/colors";

import "reactjs-popup/dist/index.css";
import * as Styled from "./PopupMenu.styled";

export interface PopupMenuType extends React.HTMLAttributes<HTMLButtonElement> {
  position?: PopupPosition;
  size?: keyof typeof IconSize;
  hoverSize?: keyof typeof IconSize;
  nested?: boolean;
  trigger?: PopupProps["trigger"];
  popupRef?: Ref<any>;
  open?: PopupProps["open"];
  onOpen?: PopupProps["onOpen"];
  onClose?: PopupProps["onClose"];
  contentStyle?: PopupProps["contentStyle"];
  keepTooltipInside?: PopupProps["keepTooltipInside"];
  offsetY?: PopupProps["offsetY"];
  offsetX?: PopupProps["offsetX"];
  on?: EventType | EventType[];
  hoverTarget?: boolean;
  hoverTargetColor?: ColorName;
  "data-test-id"?: string;
  isDisabled?: boolean;
  className?: string;
  closeOnEscape?: PopupProps["closeOnEscape"];
  closeOnDocumentClick?: PopupProps["closeOnDocumentClick"];
}

type PotentialEvent =
  | React.SyntheticEvent
  | KeyboardEvent
  | TouchEvent
  | MouseEvent
  | undefined;

const handleClick = (stopPropagation: boolean) => (e: MouseEvent) => {
  e.preventDefault();
  if (stopPropagation) {
    e.stopPropagation();
  }
};

const stopPropagation = handleClick(true);
const preventDefault = handleClick(false);

export const PopupMenu: React.FC<PopupMenuType> = ({
  children,
  className,
  closeOnDocumentClick = true,
  closeOnEscape = true,
  contentStyle,
  hoverSize = "xxl",
  hoverTarget = false,
  hoverTargetColor,
  isDisabled = false,
  keepTooltipInside = true,
  nested = false,
  offsetX,
  offsetY,
  on,
  onClose = undefined,
  onOpen = undefined,
  open,
  popupRef = null,
  position = "right center",
  size = "md",
  trigger,
  ...props
}: PopupMenuType) => {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const contentRef = useRef<HTMLDivElement>(null);

  useButtonNavigation(
    contentRef,
    ["ArrowDown", { key: "Tab", shiftKey: false }],
    ["ArrowUp", { key: "Tab", shiftKey: true }],
    isOpen
  );

  const handleOpen = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, [onOpen]);

  const handleClose = useCallback(
    (event: PotentialEvent) => {
      onClose?.(event);
      setIsOpen(false);
    },
    [onClose]
  ) as PopupProps["onClose"];

  return (
    <div className={className} onClick={stopPropagation}>
      <Styled.Popup
        ref={popupRef}
        arrow={false}
        className={className}
        closeOnDocumentClick={closeOnDocumentClick}
        closeOnEscape={closeOnEscape}
        contentStyle={contentStyle}
        disabled={isDisabled}
        keepTooltipInside={keepTooltipInside}
        nested={nested}
        offsetX={offsetX}
        offsetY={offsetY}
        on={on}
        open={open}
        position={position}
        trigger={
          trigger || (
            <div>
              <IconButton
                data-test-id="action-menu-button"
                disabled={isDisabled}
                hoverSize={hoverSize}
                hoverTarget={hoverTarget}
                hoverTargetColor={hoverTargetColor}
                iconSize={size}
                onClick={preventDefault}
                {...props}
              >
                <ContextMenuIcon size={size} />
              </IconButton>
            </div>
          )
        }
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <div ref={contentRef}>{children}</div>
      </Styled.Popup>
    </div>
  );
};
