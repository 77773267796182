import { doDelete, doGet, doPut } from 'api/request';
import { Entity, LOGGER_TYPE } from 'api/types';
import { store } from '../../reduxStore';

export type BuiltinEntitiesType = {
  builtins: string[];
  custom: string[];
  version: string;
};

export type EntityResponseType = Entity[];

type EntityActionResponse = {
  success: boolean;
  version: string;
  draft: boolean;
};

export const getCatalogEntities = () => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<EntityResponseType>(
    'entities/builtins/catalog' +
      `?account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.ENTITIES,
  );
};

export const getBuiltInEntities = () => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<BuiltinEntitiesType>(
    `entities/builtins?account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.ENTITIES,
  );
};

export const enableEntity = (entity: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPut<EntityActionResponse>(
    'entities/builtins',
    LOGGER_TYPE.ENTITIES,
    {
      builtins: [entity],
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
    },
  );
};

export const disableEntity = (entity: string) => {
  const accountId = store.getState().accountInfo.id;

  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doDelete<EntityActionResponse>(
    'entities/builtins',
    LOGGER_TYPE.ENTITIES,
    {
      builtins: [entity],
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
    },
  );
};
