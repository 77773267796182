/* eslint-disable max-len */
import React from "react";

import { OldBaseIcon } from "./OldBaseIcon";

export const DoubleArrowRight = () => {
  return (
    <OldBaseIcon height="31" style={{ transform: "rotate(180deg)" }} width="31">
      <path
        clipRule="evenodd"
        d="M16.2264 9.29168C16.5193 8.99879 16.5193 8.52392 16.2264 8.23102C15.9335 7.93813 15.4586 7.93813 15.1657 8.23102L8.64399 14.7528C8.35109 15.0457 8.35109 15.5205 8.64399 15.8134L15.1657 22.3352C15.4586 22.6281 15.9335 22.6281 16.2264 22.3352C16.5193 22.0423 16.5193 21.5674 16.2264 21.2745L10.235 15.2831L16.2264 9.29168ZM22.7481 9.29168C23.041 8.99879 23.041 8.52392 22.7481 8.23102C22.4552 7.93813 21.9804 7.93813 21.6875 8.23102L15.1657 14.7528C15.0251 14.8934 14.9461 15.0842 14.9461 15.2831C14.9461 15.482 15.0251 15.6728 15.1657 15.8134L21.6875 22.3352C21.9804 22.6281 22.4552 22.6281 22.7481 22.3352C23.041 22.0423 23.041 21.5674 22.7481 21.2745L16.7567 15.2831L22.7481 9.29168Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </OldBaseIcon>
  );
};
