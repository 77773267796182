/* eslint-disable max-len */
import React from "react";

import { OldBaseIcon } from "./OldBaseIcon";

export const MenuIcon = () => {
  return (
    <OldBaseIcon height={70} width={70}>
      <path
        d="M28.39 29.462a.616.616 0 100 1.23h14.77a.615.615 0 100-1.23H28.39zM27.776 35a.615.615 0 01.616-.615H43.16a.615.615 0 110 1.23H28.39a.616.616 0 01-.615-.615zm0 4.923a.615.615 0 01.616-.615H43.16a.615.615 0 010 1.23H28.39a.616.616 0 01-.615-.615z"
        fill="currentColor"
      />
    </OldBaseIcon>
  );
};
