import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const TransitionIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M6 3a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H6Zm5 2v5H6V5h5Zm10 15a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2h-5Zm5 2v5h-5v-5h5Z" />
    <path d="M16.5 8.5h6V15h-13a2 2 0 0 0-2 2v6.5a2 2 0 0 0 2 2h6a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-6V17h13a2 2 0 0 0 2-2V8.5a2 2 0 0 0-2-2h-6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5Z" />
  </BaseIcon>
);
