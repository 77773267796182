import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectInit } from 'actions';
import { AccountData as Account } from 'api/resources/account/types';
export interface AccountInfoState {
  id: string;
  accounts: Account[];
}

const initialState: AccountInfoState = {
  id: '',
  accounts: [],
};

const accountSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    setAccountId: (state, id: PayloadAction<string>) => {
      state.id = id.payload;
    },
    setAccounts: (state, accounts: PayloadAction<Account[]>) => {
      state.accounts = accounts.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, (state) => {
      return { id: '', accounts: state.accounts };
    });
  },
});

export const { setAccountId, setAccounts } = accountSlice.actions;

export default accountSlice.reducer;
