/* eslint-disable max-len */
import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const LogoutIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M11.76 10.327a1 1 0 0 0 .067 1.413L15.413 15H5a1 1 0 1 0 0 2h10.413l-3.586 3.26a1 1 0 0 0 1.346 1.48l5.49-4.991a1.004 1.004 0 0 0 0-1.498l-5.49-4.99a1 1 0 0 0-1.413.066ZM21 7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-3a1 1 0 1 1 0-2h2V8h-2a1 1 0 0 1-1-1Z" />
    </BaseIcon>
  );
};
