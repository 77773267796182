/* eslint-disable max-len */
import { FC } from "react";

import { useTheme } from "styled-components";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ErrorIconRound: FC<IconProps> = ({ customFill, ...props }) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();
  return (
    <BaseIcon customFill={customFill} viewBox="0 0 20 20" {...props}>
      <circle
        cx="10"
        cy="10"
        fill={customFill ? colors.borderStrong : undefined}
        r="7.5"
      />
      <path
        d="M10.0001 17C6.13407 17 3.00006 13.866 3.00006 10C3.00006 6.13401 6.13407 3 10.0001 3C13.8661 3 17.0001 6.13401 17.0001 10C16.9958 13.8642 13.8643 16.9958 10.0001 17ZM10.0001 12.1C9.61346 12.1 9.30006 12.4134 9.30006 12.8C9.30006 13.1866 9.61346 13.5 10.0001 13.5C10.3867 13.5 10.7001 13.1866 10.7001 12.8C10.7001 12.4134 10.3867 12.1 10.0001 12.1ZM10.0001 6.5C9.61346 6.5 9.30006 6.8134 9.30006 7.2V10C9.30006 10.3866 9.61346 10.7 10.0001 10.7C10.3867 10.7 10.7001 10.3866 10.7001 10V7.2C10.7001 6.8134 10.3867 6.5 10.0001 6.5Z"
        fill="#FD5254"
      />
    </BaseIcon>
  );
};
