import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const UnionIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M11.54 24.819A8.963 8.963 0 0 0 16 26c1.622 0 3.145-.43 4.46-1.181A4.5 4.5 0 1 0 25 17.256V17a8.997 8.997 0 0 0-4.51-7.801 4.5 4.5 0 0 0-8.98 0A8.997 8.997 0 0 0 7 17v.256A4.502 4.502 0 0 0 8.5 26a4.484 4.484 0 0 0 3.04-1.181ZM18.9 9.5a2.9 2.9 0 1 1-5.8 0 2.9 2.9 0 0 1 5.8 0Zm1.21 1.833A6.99 6.99 0 0 1 23 17v.027a4.5 4.5 0 0 0-3.682 6.138A6.965 6.965 0 0 1 16 24a6.965 6.965 0 0 1-3.318-.835A4.5 4.5 0 0 0 9 17.027V17a6.99 6.99 0 0 1 2.89-5.667 4.501 4.501 0 0 0 8.22 0ZM23.5 24.2a2.7 2.7 0 1 1 0-5.4 2.7 2.7 0 0 1 0 5.4Zm-12.3-2.7a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z" />
    </BaseIcon>
  );
};
