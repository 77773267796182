import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const StopAltIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M24 8v16H8V8h16ZM8 6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H8Z" />
    </BaseIcon>
  );
};
