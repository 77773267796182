/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HelpIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M16 26c-5.523 0-10-4.477-10-10S10.477 6 16 6s10 4.477 10 10-4.477 10-10 10Zm0 2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z" />
    <path d="M17 22a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1-11a2 2 0 0 0-2 2 1 1 0 1 1-2 0 4 4 0 1 1 5.31 3.78.674.674 0 0 0-.273.169.177.177 0 0 0-.037.054V18.5a1 1 0 1 1-2 0V17c0-1.152.924-1.856 1.655-2.11A2.001 2.001 0 0 0 16 11Z" />
  </BaseIcon>
);
