/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeScriptingIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M19.029 4.014a1 1 0 0 1 .707 1.225l-6.04 22.54a1 1 0 1 1-1.932-.518l6.04-22.54a1 1 0 0 1 1.225-.707Zm3.731 6.313a1 1 0 0 1 1.413-.067l5.5 5a1 1 0 0 1 0 1.48l-5.5 5a1 1 0 1 1-1.346-1.48L27.513 16l-4.686-4.26a1 1 0 0 1-.067-1.413ZM7.827 10.26a1 1 0 0 1 1.346 1.48L4.487 16l4.686 4.26a1 1 0 1 1-1.346 1.48l-5.5-5a1 1 0 0 1 0-1.48l5.5-5Z" />
    </BaseIcon>
  );
};
