import { useLayoutEffect, useState } from "react";

const useFixedOffset = (offset: number, disabled: boolean = false) => {
  const [top, setTop] = useState(offset);

  useLayoutEffect(() => {
    if (disabled) {
      setTop(offset);
      return;
    }

    const handleScroll = () => {
      setTop(Math.max(0, offset - window.scrollY));
    };

    handleScroll();

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [disabled, offset]);

  return top;
};

export default useFixedOffset;
