/* eslint-disable max-len */

import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ScenariosIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="m12.707 16.707-1.414 1.414 3.536 3.536 6.363-6.364-1.414-1.414-4.95 4.95-2.12-2.122Z" />
      <path d="M7 6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H7Zm0 5h18v13H7V11Z" />
    </BaseIcon>
  );
};
