/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HandIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="m14 7 2-1.5V16h-2V7Zm3-1h2v10h-2V6Zm3 .5 2 1.038V16h-2V6.5ZM23 9l2 1.5V20h-2V9Zm-12-.5L13 7v12h-2V8.5Z" />
      <path d="M13.5 8a.5.5 0 0 1 .5.5v2.45a2.5 2.5 0 1 0-1 0V8.5a.5.5 0 0 1 .5-.5Z" />
      <path d="M16.5 6a.5.5 0 0 1 .5.5v2.45a2.5 2.5 0 1 0-1 0V6.5a.5.5 0 0 1 .5-.5Z" />
      <path d="M19.5 7a.5.5 0 0 1 .5.5v2.45a2.5 2.5 0 1 0-1 0V7.5a.5.5 0 0 1 .5-.5Z" />
      <path d="M22.5 10a.5.5 0 0 1 .5.5v2.45a2.5 2.5 0 1 0-1 0V10.5a.5.5 0 0 1 .5-.5ZM8.378 15.398a.247.247 0 0 0 .017.155c.268.537.775 1.804 1.255 3.005l.029.07c.51 1.279.99 2.473 1.216 2.925.06.121.123.259.191.41.28.613.655 1.44 1.387 2.234C13.333 25.131 14.698 26 17 26c2.248 0 3.671-.987 4.584-2.22.943-1.274 1.346-2.831 1.419-3.851a1 1 0 0 1 1.994.142c-.093 1.313-.59 3.257-1.806 4.899-1.246 1.684-3.24 3.03-6.19 3.03-2.899 0-4.785-1.131-5.999-2.447-.981-1.065-1.52-2.27-1.78-2.851a7.4 7.4 0 0 0-.116-.255c-.27-.537-.776-1.804-1.256-3.005l-.028-.07c-.512-1.279-.99-2.473-1.216-2.925-.443-.885-.26-2.08.61-2.725.977-.725 2.258-.424 3.409.497.58.464 1.189 1.23 1.619 1.883.22.334.419.678.556.982.067.15.134.321.174.497.026.112.11.487-.08.866a1 1 0 0 1-1.893-.485 5.443 5.443 0 0 0-.427-.76c-.382-.58-.856-1.147-1.199-1.421-.412-.33-.693-.43-.84-.453a.27.27 0 0 0-.126 0c-.003.002-.02.021-.031.07Zm.025-.067.006-.003a.021.021 0 0 1-.006.003Z" />
    </BaseIcon>
  );
};
