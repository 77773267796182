import { LOGGER_TYPE } from 'api/types';
import { logToDataDog } from 'lib/ddlogHelper';
import { TABLE_STORAGE_KEY } from './table.constants';
import { MappedTableState } from './tableSlice';

export const saveTableState = (state: MappedTableState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(TABLE_STORAGE_KEY, serializedState);
  } catch (error) {
    // Ignore, but log to DD
    logToDataDog(
      LOGGER_TYPE.REDUX_STORAGE,
      'error',
      'Could not store table state in local storage',
      { error },
    );
  }
};
