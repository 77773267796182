import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CertificateIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M6 22V8h20v8h2V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12v-2H6Z" />
    <path d="M8 11a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H9Zm12.713 1.138a.5.5 0 0 0 .271-.197l1.603-2.34a.5.5 0 0 1 .825 0l1.604 2.34a.5.5 0 0 0 .27.197l2.72.801a.5.5 0 0 1 .255.785l-1.729 2.247a.5.5 0 0 0-.103.319l.078 2.835a.5.5 0 0 1-.668.485l-2.671-.95a.5.5 0 0 0-.335 0l-2.672.95a.5.5 0 0 1-.668-.485l.078-2.835a.5.5 0 0 0-.103-.319l-1.73-2.247a.5.5 0 0 1 .255-.785l2.72-.801Zm1.921.933a2.5 2.5 0 0 1-1.355.985l-.62.183.394.513a2.5 2.5 0 0 1 .517 1.593l-.017.647.61-.217a2.5 2.5 0 0 1 1.675 0l.61.217-.019-.647a2.5 2.5 0 0 1 .518-1.593l.395-.513-.62-.183a2.5 2.5 0 0 1-1.356-.985L24 19.538l-.366.533Z" />
  </BaseIcon>
);
