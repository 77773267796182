import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CatalogIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M15 10C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12H21C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10H15Z" />
    <path d="M15 20C14.4477 20 14 20.4477 14 21C14 21.5523 14.4477 22 15 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H15Z" />
    <path d="M15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17H21C21.5523 17 22 16.5523 22 16C22 15.4477 21.5523 15 21 15H15Z" />
    <path
      clipRule="evenodd"
      d="M7 12V15H6C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H7V20H6C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22H7V25C7 26.1046 7.89543 27 9 27H25C26.1046 27 27 26.1046 27 25V7C27 5.89543 26.1046 5 25 5H9C7.89543 5 7 5.89543 7 7V10H6C5.44772 10 5 10.4477 5 11C5 11.5523 5.44772 12 6 12H7ZM25 7H9V10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H9V15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H9V20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H9V25H25V7Z"
      fillRule="evenodd"
    />
  </BaseIcon>
);
