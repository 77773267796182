import { useMemo } from 'react';

import { ProjectLevelRole } from 'constants/Role.constants';
import { useQuery } from '@tanstack/react-query';

import useMatchPath from '@polyai/common/hooks/useMatchPath';

import api from 'api';
import { PROJECT_RESOURCE } from './constants';

export type EnabledFeatureResponse = {
  features: PROJECT_RESOURCE[];
  restricted_features: PROJECT_RESOURCE[];
  role: ProjectLevelRole;
};

const ACCOUNT_ROUTE = '/:accountId';
const ACCOUNT_PROJECT_ROUTE = '/:accountId/:projectId';

export const useProjectResources = () => {
  const matchPath = useMatchPath();

  const { accountId, projectId } = useMemo(() => {
    let match = matchPath(ACCOUNT_PROJECT_ROUTE, false);

    if (!match) {
      match = matchPath(ACCOUNT_ROUTE, false);
    }

    return {
      accountId: match?.params?.accountId,
      projectId: match?.params?.projectId,
    };
  }, [matchPath]);

  const queryKey = [accountId, projectId, 'enabledFeature'];

  const { data, isLoading, isError } = useQuery<EnabledFeatureResponse>(
    queryKey,
    () => api.getEnabledFeatures(accountId!, projectId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    },
  );

  return useMemo(
    () => ({
      hasFetchedProjectFeatures: !isLoading && !!data,
      features: data?.features ?? [],
      restrictedFeatures: data?.restricted_features ?? [],
      isFetchingProjectFeatures: isLoading && !!accountId,
      didProjectFeaturesError: isError && !!accountId,
    }),
    [data, isLoading, isError, accountId],
  );
};
