import { User } from '@auth0/auth0-react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface AuthState {
  authLoading: boolean;
  isAuthenticated: boolean;
  isAuthorised: boolean;
  user?: User;
  showUnauthorised: boolean;
  showUserQuestionnaire: boolean;
  login: Function;
  logout: Function;
  getAuthToken: Function;
}

const initialState: AuthState = {
  authLoading: false,
  isAuthenticated: false,
  isAuthorised: false,
  showUnauthorised: false,
  showUserQuestionnaire: false,
  login: () => {},
  logout: () => {},
  getAuthToken: () => {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading: (state, authLoading: PayloadAction<boolean>) => {
      state.authLoading = authLoading.payload;
    },
    setIsAuthenticated: (state, isAuthenticated: PayloadAction<boolean>) => {
      state.isAuthenticated = isAuthenticated.payload;
    },
    setIsAuthorised: (state, isAuthorised: PayloadAction<boolean>) => {
      state.isAuthorised = isAuthorised.payload;
    },
    setUser: (state, user: PayloadAction<User>) => {
      state.user = user.payload;
    },
    setLogin: (state, login: PayloadAction<Function>) => {
      state.login = login.payload;
    },
    setLogout: (state, logout: PayloadAction<Function>) => {
      state.logout = logout.payload;
    },
    setGetAuthToken: (state, getAuthToken: PayloadAction<Function>) => {
      state.getAuthToken = getAuthToken.payload;
    },
    setShowUnauthorised: (state, showUnauthorised: PayloadAction<boolean>) => {
      state.showUnauthorised = showUnauthorised.payload;
    },
    setShowUserQuestionnaire: (
      state,
      { payload: showUserQuestionnaire }: PayloadAction<boolean>,
    ) => {
      state.showUserQuestionnaire = showUserQuestionnaire;
    },
  },
});

export const {
  setAuthLoading,
  setGetAuthToken,
  setLogin,
  setUser,
  setLogout,
  setIsAuthorised,
  setIsAuthenticated,
  setShowUnauthorised,
  setShowUserQuestionnaire,
} = authSlice.actions;

export default authSlice.reducer;
