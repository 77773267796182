/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const RemoveIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M12 15a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z" />
    <path d="M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12Zm-2 0c0-5.523-4.477-10-10-10S6 10.477 6 16s4.477 10 10 10 10-4.477 10-10Z" />
  </BaseIcon>
);
