/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const BrainIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M14.4051 4C11.971 4 9.61388 4.85339 7.74393 6.41169C5.37163 8.3886 4 11.3171 4 14.4051V15.5C4 19.366 7.13401 22.5 11 22.5C11.5523 22.5 12 22.0523 12 21.5C12 20.9477 11.5523 20.5 11 20.5C9.12197 20.5 7.48578 19.4646 6.63112 17.9335C7.98493 17.6436 9 16.4404 9 15V14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14L7 15C7 15.544 6.56555 15.9866 6.02466 15.9997C6.00835 15.8353 6 15.6686 6 15.5V14.4051C6 12.4123 6.70719 10.5016 7.96877 8.99952C7.97914 8.99984 7.98955 9 8 9H8.5C9.05228 9 9.5 9.44772 9.5 10V10.5C9.5 11.0523 9.94772 11.5 10.5 11.5C11.0523 11.5 11.5 11.0523 11.5 10.5V10C11.5 8.83606 10.8372 7.82694 9.86835 7.32953C11.2161 6.46535 12.7896 6 14.4051 6H17V9C17 9.55229 16.5523 10 16 10H15C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12H16C17.6569 12 19 10.6569 19 9V6H19.4444C22.6261 6 25.3159 8.10597 26.1959 11H24.5C23.9477 11 23.5 10.5523 23.5 10V9.5C23.5 8.94772 23.0523 8.5 22.5 8.5C21.9477 8.5 21.5 8.94772 21.5 9.5V10C21.5 11.6569 22.8431 13 24.5 13H26.4998L26.5 13.0556C26.5 16.0624 24.0624 18.5 21.0556 18.5H20.112C20.0609 18.5 20.0099 18.5014 19.9592 18.5041C19.7229 17.0832 18.4879 16 17 16H16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18H17C17.5523 18 18 18.4477 18 19V19.4378C17.8439 19.6104 17.7077 19.8037 17.5959 20.0149L13.6162 27.5321C13.3578 28.0202 13.544 28.6254 14.0321 28.8838C14.5202 29.1422 15.1254 28.956 15.3838 28.4679L19.3635 20.9507C19.5103 20.6734 19.7983 20.5 20.112 20.5H21.0556C25.167 20.5 28.5 17.167 28.5 13.0556C28.5 8.05431 24.4457 4 19.4444 4H14.4051Z" />
    </BaseIcon>
  );
};
