import { doDelete, doGet, doPatch, doPut } from 'api/request';
import { LOGGER_TYPE, ResponseWithVersion } from 'api/types';
import { store } from 'reduxStore';
import { NluDatasetJsonType, NluDatasetResponseType } from './types';

export const getNluDataset = () => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<NluDatasetResponseType>(
    `${accountId}/${projectId}/dataset`,
    LOGGER_TYPE.NLU,
  );
};

export const getNluDatasetCSV = ({ intents }: { intents?: string[] }) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  const filterIntents = !!intents && intents.join('|');

  return doGet<Blob>(
    `${accountId}/${projectId}/dataset/csv`,
    LOGGER_TYPE.NLU,
    {
      filter_intents: filterIntents,
    },
    '',
    null,
    true,
  );
};

export const patchNluDataset = (dataset: NluDatasetJsonType) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPatch<NluDatasetResponseType>(
    `${accountId}/${projectId}/dataset`,
    LOGGER_TYPE.NLU,
    {
      dataset,
      version: gitVersion,
    },
  );
};

export const putNluDataset = (dataset: NluDatasetJsonType) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPut<NluDatasetResponseType>(
    `${accountId}/${projectId}/dataset`,
    LOGGER_TYPE.NLU,
    {
      dataset,
      version: gitVersion,
    },
  );
};

export const patchIntentFromDataset = ({
  oldIntent,
  newIntent,
  description,
}: {
  oldIntent: string;
  newIntent?: string;
  description?: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doPatch<ResponseWithVersion>(
    `${accountId}/${projectId}/intents`,
    LOGGER_TYPE.NLU,
    {
      intent_name: oldIntent,
      new_intent_name: newIntent,
      description,
      version: gitVersion,
    },
  );
};

export const deleteIntentFromDataset = ({ intents }: { intents: string[] }) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  return doDelete<ResponseWithVersion>(
    `${accountId}/${projectId}/intents`,
    LOGGER_TYPE.NLU,
    {
      intents,
      version: gitVersion,
    },
  );
};

// Append csv data to the current dataset
export const patchIntentCSV = ({
  file,
  dryRun,
}: {
  file: File;
  dryRun?: boolean;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;
  const params = dryRun ? `?dry_run=true` : ``;

  const bodyFormData = new FormData();
  bodyFormData.append('version', gitVersion as string);
  const blob = new Blob([file], { type: 'text/csv' });
  bodyFormData.append('csv', blob);

  return doPatch<NluDatasetResponseType>(
    `${accountId}/${projectId}/dataset/csv${params}`,
    LOGGER_TYPE.NLU,
    bodyFormData,
    {
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
    },
    true,
  );
};

// overwrite current dataset with csv data
export const putIntentCSV = ({
  file,
  dryRun,
}: {
  file: File;
  dryRun?: boolean;
}) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  const gitVersion = store.getState().version.currentPolicyVersion;
  const params = dryRun ? `?dry_run=true` : ``;

  const bodyFormData = new FormData();
  bodyFormData.append('version', gitVersion as string);
  const blob = new Blob([file], { type: 'text/csv' });
  bodyFormData.append('csv', blob);

  return doPut<NluDatasetResponseType>(
    `${accountId}/${projectId}/dataset/csv${params}`,
    LOGGER_TYPE.NLU,
    bodyFormData,
    '',
    {
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
    },
    true,
  );
};
