/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeFolderIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M14.793 5.793 17 8h8a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7.5a2 2 0 0 1 2-2h7.086a1 1 0 0 1 .707.293ZM7 10v14h18V10H7Z" />
    </BaseIcon>
  );
};
