const debounce = <F extends (...args: any) => void>(fn: F, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<F>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), ms);
  };
};

export default debounce;
