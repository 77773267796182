import React from 'react';

import { ComponentWithChildren } from '@polyai/common/types/helpers';

import { LockProvider } from './LockContext';
import { PreviousLocationProvider } from './PreviousLocationContext';

const MasterProvider: ComponentWithChildren = ({ children }) => {
  return (
    <LockProvider>
      <PreviousLocationProvider>{children}</PreviousLocationProvider>
    </LockProvider>
  );
};

export default MasterProvider;
