import { RefObject, useEffect } from "react";

type ButtonType =
  | string
  | {
      key: string;
      shiftKey: boolean;
    };

const getFocusableElements = (contentRef: RefObject<HTMLElement>) => [
  ...((contentRef?.current?.querySelectorAll(
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"], iframe'
  ) ?? []) as HTMLElement[]),
];

const matchButton = (previousButtons: ButtonType[], event: KeyboardEvent) => {
  return previousButtons.some((button) =>
    typeof button === "string"
      ? button === event.key
      : button.key === event.key && button.shiftKey === event.shiftKey
  );
};

/**
 * @param {RefObject<HTMLElement>} contentRef
 * @param {ButtonType[]} nextButtons
 * @param {ButtonType[]} previousButtons
 * @param {boolean} active
 *
 * This hook will add event listeners to the specific keyboard events given
 * in the "nextButtons" and "previousButtons" props. If a next button is pressed
 * then it will move the focus on to the next focusable element within the
 * given container, if it's a previous button then it will move backwards.
 *
 * This also adds focus looping to the container, so that once you reach the end
 * it loops to the beginning.
 *
 * Tihs can be used to allow arrow navigation to work the same as tab navigation.
 */
const useButtonNavigation = (
  contentRef: RefObject<HTMLElement>,
  nextButtons: ButtonType[],
  previousButtons: ButtonType[],
  active = true
) => {
  useEffect(() => {
    if (!active) return;

    const listener = (event: KeyboardEvent) => {
      if (
        document.activeElement &&
        document.activeElement !== document.body &&
        !contentRef.current?.contains(document.activeElement)
      ) {
        return;
      }

      const isNext = matchButton(nextButtons, event);
      const isPrevious = matchButton(previousButtons, event);

      if (isNext || isPrevious) {
        event.preventDefault();

        const focusableEls = getFocusableElements(contentRef);

        if (focusableEls.length === 1) {
          event.preventDefault();
          return;
        }

        const currentIndex =
          document.activeElement &&
          focusableEls.indexOf(document.activeElement as HTMLElement);
        const lastIndex = focusableEls.length - 1;

        if (currentIndex === null) {
          focusableEls[isNext ? 0 : lastIndex]?.focus();
        } else {
          const nextIndex = currentIndex! + (isNext ? 1 : -1);
          focusableEls.at(nextIndex % focusableEls.length)?.focus();
        }
      }
    };

    const content = contentRef.current;
    content?.addEventListener("keydown", listener);

    return () => {
      if (!active) return;
      content?.removeEventListener("keydown", listener);
    };
  }, [contentRef, active, nextButtons, previousButtons]);
};

export default useButtonNavigation;
