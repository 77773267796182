// @ts-nocheck
import color, { hue } from "./color.js";
import { default as colorHsluv } from "./hsluv.js";

function hsluv(hue) {
  return function (start, end) {
    const l = hue((start = colorHsluv(start)).l, (end = colorHsluv(end)).l);
    const u = color(start.u, end.u);
    const v = color(start.v, end.v);
    const opacity = color(start.opacity, end.opacity);
    return function (t) {
      start.l = l(t);
      start.u = u(t);
      start.v = v(t);
      start.opacity = opacity(t);
      return start + "";
    };
  };
}

export default hsluv(hue);
export const hsluvLong = hsluv(color);
