import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LockState {
  isDraft: boolean;
}

const initialState: LockState = {
  isDraft: false,
};

const lockSlice = createSlice({
  name: 'lock',
  initialState,
  reducers: {
    setIsDraft: (state, { payload: isDraft }: PayloadAction<boolean>) => {
      state.isDraft = isDraft;
    },
  },
});

export const { setIsDraft } = lockSlice.actions;

export default lockSlice.reducer;
