import dayjs from 'dayjs';

import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from '../../reduxStore';

export type NotificationEntity =
  | 'FLOW'
  | 'NODES'
  | 'INTENTS'
  | 'ENTITIES'
  | 'AUDIO'
  | 'SCENARIOS'
  | 'UTILITIES'
  | 'DEPLOYMENTS'
  | 'DATASTORE'
  | 'API_INTEGRATIONS'
  | 'SETTINGS';

export type NotificationStatus = 'SUCCESS' | 'INFO' | 'ERROR';

export type NotificationState = 'SENT' | 'UNREAD' | 'READ' | 'ARCHIVED';

export type NotificationType = 'USER' | 'SYSTEM';

export interface NotificationMessage {
  title?: string;
  body?: string;
  action?: string;
  sub_entity?: string;
}

export interface NotificationMetadata {
  callToAction?: {
    label: string;
    action: () => void;
  };
  link?: {
    label: string;
    href: string;
  };
  errorCode?: string;
}

export interface NotificationActivity {
  notification_id: string;
  state: NotificationState;
}

export interface NotificationPayload {
  user_id?: string;
  type: NotificationType;
  status: NotificationStatus;
  entity: NotificationEntity;
  entity_ids?: string[];
  message: NotificationMessage;
  metadata?: NotificationMetadata;
}

export interface NotificationDescriptor extends NotificationPayload {
  id: string;
  created_at: number;
  state: NotificationState;
}

export interface NotificationResponse
  extends Omit<NotificationDescriptor, 'created_at'> {
  created_at: string;
}

export const responseToDescriptor = (
  response: NotificationResponse,
): NotificationDescriptor => {
  return {
    ...response,
    created_at: dayjs(response.created_at).unix(),
  };
};

export const getNotifications = (start: string, polling: boolean) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<NotificationResponse[]>(
    `${accountId}/${projectId}/notifications`,
    LOGGER_TYPE.NOTIFICATIONS,
    null,
    `start=${start}&polling=${polling}`,
  );
};

export const addNotificationActivities = (
  activities: NotificationActivity[],
) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPost(
    `${accountId}/${projectId}/notifications/activity`,
    LOGGER_TYPE.NOTIFICATIONS,
    activities,
  );
};

export const createNotification = (notification: NotificationPayload) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPost<{ notification_id: string }>(
    `${accountId}/${projectId}/notifications`,
    LOGGER_TYPE.NOTIFICATIONS,
    notification,
  );
};
