import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { closeSidePanel, projectInit } from 'actions';
import { NodeError } from '../../api/types';

export interface PolicyState {
  activeNodePath: string | null;
  nodeError: NodeError | null;
  invalidNodes: string[];
}

const initialState: PolicyState = {
  activeNodePath: null,
  nodeError: null,
  invalidNodes: [],
};

const policySlice = createSlice({
  name: 'invalidNodes',
  initialState,
  reducers: {
    setActiveNodePath: (
      state,
      activeNodePath: PayloadAction<string | null | undefined>,
    ) => {
      state.activeNodePath = activeNodePath.payload || null;
    },
    setNodeError: (state, nodeError: PayloadAction<NodeError>) => {
      state.nodeError = nodeError.payload;
    },
    setInvalidNodes: (state, invalidNodes: PayloadAction<string[]>) => {
      state.invalidNodes = invalidNodes.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(closeSidePanel, (state) => {
        state.activeNodePath = null;
      });
  },
});

export const { setActiveNodePath, setNodeError, setInvalidNodes } =
  policySlice.actions;

export default policySlice.reducer;
