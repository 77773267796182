import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createNewChat,
  createNewDeployment,
  createNewDraftChat,
  projectInit,
} from 'actions';
import { TurnMetadata } from 'api/resources/conversation/types';
import { ChatMessage } from 'api/types';

export enum TestAgentStages {
  DEPLOYING_TEST_AGENT = 'DEPLOYING_TEST_AGENT',
  VALIDATING_FIELDS = 'VALIDATING_FIELDS',
  POLLING_FOR_STATUS = 'POLLING_FOR_STATUS',
  CREATING_CONVERSATION_OLD_AGENT = 'CREATING_CONVERSATION_OLD_AGENT',
  CREATING_CONVERSATION_NEW_AGENT = 'CREATING_CONVERSATION_NEW_AGENT',
  TRIGGERING_DEPLOYMENT = 'TRIGGERING_DEPLOYMENT',
  SENDING_MESSAGE = 'SENDING_MESSAGE',
  CREATING_BRANCH = 'CREATING_BRANCH',
}

enum TestAgentErrorCodes {
  AGENT_NOT_FOUND = 'AGENT_NOT_FOUND',
  AGENT_NOT_READY = 'AGENT_NOT_READY',
  CONVERSATION_HASH_MISMATCH = 'CONVERSATION_HASH_MISMATCH',
  CONVERSATION_UNKNOWN_EXCEPTION = 'CONVERSATION_UNKNOWN_EXCEPTION',
}

export type ChatError = {
  message: string;
  title?: string;
  type?: TestAgentStages | TestAgentErrorCodes;
  data?: {
    node: string;
    line: number;
    method: string;
    file: string;
  };
} | null;

export interface TestAgentState {
  conversationId: string | null;
  isChatLoading: boolean;
  chatMessages: ChatMessage[];
  chatError: ChatError;
  chatMessageDraft: string;
  chatEnded: boolean;
  chatInfo: string | null;
  chatId: string | null;
  isSendingApiRequest: boolean;
  isChattingWithDraft: boolean;
}

const initialState: TestAgentState = {
  conversationId: null,
  isChatLoading: false,
  chatMessages: [],
  chatError: null,
  chatMessageDraft: '',
  chatEnded: false,
  chatInfo: null,
  chatId: null,
  isSendingApiRequest: false,
  isChattingWithDraft: false,
};

const testAgentSlice = createSlice({
  name: 'testAgent',
  initialState,
  reducers: {
    setConversationId: (
      state,
      { payload: conversationId }: PayloadAction<string | null>,
    ) => {
      state.conversationId = conversationId;
    },
    setIsChatLoading: (
      state,
      { payload: isLoading }: PayloadAction<boolean>,
    ) => {
      state.isChatLoading = isLoading;
    },
    addUserMessage: (
      state,
      { payload: message }: PayloadAction<ChatMessage>,
    ) => {
      state.chatMessages.push(message);
    },
    addAgentResponse: (
      state,
      {
        payload: { agentMessage, previousMessageMetadata },
      }: PayloadAction<{
        agentMessage: ChatMessage;
        previousMessageMetadata: TurnMetadata;
      }>,
    ) => {
      const previousMessage = state.chatMessages.at(-1);
      if (previousMessage) {
        previousMessage.metadata = previousMessageMetadata;
      }
      state.chatMessages.push(agentMessage);
    },
    setChatError: (state, { payload: error }: PayloadAction<ChatError>) => {
      state.chatError = error;
    },
    setChatMessageDraft: (state, { payload: draft }: PayloadAction<string>) => {
      state.chatMessageDraft = draft;
    },
    setChatEnded: (state, { payload: ended }: PayloadAction<boolean>) => {
      state.chatEnded = ended;
    },
    setChatInfo: (state, { payload: info }: PayloadAction<string | null>) => {
      state.chatInfo = info;
    },
    setChatId: (state, { payload: id }: PayloadAction<string>) => {
      state.chatId = id;
    },
    setIsSendingApiRequest: (
      state,
      { payload: isSendingApiRequest }: PayloadAction<boolean>,
    ) => {
      state.isSendingApiRequest = isSendingApiRequest;
    },
    setIsChattingWithDraft: (
      state,
      { payload: isChattingWithDraft }: PayloadAction<boolean>,
    ) => {
      state.isChattingWithDraft = isChattingWithDraft;
    },
    clearChatMessages: (state) => {
      state.chatMessages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewChat, (state) => {
        state.isChatLoading = true;
        state.chatMessages = [];
        state.chatError = null;
        state.chatEnded = false;
        state.chatId = null;
        state.conversationId = null;
        state.isSendingApiRequest = false;
        state.isChattingWithDraft = false;
      })
      .addCase(createNewDeployment, (state) => {
        state.isChatLoading = false;
        state.chatMessages = [];
        state.chatError = null;
        state.chatEnded = false;
        state.chatId = null;
        state.conversationId = null;
        state.isSendingApiRequest = false;
        state.isChattingWithDraft = false;
      })
      .addCase(createNewDraftChat, (state) => {
        state.isChatLoading = true;
        state.chatMessages = [];
        state.chatError = null;
        state.chatEnded = false;
        state.chatId = null;
        state.conversationId = null;
        state.isSendingApiRequest = false;
        state.isChattingWithDraft = true;
      })
      .addCase(projectInit, () => {
        return { ...initialState };
      });
  },
});

export const {
  addAgentResponse,
  addUserMessage,
  setChatEnded,
  setChatError,
  setChatId,
  setChatInfo,
  setChatMessageDraft,
  setConversationId,
  setIsChatLoading,
  setIsSendingApiRequest,
  setIsChattingWithDraft,
  clearChatMessages,
} = testAgentSlice.actions;

export default testAgentSlice.reducer;
