import React, { forwardRef } from "react";

import { ColorName } from "styles/config/colors";
import { IconSize } from "../Icons/BaseIcon";
import LoadingIcon from "../LoadingIcon";

import * as Styled from "./IconButton.styled";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  circle?: boolean;
  hoverTarget?: boolean;
  iconSize?: keyof typeof IconSize;
  hoverSize?: keyof typeof IconSize;
  hoverTargetColor?: ColorName;
  hoverIconColor?: ColorName;
  loading?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      loading,
      disabled,
      circle,
      hoverIconColor,
      hoverTargetColor,
      hoverTarget = false,
      iconSize = "md",
      hoverSize = "xxl",
      className,
      type = "button",
      ...rest
    },
    ref
  ) => {
    return (
      <Styled.Button
        ref={ref}
        $circle={circle}
        $disabled={disabled}
        $hoverIconColor={hoverIconColor}
        $hoverSize={hoverSize}
        $hoverTarget={hoverTarget}
        $hoverTargetColor={hoverTargetColor}
        $iconSize={iconSize}
        className={className}
        disabled={loading || disabled}
        type={type}
        {...rest}
      >
        {loading ? (
          <Styled.LoadingWrapper $size={iconSize}>
            <LoadingIcon />
          </Styled.LoadingWrapper>
        ) : (
          children
        )}
      </Styled.Button>
    );
  }
);

export default IconButton;
