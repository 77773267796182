import { Link, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";

interface AccountLinkProps extends LinkProps {
  $isActive: boolean;
}

export const AccountLink = styled(Link)<AccountLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  font-size: ${text.size.body.body3};
  font-weight: ${text.weight.bold};
  color: ${themeColors.textPrimaryInverse};

  background: ${(p) =>
    p.$isActive ? themeColors.backgroundPrimary : themeColors.backgroundPage};
  border-radius: 6px;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      color: ${themeColors.textPrimary};
      border: 1px solid ${themeColors.borderStrong};
    `}
`;

export const AccountsWrapper = styled.div`
  display: grid;
  gap: ${themeSpacing.sm1};
  justify-items: center;
  padding: ${themeSpacing.sm1};
`;

export const AccountToolTip = styled.span`
  font-size: ${text.size.body.body4};
`;
