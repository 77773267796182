import ReactPopup from "reactjs-popup";
import styled, { css } from "styled-components";

import Input from "components/atoms/Input";
import { MenuLabel } from "components/atoms/Text";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";
import rem from "styles/functions/rem";

export const Popup = styled(ReactPopup)`
  &-content {
    overflow: hidden;

    padding: 0;

    color: ${themeColors.textPrimaryInverse};

    background: ${themeColors.backgroundInverse};
    border: 1px solid ${themeColors.textSecondary};
    border-radius: ${themeSpacing.radiusMedium} !important;
  }
`;
// FIXME: check strange disable logic

export const PopupMenuLabel = styled(MenuLabel)<{
  $create?: boolean;
  $delete?: boolean;
  $clickable?: boolean;
}>`
  display: flex;
  gap: ${themeSpacing.xs3};
  align-items: center;

  width: 100%;
  padding: ${themeSpacing.xs2} ${themeSpacing.sm1};

  color: ${({ $create, $delete }) =>
    $create
      ? themeColors.textBrand
      : $delete
      ? themeColors.errorText01
      : themeColors.textSecondaryInverse};
  text-align: left;

  ${({ $create, $delete }) =>
    ($delete || $create) &&
    css`
      border-top: 1px solid ${themeColors.textSecondary};
    `}
  &:disabled {
    opacity: 0.5;
  }
  ${({ $clickable, $create, $delete }) =>
    $clickable
      ? css`
          cursor: pointer;

          &:disabled:active {
            color: ${$create
              ? themeColors.textBrand
              : $delete
              ? themeColors.errorText01
              : themeColors.textPlaceholder};
          }

          &:hover:not([disabled]),
          &:active:not([disabled]),
          &:focus:not([disabled]) {
            background-color: ${themeColors.backgroundAccentInverse};
          }
        `
      : css`
          cursor: default;

          &:active {
            color: ${themeColors.textSecondaryInverse};
            opacity: 0.5;
          }
        `}

  :first-child {
    border-top: none;
  }
`;

export const MenuItemIcon = styled.div`
  display: flex;
  width: ${rem(20)};
  height: 100%;
  margin-left: 0;
`;

export const PopupMenuSearchWrapper = styled.div<{ $focused?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: stretch;
  min-height: ${rem(40)};

  ${({ $focused }) =>
    $focused &&
    css`
      border: none;
    `}
`;

export const PopupMenuIcon = styled.div`
  display: flex;
  margin: 0 ${themeSpacing.xs3};
`;

export const PopupMenuSearch = styled(Input)`
  display: flex;

  padding: 0 !important;

  font-size: ${text.size.body.body3};
  color: ${themeColors.textPlaceholder};

  border: none !important;
  outline: none;

  :disabled {
    background-color: transparent;
  }
`;
