import { doDelete, doGet, doPost, doPut } from 'api/request';
import { ProjectLanguagesResponseType } from 'api/resources/project/types';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { SupportedLanguageResponseType } from './types';

export const getLanguage = () => {
  const projectId = store.getState().projectInfo.id;

  return doGet(`projects/${projectId}/languages`, LOGGER_TYPE.PROJECT_SETTINGS);
};

export const activateLanguage = (data: {
  language: string;
  voiceId: string;
  voiceName: string;
}) => {
  const projectId = store.getState().projectInfo.id;
  return doPost<ProjectLanguagesResponseType>(
    `projects/${projectId}/languages/${data.language}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      voice_id: data.voiceId,
      voice_name: data.voiceName,
    },
  );
};

export const deactivateLanguage = (language: string) => {
  const projectId = store.getState().projectInfo.id;

  return doDelete<ProjectLanguagesResponseType>(
    `projects/${projectId}/languages/${language}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const getAllLanguages = () => {
  return doGet<SupportedLanguageResponseType>(
    `languages`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const editLanguageVoiceId = (
  language: string,
  voiceId: string,
  voiceName: string,
) => {
  const projectId = store.getState().projectInfo.id;
  return doPut(
    `projects/${projectId}/languages/${language}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      voice_id: voiceId,
      voice_name: voiceName,
    },
  );
};
