import theme, { ThemeSet } from "styled-theming";

import { THEME_NAMES } from "../theme.constants";
import barbieTheme from "./barbieTheme";
import defaultTheme, {
  ColorMapping,
  ColorScheme,
  defaultColors,
} from "./defaultTheme";
import newBrandTheme from "./newBrandTheme";

export { default as baseColors } from "./baseColors";
export { type ColorMapping, type ColorScheme } from "./defaultTheme";

export type ColorName = keyof ColorMapping;

export type ColorSchemesObject = {
  [key in THEME_NAMES]: ColorScheme;
};

export type StyledColorScheme = {
  [key in ColorName]: ThemeSet;
};

type ColorValueObject = {
  [key in THEME_NAMES]: string;
};

export const COLOR_SCHEMES: ColorSchemesObject = {
  [THEME_NAMES.DEFAULT]: defaultTheme,
  [THEME_NAMES.NEW]: newBrandTheme,
  [THEME_NAMES.BARBIE]: barbieTheme,
};

export const DEFAULT_COLOR_SCHEME = THEME_NAMES.DEFAULT;

/**
 * Get the hex value of a given color and assign it to theme
 * @return Returns an object of theme-color pairings
 * e.g {
 *   polyAI: '#716bff',
 *   anotherBrand: '#F57921'
 * }
 */

const getColorValues = (color: ColorName): ColorValueObject => {
  return (Object.keys(COLOR_SCHEMES) as THEME_NAMES[]).reduce(
    (themeColors, schemeName) => {
      return {
        ...themeColors,
        [schemeName]: COLOR_SCHEMES[schemeName].colors[color],
      };
    },
    {} as ColorValueObject
  );
};

/**
 * Get all color values for a given theme
 * @return Returns an object of colors and their hex values
 * e.g {
 *   primary: '#716bff',
 *   secondary: '#222233',
 *   tertiary: '#363644'
 * }
 */

const colorNames = Object.keys(defaultColors) as ColorName[];

const getColorScheme = (): StyledColorScheme => {
  return colorNames.reduce((colorObject, color) => {
    return Object.assign(colorObject, {
      [color]: theme("themeColors", getColorValues(color)),
    });
  }, {} as StyledColorScheme);
};

const themeColors: StyledColorScheme = getColorScheme();

export default themeColors;
