/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ProjectSettingsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M8 5a1 1 0 0 1 2 0v11.1a5.002 5.002 0 0 1 0 9.8V27a1 1 0 1 1-2 0v-1.1a5.002 5.002 0 0 1 0-9.8V5Zm4 16a3 3 0 1 0-6 0 3 3 0 0 0 6 0ZM22 6.1a5.002 5.002 0 0 0 0 9.8V27a1 1 0 1 0 2 0V15.9a5.002 5.002 0 0 0 0-9.8V5a1 1 0 1 0-2 0v1.1ZM20 11a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" />
    </BaseIcon>
  );
};
