export interface Breakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

const breakpoints: Breakpoints = {
  xs: 500,
  sm: 800,
  md: 1000,
  lg: 1200,
  xl: 1400,
};

export const above = (breakpoint: number) => `(min-width: ${breakpoint}px)`;
export const under = (breakpoint: number) => `(max-width: ${breakpoint}px)`;

export default breakpoints;
