/* eslint-disable max-len */
import React, { FC } from 'react';

import { BaseIcon, IconProps } from './BaseIcon';

export const PlayChat: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M13 18.3007V11.6997C13 10.9372 13.8191 10.4552 14.4856 10.8255L20.4265 14.126C21.1123 14.507 21.1123 15.4933 20.4265 15.8743L14.4856 19.1748C13.8191 19.5451 13 19.0631 13 18.3007Z" />
      <path d="M3 7V30L9.5 24.6923H27C28.1046 24.6923 29 23.7969 29 22.6923V7C29 5.89543 28.1046 5 27 5H5C3.89543 5 3 5.89543 3 7ZM27 22.6923L8.78716 22.6923L5 25.7848V7H27V22.6923Z" />
    </BaseIcon>
  );
};
