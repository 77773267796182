import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CurrencyIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M17 11V9H15V11H13.5715C12.467 11 11.5715 11.8954 11.5715 13V15C11.5715 16.1046 12.467 17 13.5715 17H18.4287V19H12.0001V21H15V23H17V21H18.4287C19.5332 21 20.4287 20.1046 20.4287 19V17C20.4287 15.8954 19.5332 15 18.4287 15H13.5715V13H20.0001V11H17Z" />
      <path
        clipRule="evenodd"
        d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z"
        fillRule="evenodd"
      />
    </BaseIcon>
  );
};
