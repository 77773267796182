/* eslint-disable max-len */

import { BaseIcon, IconProps } from "./BaseIcon";

export const ContextMenuIcon = (props: IconProps) => {
  return (
    <BaseIcon {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm2 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
      </svg>
    </BaseIcon>
  );
};
