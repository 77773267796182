import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const TrashCanIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M12 4a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm8 9a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0V13Zm-7-1a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V13a1 1 0 0 1 1-1Z" />
    <path d="M5 9a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2h-1v17a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V10H6a1 1 0 0 1-1-1Zm4 1v17h14V10H9Z" />
  </BaseIcon>
);
