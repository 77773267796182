import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const SecondaryMenuIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M3.125 4.875C3.125 4.52982 3.40482 4.25 3.75 4.25H16.25C16.5952 4.25 16.875 4.52982 16.875 4.875C16.875 5.22018 16.5952 5.5 16.25 5.5H3.75C3.40482 5.5 3.125 5.22018 3.125 4.875Z" />
      <path d="M10 10.5C10 10.1548 10.2798 9.875 10.625 9.875H16.25C16.5952 9.875 16.875 10.1548 16.875 10.5C16.875 10.8452 16.5952 11.125 16.25 11.125H10.625C10.2798 11.125 10 10.8452 10 10.5Z" />
      <path d="M6.25 16.125C6.25 15.7798 6.52982 15.5 6.875 15.5H16.25C16.5952 15.5 16.875 15.7798 16.875 16.125C16.875 16.4702 16.5952 16.75 16.25 16.75H6.875C6.52982 16.75 6.25 16.4702 6.25 16.125Z" />
    </BaseIcon>
  );
};
