/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeTransferIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M21.414 3.828a1 1 0 0 0 0 2h3.243l-5.364 5.365a1 1 0 0 0 1.414 1.414l5.364-5.364v3.242a1 1 0 1 0 2 0V4.83a1 1 0 0 0-1-1h-5.657ZM4.217 14.5c.424 1.555 1.887 4.898 4.844 7.86l.01.01c2.963 2.963 6.312 4.428 7.868 4.853 1.09.297 2.281.152 3.294-.169 1.014-.32 1.983-.86 2.627-1.503l.039-.04c.404-.403.804-.803 1.094-1.198.318-.434.574-.95.574-1.59 0-.317-.122-.614-.199-.786a6.14 6.14 0 0 0-.36-.669c-.28-.459-.655-.983-1.055-1.483-.4-.498-.843-.995-1.268-1.39a5.586 5.586 0 0 0-.655-.532c-.205-.14-.484-.3-.803-.364-1.278-.255-2.237.429-2.818.843l-.084.06c-.688.487-.95.608-1.293.522-.588-.147-.921-.415-2.002-1.495l-.01-.01c-1.087-1.088-1.356-1.421-1.504-2.01-.086-.343.035-.606.522-1.294l.06-.084c.414-.58 1.098-1.54.843-2.818-.064-.319-.225-.598-.364-.803a5.596 5.596 0 0 0-.532-.655 13.39 13.39 0 0 0-1.39-1.268c-.5-.4-1.024-.775-1.483-1.055a6.131 6.131 0 0 0-.669-.36c-.172-.077-.469-.199-.786-.199-.64 0-1.156.256-1.59.574-.395.29-.795.69-1.199 1.094l-.039.04c-.643.643-1.183 1.612-1.503 2.625-.32 1.014-.466 2.206-.169 3.295Zm6.359 1.395c.301 1.201.993 1.901 2.021 2.93l.01.009c1.033 1.033 1.733 1.728 2.938 2.03 1.307.328 2.286-.368 2.876-.787l.06-.043c.68-.48.967-.638 1.322-.58.015.008.05.026.105.064.108.074.248.187.415.342.332.309.71.727 1.07 1.176.358.448.68.903.908 1.275a4.199 4.199 0 0 1 .258.482c-.014.069-.057.17-.178.336-.19.258-.48.552-.936 1.008-.363.363-1.026.76-1.815 1.01-.789.25-1.568.309-2.164.146-1.254-.341-4.28-1.642-6.972-4.329l-.009-.01c-2.693-2.692-3.996-5.725-4.338-6.98-.163-.596-.104-1.375.146-2.164.25-.789.647-1.452 1.01-1.815.456-.456.75-.747 1.008-.936.165-.121.267-.164.336-.178l.039.016a4.2 4.2 0 0 1 .443.242c.372.227.827.55 1.275.909.449.36.867.737 1.176 1.07.155.166.268.306.342.414.037.056.056.09.063.105.06.355-.098.643-.58 1.322l-.041.06c-.42.59-1.116 1.57-.788 2.876Z" />
    </BaseIcon>
  );
};
