/* eslint-disable max-len */
import { FC } from "react";

import { useTheme } from "styled-components";

import { ColorName } from "styles/config/colors";
import { BaseIcon, IconProps } from "./BaseIcon";

interface WarningProps extends IconProps {
  border?: boolean;
  innerColor?: ColorName;
}

export const WarningIcon: FC<WarningProps> = ({
  customFill = false,
  color = "iconWarning",
  border = true,
  innerColor = "backgroundInverse",
  ...props
}) => {
  const {
    activeColorScheme: { colors },
  } = useTheme();

  return (
    <BaseIcon color={color} customFill={customFill} {...props}>
      <path
        d="M16 26c-5.523 0-10-4.477-10-10S10.477 6 16 6s10 4.477 10 10-4.477 10-10 10Zm0 2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
        fill={customFill && border ? colors.borderStrong : undefined}
      />
      {customFill && (
        <path
          d="M26 16c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10Z"
          fill={colors[color]}
        />
      )}
      <path
        d="M16 10a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0v-6a1 1 0 0 0-1-1Zm0 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        fill={customFill ? colors[innerColor] : undefined}
      />
    </BaseIcon>
  );
};
