import { doGet, doPost } from 'api/request';
import {
  LOGGER_TYPE,
  ResembleVoicesResponse,
  SpeechToSpeechResponse,
} from 'api/types';
import { store } from 'reduxStore';

export const postAudioRecording = (voiceId: string, wavData: string[]) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPost<SpeechToSpeechResponse>(
    `${accountId}/${projectId}/speech`,
    LOGGER_TYPE.AUDIO,
    {
      voice_id: voiceId,
      wav_data: wavData,
    },
  );
};

export const getResembleVoices = () => {
  return doGet<ResembleVoicesResponse>(`resemble-voices`, LOGGER_TYPE.AUDIO);
};
