import { doDelete, doGet, doPatch, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from '../../../reduxStore';
import {
  ApiIntegrationsResponse,
  CreateApiIntegration,
  GetApiIntegrationResponse,
  PatchApiIntegration,
} from './types';

export const getApiIntegrations = () => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<ApiIntegrationsResponse>(
    `${accountId}/${projectId}/api-integrations`,
    LOGGER_TYPE.API_INTEGRATIONS,
  );
};

export const getApiIntegration = (id: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet<GetApiIntegrationResponse>(
    `${accountId}/${projectId}/api-integrations/${id}`,
    LOGGER_TYPE.API_INTEGRATIONS,
  );
};

export const postApiIntegration = (apiIntegration: CreateApiIntegration) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost<ApiIntegrationsResponse>(
    `${accountId}/${projectId}/api-integrations`,
    LOGGER_TYPE.API_INTEGRATIONS,
    {
      api_integration: apiIntegration,
      version: gitVersion,
    },
  );
};

export const patchApiIntegration = ({
  id,
  apiIntegration,
}: {
  id: string;
  apiIntegration: PatchApiIntegration;
}) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPatch(
    `${accountId}/${projectId}/api-integrations/${id}`,
    LOGGER_TYPE.API_INTEGRATIONS,
    {
      api_integration: apiIntegration,
      version: gitVersion,
    },
  );
};

export const deleteApiIntegration = (id: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doDelete(
    `${accountId}/${projectId}/api-integrations/${id}`,
    LOGGER_TYPE.API_INTEGRATIONS,
    {
      version: gitVersion,
    },
  );
};
