export const PATHS = {
  // intents
  INTENTS: '/:accountId/:projectId/intents',
  INTENT_DETAILS: '/:accountId/:projectId/intents/:intentId',
  // entities
  ENTITIES: '/:accountId/:projectId/entities',
  ENTITY_DETAILS: '/:accountId/:projectId/entities/:entityId',
  // audio
  AUDIO: '/:accountId/:projectId/audio',
  AUDIO_DETAILS: '/:accountId/:projectId/audio/:promptId',
  // data store
  DATA_STORE: '/:accountId/:projectId/data-store',
  DATA_STORE_DETAILS: '/:accountId/:projectId/file',
  FAQ_EDITOR: '/:accountId/:projectId/data-store/faq-editor',
  AGENT_CONFIG: '/:accountId/:projectId/config/:fileId',
  // conversations
  CONVERSATIONS: '/:accountId/:projectId/conversations',
  CONVERSATION_REVIEW: '/:accountId/:projectId/conversations/:conversationId',
  CONVERSATIONS_LOG: '/:accountId/:projectId/conversations-log',
  LIVE_CONVERSATIONS: '/:accountId/:projectId/live-conversations',
  LIVE_CONVERSATIONS_REVIEW:
    '/:accountId/:projectId/live-conversations/:conversationId',
  DAILY_PLAYLISTS: '/:accountId/:projectId/daily-playlists/:playlistName',
  // analytics
  ANALYTICS: '/:accountId/:projectId/dashboards/:dashboardId',
  PORTAL_ANALYTICS_DASHBOARD: '/:accountId/:projectId/analytics/:dashboardSlug',
  CALL_FLOWS_DASHBOARD: '/:accountId/:projectId/analytics/call-flows',
  CHAT_REPORTS: '/:accountId/:projectId/chat-reports',
  // flows
  FLOWS: '/:accountId/:projectId/flows',
  FLOW_DESIGN: '/:accountId/:projectId/flows/:flowId',
  // scenarios
  SCENARIOS: '/:accountId/:projectId/scenarios',
  SCENARIO_DETAILS: '/:accountId/:projectId/scenarios/:scenarioId',
  // utils
  UTILITIES: '/:accountId/:projectId/utilities',
  UTILITY_DETAILS: '/:accountId/:projectId/utilities/:utilityId',
  // project settings
  PROJECT_SETTINGS: '/:accountId/:projectId/settings/project',
  ANALYTICS_SETTINGS: '/:accountId/:projectId/settings/analytics',
  VARIANT_SETTINGS: '/:accountId/:projectId/settings/variant/:variantId',
  // api integrations
  API_INTEGRATIONS: '/:accountId/:projectId/api-integrations',
  API_INTEGRATION_CREATE: '/:accountId/:projectId/api-integrations/new',
  API_INTEGRATION_DETAILS:
    '/:accountId/:projectId/api-integrations/:integrationId',

  USER_SETTINGS: '/settings/user',
};

export const PAGE_TITLES: { [key in keyof typeof PATHS]: string } = {
  AGENT_CONFIG: 'Agent Config',
  ANALYTICS_SETTINGS: 'Advanced Settings',
  ANALYTICS: 'Analytics',
  API_INTEGRATION_CREATE: 'API Integrations',
  API_INTEGRATION_DETAILS: 'API Integrations',
  API_INTEGRATIONS: 'Api Integrations',
  AUDIO_DETAILS: 'Audio',
  AUDIO: 'Audio',
  CALL_FLOWS_DASHBOARD: 'Call Flows',
  CHAT_REPORTS: 'Chat Reports',
  CONVERSATION_REVIEW: 'Conversation',
  CONVERSATIONS_LOG: 'Conversations Log',
  CONVERSATIONS: 'Conversations',
  DAILY_PLAYLISTS: 'Daily playlist',
  LIVE_CONVERSATIONS: 'Live Conversations',
  LIVE_CONVERSATIONS_REVIEW: 'Live Conversation',
  DATA_STORE_DETAILS: 'Data Store',
  DATA_STORE: 'Data Store',
  ENTITIES: 'Entities',
  ENTITY_DETAILS: 'Entity',
  FAQ_EDITOR: 'FAQ Editor',
  FLOW_DESIGN: 'Flow Design',
  FLOWS: 'Flows',
  INTENT_DETAILS: 'Intent',
  INTENTS: 'Intents',
  PORTAL_ANALYTICS_DASHBOARD: 'Analytics Dashboard',
  PROJECT_SETTINGS: 'Project Settings',
  SCENARIO_DETAILS: 'Scenarios',
  SCENARIOS: 'Scenarios',
  USER_SETTINGS: 'User Settings',
  UTILITIES: 'Utilities',
  UTILITY_DETAILS: 'Utility',
  VARIANT_SETTINGS: 'Variant Settings',
};

export const PROJECT_ROOT_PATH = '/[accountId]/[projectId]';
export const BASE_ROUTE = '/:accountId/:projectId/';
export const NON_WRAPPED_PATHS = [
  PATHS.FLOW_DESIGN,
  PATHS.CALL_FLOWS_DASHBOARD,
];
