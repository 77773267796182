import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const LightBulbIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M7.5 15C7.5 14.6548 7.77982 14.375 8.125 14.375H11.875C12.2202 14.375 12.5 14.6548 12.5 15C12.5 15.3452 12.2202 15.625 11.875 15.625H8.125C7.77982 15.625 7.5 15.3452 7.5 15Z" />
      <path d="M8.75 16.875C8.75 16.5298 9.02982 16.25 9.375 16.25H10.625C10.9702 16.25 11.25 16.5298 11.25 16.875C11.25 17.2202 10.9702 17.5 10.625 17.5H9.375C9.02982 17.5 8.75 17.2202 8.75 16.875Z" />
      <path
        clipRule="evenodd"
        d="M10 3.125C7.60589 3.125 5.625 5.02852 5.625 7.8125C5.625 9.52776 6.83874 10.4796 7.75546 10.9566C8.40467 11.2943 8.95833 11.9726 8.95833 12.8169V13.125C8.95833 13.4702 8.67851 13.75 8.33333 13.75C7.98816 13.75 7.70833 13.4702 7.70833 13.125V12.8169C7.70833 12.546 7.51873 12.2424 7.17854 12.0655C6.11995 11.5147 4.375 10.2364 4.375 7.8125C4.375 4.38327 6.87127 1.875 10 1.875C13.1287 1.875 15.625 4.38327 15.625 7.8125C15.625 10.351 14.0167 11.5733 12.9112 12.0986C12.5148 12.2869 12.2917 12.6219 12.2917 12.9124V13.125C12.2917 13.4702 12.0118 13.75 11.6667 13.75C11.3215 13.75 11.0417 13.4702 11.0417 13.125V12.9124C11.0417 11.9998 11.6846 11.2975 12.3747 10.9695C13.2366 10.56 14.375 9.68693 14.375 7.8125C14.375 5.02852 12.3941 3.125 10 3.125Z"
        fillRule="evenodd"
      />
    </BaseIcon>
  );
};
