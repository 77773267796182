/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CloseIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <path d="M22.293 8.293a1 1 0 1 1 1.414 1.414L17.414 16l6.293 6.293a1 1 0 0 1-1.414 1.414L16 17.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L14.586 16 8.293 9.707a1 1 0 0 1 1.414-1.414L16 14.586l6.293-6.293Z" />
    </BaseIcon>
  );
};
