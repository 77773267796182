/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const AudioIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 2a6 6 0 0 0-6 6v7a6 6 0 0 0 12 0V8a6 6 0 0 0-6-6Zm4 13a4 4 0 0 1-8 0V8a4 4 0 0 1 8 0v7Z" />
      <path d="M15 25.95V29a1 1 0 1 0 2 0v-3.05c5.053-.501 9-4.765 9-9.95a1 1 0 1 0-2 0 8 8 0 1 1-16 0 1 1 0 1 0-2 0c0 5.185 3.947 9.449 9 9.95Z" />
    </BaseIcon>
  );
};
