/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const RevertIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M18.7558 7.17294C17.0099 6.82567 15.2003 7.0039 13.5558 7.68509C11.9113 8.36628 10.5057 9.51983 9.51672 10.9999C8.52779 12.4799 7.99995 14.22 7.99995 16L9.79284 16C10.2383 16 10.4614 16.5386 10.1464 16.8536L7.3535 19.6464C7.15824 19.8417 6.84166 19.8417 6.6464 19.6464L3.8535 16.8536C3.53852 16.5386 3.76161 16 4.20706 16L5.99995 16C5.99995 13.8244 6.64509 11.6977 7.85378 9.88873C9.06248 8.07979 10.7804 6.66989 12.7904 5.83733C14.8004 5.00477 17.0122 4.78693 19.1459 5.21137C21.2797 5.6358 23.2397 6.68345 24.7781 8.22183C26.3165 9.76021 27.3641 11.7202 27.7886 13.854C28.213 15.9878 27.9952 18.1995 27.1626 20.2095C26.3301 22.2195 24.9202 23.9375 23.1112 25.1462C21.3023 26.3549 19.1755 27 16.9999 27C16.4477 27 15.9999 26.5523 15.9999 26C15.9999 25.4477 16.4477 25 16.9999 25C18.78 25 20.52 24.4722 22.0001 23.4832C23.4801 22.4943 24.6337 21.0887 25.3149 19.4442C25.996 17.7996 26.1743 15.99 25.827 14.2442C25.4797 12.4984 24.6226 10.8947 23.3639 9.63604C22.1052 8.37737 20.5016 7.5202 18.7558 7.17294Z" />
  </BaseIcon>
);
