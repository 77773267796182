import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 300000,
      staleTime: 60000,
      retry: false,
    },
  },
});

export default queryClient;
