import { createAction } from '@reduxjs/toolkit';

import { UploadType } from 'components/organisms/UploadPanel/UploadPanel.constants';

export const projectInit = createAction('projectInit');
export const closeAllPanels = createAction('closeAllPanels');
export const closeOtherPanels = createAction<UploadType | undefined>(
  'closeOtherPanels',
);
export const createNewChat = createAction('createNewChat');
export const createNewDeployment = createAction('createNewDeployment');
export const closeSidePanel = createAction('closeSidePanel');
export const createNewDraftChat = createAction('createNewDraftChat');
export const resetUploadStatus = createAction<UploadType | undefined>(
  'resetUploadStatus',
);
