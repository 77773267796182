import { ConversationTag } from 'api/resources/conversation/types';

export type ConversationIssueField = {
  placeholder?: string;
  label?: string;
  value?: string;
};

export interface ConversationIssue {
  value: string;
  label: string;
  tag: ConversationTag;
  isActive: boolean;
  field?: ConversationIssueField;
}

export type IssueOptions = {
  [key in ConversationTag]: ConversationIssue;
};

export const ISSUE_OPTIONS: IssueOptions = {
  [ConversationTag.TRANSCRIPTION]: {
    value: 'INCORRECT_TRANSCRIPT',
    label: 'Transcript is incorrect',
    tag: ConversationTag.TRANSCRIPTION,
    isActive: false,
    field: {
      placeholder: 'Type correct transcript in full',
      label: 'Correct transcript',
    },
  },
  [ConversationTag.CLARITY]: {
    value: 'USER_NOT_UNDERSTOOD',
    label: 'User cannot be understood',
    tag: ConversationTag.CLARITY,
    isActive: false,
  },
  [ConversationTag.VOICE_ACTOR]: {
    value: 'NO_VOICE_ACTOR',
    label: 'No voice actor recording',
    tag: ConversationTag.VOICE_ACTOR,
    isActive: false,
  },
  [ConversationTag.INTERRUPTION]: {
    value: 'AGENT_INTERRUPTION',
    label: 'Agent interrupts caller',
    tag: ConversationTag.INTERRUPTION,
    isActive: false,
  },
  [ConversationTag.LATENCY]: {
    value: 'AGENT_LATENCY',
    label: 'Agent takes a long time to respond',
    tag: ConversationTag.LATENCY,
    isActive: false,
  },
  [ConversationTag.LISTENING]: {
    value: 'AGENT_NOT_LISTENING',
    label: 'Agent is not listening to caller',
    tag: ConversationTag.LISTENING,
    isActive: false,
  },
  [ConversationTag.INTENT]: {
    value: 'INCORRECT_INTENT_DETECTED',
    label: 'Intent is incorrectly detected',
    tag: ConversationTag.INTENT,
    isActive: false,
    field: {
      label: 'Correct intent',
    },
  },
  [ConversationTag.ENTITY]: {
    value: 'INCORRECT_ENTITY_DETECTED',
    label: 'Entity is incorrectly detected',
    tag: ConversationTag.ENTITY,
    isActive: false,
    field: {
      label: 'Correct entities',
    },
  },
  [ConversationTag.POLICY]: {
    value: 'INCORRECT_AGENT_CONFIG',
    label: 'Agent is configured incorrectly',
    tag: ConversationTag.POLICY,
    isActive: false,
    field: {
      label: 'Issue description',
      placeholder: 'Describe the issue',
    },
  },
  [ConversationTag.OUT_OF_SCOPE]: {
    value: 'OUT_OF_SCOPE',
    label: 'Agent does not have this functionality',
    tag: ConversationTag.OUT_OF_SCOPE,
    isActive: false,
    field: {
      label: 'Topic desciption',
      placeholder: 'Describe the topic',
    },
  },
  [ConversationTag.NO_ISSUE]: {
    value: 'NO_ISSUE',
    label: 'There is no issue with this turn',
    tag: ConversationTag.NO_ISSUE,
    isActive: false,
  },
};

export const SIMPLIFIED_CONVERSATION_REVIEW_ISSUE_OPTIONS: ConversationTag[] = [
  ConversationTag.TRANSCRIPTION,
  ConversationTag.CLARITY,
  ConversationTag.VOICE_ACTOR,
  ConversationTag.INTENT,
  ConversationTag.POLICY,
  ConversationTag.OUT_OF_SCOPE,
];
