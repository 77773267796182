import { ClientEnvironment } from '@polyai/common/constants/GlobalNew.constants';

import { doDelete, doGet, doPatch, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getScenario = (scenarioId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/scenarios/${scenarioId}`,
    LOGGER_TYPE.SCENARIOS,
  );
};

export const getScenarios = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(`${accountId}/${projectId}/scenarios`, LOGGER_TYPE.SCENARIOS);
};

export const deleteScenarios = (scenarioIds: string[]) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  const version = store.getState().version.currentPolicyVersion;

  return doDelete(
    `${accountId}/${projectId}/scenarios`,
    LOGGER_TYPE.SCENARIOS,
    { scenario_ids: scenarioIds, version },
  );
};

export const getScenarioResults = (clientEnv: ClientEnvironment) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/scenario_results/${clientEnv}`,
    LOGGER_TYPE.SCENARIOS,
  );
};

export const getScenariosCSV = (scenarioIds?: string[]) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPost<Blob>(
    `${accountId}/${projectId}/scenarios/csv`,
    LOGGER_TYPE.SCENARIOS,
    scenarioIds ? { scenario_ids: scenarioIds } : undefined,
    undefined,
    undefined,
    false,
    true,
  );
};

export const putScenariosCSV = (file: File, dryRun: boolean = true) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;
  const params = dryRun ? `?dry_run=true` : ``;

  const bodyFormData = new FormData();
  bodyFormData.append('version', version);
  const blob = new Blob([file], { type: 'text/csv' });
  bodyFormData.append('csv', blob);

  return doPut(
    `${accountId}/${projectId}/scenarios/csv${params}`,
    LOGGER_TYPE.SCENARIOS,
    bodyFormData,
    undefined,
    undefined,
    true,
  );
};

export const patchScenariosCSV = (file: File, dryRun: boolean = true) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;
  const params = dryRun ? `?dry_run=true` : ``;

  const bodyFormData = new FormData();
  bodyFormData.append('version', version);
  const blob = new Blob([file], { type: 'text/csv' });
  bodyFormData.append('csv', blob);

  return doPatch(
    `${accountId}/${projectId}/scenarios/csv${params}`,
    LOGGER_TYPE.SCENARIOS,
    bodyFormData,
    undefined,
    true,
  );
};
