/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DataIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M11 3a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H11ZM8 9a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm-2 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V15a2 2 0 0 0-2-2H6Zm20 2v12H6V15h20Z" />
    </BaseIcon>
  );
};
