import { LOGGER_TYPE } from 'api/types';
import { store } from '../../../reduxStore';
import { doDelete, doGet, doPut } from '../../request';
import { GetAnalyticsSettingsResponse } from './types';

export const getAnalyticsDashboard = (id: string) => {
  const projectId = store.getState().projectInfo.id;
  return doGet(
    `projects/${projectId}/dashboards/${id}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const getAnalyticsDashboards = (projectId?: string) => {
  const projectIdToUse = projectId ?? store.getState().projectInfo.id;

  return doGet(
    `projects/${projectIdToUse}/dashboards`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const upsertAnalyticsDashboards = (
  dashboards: {
    id: number | null;
    name?: string;
    url?: string;
  }[],
) => {
  const projectId = store.getState().projectInfo.id;
  return doPut(
    `projects/${projectId}/dashboards`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    { dashboards },
  );
};

export const deleteAnalyticsDashboards = (ids: number[]) => {
  const projectId = store.getState().projectInfo.id;
  return doDelete(
    `projects/${projectId}/dashboards`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    { ids },
  );
};

export const getAnalyticsSettings = (accountId: string, projectId: string) => {
  return doGet<GetAnalyticsSettingsResponse>(
    `${accountId}/${projectId}/analytics_settings`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};
