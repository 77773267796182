import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ConfirmationFilledIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Zm3.9-15.293a1 1 0 1 1 1.414 1.414l-6.364 6.364a1 1 0 0 1-1.414 0l-2.829-2.828a1 1 0 0 1 1.414-1.415l2.122 2.122 5.657-5.657Z" />
    </BaseIcon>
  );
};
