import { ReactElement, useMemo } from "react";

import { StatusType } from "@datadog/browser-logs";
import {
  AlertContainerFactory,
  AlertCustomOptions as DefaultAlertCustomOptions,
  AlertInstance,
} from "react-alert";

export enum ALERT_LOGGER_TYPE {
  ERROR = "errorAlertLogger",
  INFO = "infoAlertLogger",
  WARNING = "warningAlertLogger",
  SUCCESS = "successAlertLogger",
}

export interface AlertCustomOptions extends DefaultAlertCustomOptions {
  additionalType?: "warning";
}

export type AlertMessageType = {
  title: string;
  actionButton?: ReactElement;
};

type GetLogContentHandler = (
  message: ReactElement<AlertMessageType> | string,
  pathname: string
) => {
  page: string;
  alert_message: string;
  alert_title?: string;
};

type LogToDatadogHandler = (
  loggerType: ALERT_LOGGER_TYPE,
  statusType: StatusType,
  message: string,
  body: Record<string, any>
) => void;

// This is a simple base wrapper over `react-alert` that makes it possible for us to tailor
// the different methods how we want, e.g we don't want errors to automatically disappear

// Each app instantiates its own useAlert hook which passes app-specific:
// 1. react-alert instance
// 2. parameters (e.g. pathname, timeout, state dependent logging)
export const useBaseAlert = (
  alert: AlertContainerFactory<AlertCustomOptions>,
  pathname: string,
  handleGetLogContent: GetLogContentHandler,
  handleLogToDataDog: LogToDatadogHandler,
  timeout: number = 5000
) =>
  useMemo(
    () => ({
      info: (
        message: ReactElement<AlertMessageType> | string,
        options?: AlertCustomOptions
      ) => {
        return alert.info(message, options);
      },
      success: (
        message: ReactElement<AlertMessageType> | string,
        options?: AlertCustomOptions
      ) => {
        const content = handleGetLogContent(message, pathname);
        handleLogToDataDog(
          ALERT_LOGGER_TYPE.SUCCESS,
          "info",
          content.alert_message,
          content
        );

        return alert.success(message, options);
      },
      warn: (
        message: ReactElement<AlertMessageType> | string,
        options?: AlertCustomOptions
      ) => {
        const content = handleGetLogContent(message, pathname);
        handleLogToDataDog(
          ALERT_LOGGER_TYPE.WARNING,
          "warn",
          content.alert_message,
          content
        );

        return alert.error(message, { ...options, additionalType: "warning" });
      },
      error: (
        message: ReactElement<AlertMessageType> | string,
        options?: AlertCustomOptions
      ) => {
        const content = handleGetLogContent(message, pathname);
        handleLogToDataDog(
          ALERT_LOGGER_TYPE.ERROR,
          "error",
          content.alert_message,
          content
        );

        return alert.error(message, {
          ...options,
          timeout,
        });
      },
      remove: (alertInstance: AlertInstance) => {
        return alert.remove(alertInstance);
      },
      removeAll: () => {
        return alert.removeAll();
      },
    }),
    [alert, handleGetLogContent, handleLogToDataDog, pathname, timeout]
  );

export type BaseAlertInstance = ReturnType<typeof useBaseAlert>;
