import { ClientEnvironment } from '@polyai/common/constants/GlobalNew.constants';

import { doGet, doPut } from 'api/request';
import { FileResponse } from 'api/resources/data';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import { store } from 'reduxStore';
import {
  AgentConfigFileResponse,
  AgentConfigFilesResponse,
  AgentConfigUpsert,
} from './types';

const BASE_TEMPLATE = ':accountId/:projectId/agent-config';
const FILE_TEMPLATE = `${BASE_TEMPLATE}/:fileName`;

export const getAgentConfigFiles = (projectId: string) => {
  const accountId = store.getState().accountInfo.id;

  return doGet<AgentConfigFilesResponse>(
    generateNextPath(BASE_TEMPLATE, { accountId, projectId }),
    LOGGER_TYPE.DATA_STORE,
  );
};

export const getAgentConfigFile = (
  fileName: string,
  clientEnv: ClientEnvironment,
  activeVariant?: string,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet<AgentConfigFileResponse>(
    generateNextPath(
      FILE_TEMPLATE,
      {
        accountId,
        projectId,
        fileName,
      },
      { client_env: clientEnv, path: activeVariant },
    ),
    LOGGER_TYPE.DATA_STORE,
  );
};

export const upsertAgentConfigFile = async ({
  fileName,
  ...payload
}: AgentConfigUpsert) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doPut<FileResponse>(
    generateNextPath(FILE_TEMPLATE, { accountId, projectId, fileName }),
    LOGGER_TYPE.DATA_STORE,
    payload,
  );
};
