import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';

import accountInfoReducer from 'ducks/accountInfo/accountInfoSlice';
import apiIntegrationsReducer from 'ducks/apiIntegrations/apiIntegrationsSlice';
import audioReducer from 'ducks/audio/audioSlice';
import audioTestingReducer from 'ducks/audioTestingSlice';
import authReducer from 'ducks/auth/authSlice';
import canvasReducer from 'ducks/canvas/canvasSlice';
import chatPanelReducer from 'ducks/chatPanel/chatPanelSlice';
import conversationReducer from 'ducks/conversation/conversationSlice';
import deploymentPanelSlice from 'ducks/deploymentPanel/deploymentPanelSlice';
import deploymentsReducer from 'ducks/deployments/deploymentsSlice';
import flowsReducer from 'ducks/flows/flowsSlice';
import layoutReducer from 'ducks/layout/layoutSlice';
import lockReducer from 'ducks/lock/lockSlice';
import notificationReducer from 'ducks/notifications/notificationsSlice';
import pageStatusReducer from 'ducks/pageStatus/pageStatusSlice';
import policyReducer from 'ducks/policy/policySlice';
import projectInfoReducer from 'ducks/projectInfo/projectInfoSlice';
import scenariosReducer from 'ducks/scenarios/scenariosSlice';
import tableReducer from 'ducks/table/tableSlice';
import testAgentReducer from 'ducks/testAgent/testAgentSlice';
import uploadPanelReducer from 'ducks/uploadPanel/uploadPanelSlice';
import versionReducer from 'ducks/version/versionSlice';

const rootReducer = combineReducers({
  apiIntegrations: apiIntegrationsReducer,
  accountInfo: accountInfoReducer,
  audio: audioReducer,
  audioTesting: audioTestingReducer,
  auth: authReducer,
  canvas: canvasReducer,
  chatPanel: chatPanelReducer,
  deploymentPanel: deploymentPanelSlice,
  conversation: conversationReducer,
  deployments: deploymentsReducer,
  flows: flowsReducer,
  layout: layoutReducer,
  lock: lockReducer,
  notificationCenter: notificationReducer,
  pageStatus: pageStatusReducer,
  policy: policyReducer,
  projectInfo: projectInfoReducer,
  scenarios: scenariosReducer,
  table: tableReducer,
  testAgent: testAgentReducer,
  uploadPanel: uploadPanelReducer,
  version: versionReducer,
});

export const buildStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'auth/setLogin',
            'auth/setLogout',
            'auth/setGetAuthToken',
          ],
          ignoredPaths: [
            'auth.login',
            'auth.logout',
            'auth.getAuthToken',
            'payload',
          ],
        },
      }),
  });
};

export const store = buildStore();

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof buildStore>;
export type RootState = ReturnType<typeof rootReducer>;
