import {
  PatchUsersRequest,
  PatchUsersResponse,
  UserInfo,
} from '@polyai/common/api/resources/userManagement/types';

import { doDelete, doGet, doPatch, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getUsers = (accountId: string) => {
  return doGet<UserInfo[]>(
    `users`,
    LOGGER_TYPE.USER_MANAGEMENT,
    { account_id: accountId },
    undefined,
    undefined,
    undefined,
    undefined,
    'v2',
  );
};

export const getUser = (user_email: string) => {
  const accountId = store.getState().accountInfo.id;

  return doPost(
    'users',
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      user_email,
      account_id: accountId,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'v2',
  );
};

export const patchUsers = (body: Omit<PatchUsersRequest, 'account_id'>) => {
  const accountId = store.getState().accountInfo.id;

  return doPatch<PatchUsersResponse>(
    'users',
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      ...body,
      account_id: accountId,
    },
    undefined,
    undefined,
    undefined,
    'v2',
  );
};

export const deleteUsers = (users_email: string[]) => {
  const accountId = store.getState().accountInfo.id;

  return doDelete(
    'users',
    LOGGER_TYPE.USER_MANAGEMENT,
    {
      users_email,
      account_id: accountId,
    },
    undefined,
    'v2',
  );
};
