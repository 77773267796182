import { doDelete, doGet, doPost, doPut } from 'api/request';
import { store } from '../../reduxStore';
import {
  LOGGER_TYPE,
  PhoneNumberCreateRequestBodyType,
  PhoneNumbersType,
} from '../types';

export const getPhoneNumbers = (accountId: string, projectId: string) => {
  return doGet<PhoneNumbersType>(
    `numbers/list?account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const allocatePhoneNumber = (
  accountId: string,
  projectId: string,
  phoneNumbers: PhoneNumberCreateRequestBodyType[],
) => {
  return doPost(
    `numbers/buy?account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      numbers: phoneNumbers,
    },
  );
};

export const releasePhoneNumber = (phoneNumber: string) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doDelete(
    `numbers/release?phone_number=${encodeURIComponent(
      phoneNumber,
    )}&account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const updatePhoneNumber = (body: any) => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;
  return doPut(
    `numbers/info?account_id=${accountId}&project_id=${projectId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      ...body,
    },
  );
};
