export enum PROJECT_RESOURCE {
  ANALYTICS = 'analytics',
  AGENT_CONFIG = 'agent_config',
  API_INTEGRATIONS = 'api_integrations',
  CALL_DOWNLOAD = 'call_download',
  CALL_RECORDINGS = 'call_recordings',
  CONVERSATION_REVIEW = 'conversation_review',
  CONVERSATIONS = 'conversations',
  CONVERSATIONS_LOG = 'conversations_log',
  DATA_STORE = 'data_store',
  DEPLOYMENT = 'deployment',
  PLAYLIST_CONVERSATIONS = 'playlist_conversations',
  PLAYLIST_CONVERSATIONS_DOWNLOAD = 'playlist_conversations_download',
  PROJECT_SETTING = 'settings',
  SCENARIOS = 'scenarios',
  UTILITIES = 'utilities',
  USER_MANAGEMENT = 'user_management',
  SIMPLIFIED_CONVERSATION_REVIEW = 'simplified_conversation_review',
}
