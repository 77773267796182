/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const PhoneIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M11.071 20.37c-2.964-2.964-4.43-6.313-4.854-7.87-.297-1.088-.152-2.28.169-3.293.32-1.014.86-1.983 1.503-2.626l.04-.04c.403-.404.803-.804 1.198-1.094.434-.318.95-.573 1.59-.573.317 0 .614.12.786.198.212.095.44.22.669.36.459.28.983.655 1.482 1.055.499.4.996.844 1.39 1.268.198.212.383.434.533.655.14.205.3.485.364.803.255 1.279-.429 2.237-.843 2.818l-.06.084c-.487.688-.608.95-.522 1.293.148.59.417.923 1.505 2.011 1.088 1.089 1.421 1.357 2.01 1.505.343.086.606-.035 1.294-.522l.084-.06c.581-.414 1.54-1.098 2.818-.842.319.063.598.224.803.363.221.15.444.336.655.533.425.394.869.891 1.268 1.39.4.499.775 1.023 1.055 1.482.14.229.265.457.36.669.077.172.199.47.199.786 0 .64-.256 1.157-.574 1.59-.29.395-.69.795-1.094 1.199l-.04.04c-.643.643-1.612 1.182-2.625 1.502-1.014.321-2.206.466-3.294.17-1.557-.425-4.905-1.891-7.869-4.855Zm3.536-3.536c-1.034-1.034-1.729-1.734-2.031-2.939-.328-1.306.368-2.286.787-2.876l.043-.06c.481-.679.638-.967.58-1.322a.964.964 0 0 0-.064-.105 3.646 3.646 0 0 0-.342-.415c-.309-.332-.727-.71-1.176-1.07a13.29 13.29 0 0 0-1.274-.908 4.203 4.203 0 0 0-.482-.258c-.07.014-.172.057-.337.178-.258.19-.552.48-1.008.936-.363.363-.76 1.026-1.01 1.815-.25.789-.309 1.568-.146 2.164.342 1.256 1.645 4.288 4.338 6.981 2.693 2.693 5.726 3.996 6.98 4.339.597.162 1.376.103 2.165-.146.789-.25 1.452-.648 1.815-1.011.456-.456.747-.75.936-1.008.121-.165.164-.267.178-.336l-.008-.02-.008-.018a4.199 4.199 0 0 0-.242-.444c-.227-.372-.55-.826-.909-1.275-.36-.449-.737-.867-1.07-1.176a3.656 3.656 0 0 0-.414-.341.975.975 0 0 0-.105-.064c-.355-.06-.643.098-1.322.58l-.06.042c-.59.419-1.57 1.115-2.876.787-1.205-.302-1.905-.997-2.939-2.03Z" />
  </BaseIcon>
);
