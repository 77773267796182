import { doGet, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const getUtilities = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(`${accountId}/${projectId}/utilities`, LOGGER_TYPE.UTILITIES);
};

export const getUtility = (utilityId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/utilities/${utilityId}`,
    LOGGER_TYPE.UTILITIES,
  );
};

export const putUtility = (utilityId: string, code: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/utilities/${utilityId}`,
    LOGGER_TYPE.UTILITIES,
    {
      code,
      version,
    },
  );
};
