/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HotelIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M14 11h-2v3h2v-3Zm0 12h-2v3h2v-3Zm0-6h-2v3h2v-3Zm6-6h-2v3h2v-3Zm0 12h-2v3h2v-3Zm0-6h-2v3h2v-3Z" />
    <path d="M22 3v4h2v4h5v18H3V11h5V7h2V3h12Zm5 24V13h-3v14h3Zm-5 0V9H10v18h12ZM8 27V13H5v14h3ZM20 5h-8v2h8V5Z" />
  </BaseIcon>
);
