/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const UploadIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M15.937 7.75a8.674 8.674 0 0 1 7.828 5.009c1.582.796 2.637 1.811 3.222 2.97.627 1.246.656 2.548.356 3.708-.296 1.15-.918 2.183-1.638 2.966-.703.765-1.598 1.396-2.509 1.578a.995.995 0 0 1-.196.02L16 24a1 1 0 0 1-1-1v-5.586l-1.793 1.793a1 1 0 0 1-1.414-1.414l3.5-3.5a1 1 0 0 1 1.414 0l3.5 3.5a1 1 0 0 1-1.414 1.414L17 17.414V22h5.884c.34-.096.843-.4 1.349-.95.529-.576.97-1.324 1.174-2.112.2-.778.166-1.57-.206-2.308-.374-.742-1.145-1.541-2.62-2.222a1 1 0 0 1-.504-.523 6.678 6.678 0 0 0-6.14-4.135c-2.493 0-5.137 1.465-6.5 5.101a1 1 0 0 1-.49.543c-2.155 1.078-2.723 2.772-2.413 4.059C6.847 20.747 8.137 22 10.5 22a1 1 0 0 1 0 2c-3.137 0-5.347-1.747-5.91-4.078-.54-2.24.522-4.68 3.114-6.132 1.683-4.117 4.946-6.04 8.233-6.04Z" />
  </BaseIcon>
);
