import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectInit } from 'actions';

export interface VersionState {
  currentPolicyVersion: string;
  refreshProjectAfterDeletingDraft: boolean;
  draftPolicyVersion?: string;
}

const initialState: VersionState = {
  currentPolicyVersion: '',
  refreshProjectAfterDeletingDraft: false,
  draftPolicyVersion: '',
};

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setCurrentPolicyVersion: (
      state,
      { payload: currentPolicyVersion }: PayloadAction<string>,
    ) => {
      state.currentPolicyVersion = currentPolicyVersion;
    },
    setRefreshProjectAfterDeletingDraft: (
      state,
      refreshProjectAfterDeletingDraft: PayloadAction<boolean>,
    ) => {
      state.refreshProjectAfterDeletingDraft =
        refreshProjectAfterDeletingDraft.payload;
    },
    setCurrentDraftPolicyVersion: (
      state,
      { payload: draftPolicyVersion }: PayloadAction<string>,
    ) => {
      state.draftPolicyVersion = draftPolicyVersion;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectInit, () => {
      return { ...initialState };
    });
  },
});

export const {
  setCurrentPolicyVersion,
  setRefreshProjectAfterDeletingDraft,
  setCurrentDraftPolicyVersion,
} = versionSlice.actions;

export default versionSlice.reducer;
