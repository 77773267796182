/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ListenIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M24.3186 4.90202C24.692 4.4951 25.3246 4.46793 25.7315 4.84133C27.551 6.51097 28.6939 8.91182 28.6939 11.5781C28.6939 14.2444 27.551 16.6453 25.7315 18.3149C25.3246 18.6883 24.692 18.6612 24.3186 18.2542C23.9452 17.8473 23.9724 17.2147 24.3793 16.8413C25.8034 15.5346 26.6939 13.6611 26.6939 11.5781C26.6939 9.4952 25.8034 7.6217 24.3793 6.31493C23.9724 5.94152 23.9452 5.30894 24.3186 4.90202Z" />
    <path d="M6 12.8182C6 9.55931 8.50693 7 11.5 7C14.4931 7 17 9.55931 17 12.8182C17 14.81 16.0561 16.5542 14.6341 17.5997C13.3226 18.5639 11.8 20.111 11.8 22.1818C11.8 23.7184 10.5217 25 8.9 25C7.27828 25 6 23.7184 6 22.1818C6 21.6295 5.55228 21.1818 5 21.1818C4.44772 21.1818 4 21.6295 4 22.1818C4 24.8627 6.2139 27 8.9 27C11.5861 27 13.8 24.8627 13.8 22.1818C13.8 21.125 14.6104 20.0995 15.8188 19.211C17.751 17.7904 19 15.4492 19 12.8182C19 8.54589 15.6866 5 11.5 5C7.31337 5 4 8.54589 4 12.8182C4 13.3705 4.44772 13.8182 5 13.8182C5.55228 13.8182 6 13.3705 6 12.8182Z" />
    <path d="M10 12.6667C10 11.6844 10.7314 11 11.5 11C12.2686 11 13 11.6844 13 12.6667C13 13.219 13.4477 13.6667 14 13.6667C14.5523 13.6667 15 13.219 15 12.6667C15 10.7034 13.4928 9 11.5 9C9.50719 9 8 10.7034 8 12.6667V13.7882C8 14.5454 8.28824 15.2743 8.80618 15.8268C8.97659 16.0086 9.07143 16.2484 9.07143 16.4976V18C9.07143 18.5523 9.51914 19 10.0714 19C10.6237 19 11.0714 18.5523 11.0714 18V16.4976C11.0714 15.7403 10.7832 15.0114 10.2653 14.4589C10.0948 14.2771 10 14.0373 10 13.7882V12.6667Z" />
    <path d="M22.7315 7.84133C22.3246 7.46793 21.692 7.4951 21.3186 7.90202C20.9452 8.30894 20.9724 8.94152 21.3793 9.31493C21.9925 9.8776 22.3746 10.6825 22.3746 11.5781C22.3746 12.4738 21.9925 13.2787 21.3793 13.8413C20.9724 14.2147 20.9452 14.8473 21.3186 15.2542C21.692 15.6612 22.3246 15.6883 22.7315 15.3149C23.7401 14.3894 24.3746 13.0571 24.3746 11.5781C24.3746 10.0991 23.7401 8.76687 22.7315 7.84133Z" />
  </BaseIcon>
);
