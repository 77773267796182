import { DynamicError } from '@polyai/common/types/api';

import { TurnMetadata } from './resources/conversation/types';

export type ResponseWithVersion = { version: string; draft: boolean };

export interface Intents {
  [i: number]: string;
}

export interface ConditionConfig {
  code?: string;
  errors?: DynamicError[];
}

export interface MetricConfig {
  [condition: string]: Metric;
}

export interface Metric {
  [key: string]: string;
}

export interface Utterance {
  name?: string;
  text: TextConfig[];
  slots?: SlotConfigs;
  errors?: DynamicError[];
  warnings?: DynamicError[];
}

export interface SlotConfig {
  type: string;
  key: string;
  format: string;
  name: string;
}

export interface SlotConfigs {
  [i: string]: SlotConfig;
}

export interface TextConfig {
  [i: string]: string;
}

export interface Edge {
  label: string;
  node: string;
  stop?: boolean;
  go_back?: boolean;
  type: NodeType;
  position?: Position;
  absolute_node: string;
}

export interface TransitionConfig {
  code: string;
  edges: Edge[];
  errors?: DynamicError[];
  warnings?: DynamicError[];
}

export interface TriggerConditionConfig {
  code?: string;
  errors?: DynamicError[];
  warnings?: DynamicError[];
}

export interface Position {
  x: number;
  y: number;
}

export type ASRConfigV1 = {
  fields: string[];
  keywords?: string[];
};

export type ASRConfigV2 = {
  fields: string[];
  keywords?: Record<string, string[]>;
};

export type ASRConfigV3 = {
  [key in Exclude<string, 'fields' | 'keywords'>]: {
    fields: string[];
    keywords?: string[];
  };
};

export type ASRConfig = ASRConfigV1 | ASRConfigV2 | ASRConfigV3;

export interface ListenConfig {
  asr?: ASRConfig;
  nlu?: {
    requested_slots: string[];
  };
  listen: boolean;
  errors?: DynamicError[];
}

interface ApiIntegrationRequestParams {
  path_params: { [key: string]: string };
  query_params: { [key: string]: string };
  headers: { [key: string]: string };
  body?: string;
}

interface CreateApiIntegrationConfig {
  api_integration_id?: string;
  operation_id?: string;
  poll_max_calls?: number;
  request_params?: ApiIntegrationRequestParams;
}

interface ApiIntegrationConfig extends CreateApiIntegrationConfig {
  service_name?: string;
  poller_name?: string;
  errors?: DynamicError[];
}

export enum NodeType {
  START = 'start',
  END = 'end',
  INTERACTIVE = 'interactive',
  SCRIPTING = 'scripting',
  TRANSFER = 'transfer',
  INFORMATION = 'information',
  SYSTEM = 'system',
  FOLDER = 'folder',
  API_INTEGRATION = 'api_integration',
}

export type Transition = {
  label: string;
};

export interface Node {
  type: NodeType;
  // display label for the front end
  label?: string;
  // internal name -- corresponds to the name in the Flow dictionary
  name?: string;
  position?: Position;
  priority?: number;
  trigger_condition?: TriggerConditionConfig;
  max_repeat?: number;
  on_exit?: string;
  metrics?: MetricConfig;
  say?: Utterance;
  transition?: TransitionConfig;
  listen?: ListenConfig;
  path?: string;
  user_path?: string;
  flow?: string;
  flow_name?: string;
  id?: string;
  incoming_edges?: Edge[];
  positionMissing?: boolean;
  selected?: boolean;
  api_integration_config?: ApiIntegrationConfig;
}

export type Flow = {
  [name: string]: Node | Flow;
} & {
  type: 'flow';
  path: string;
};

export interface Policy {
  start: string;
  default: string;
  nodes: Flow;
}

export interface NodeError {
  node: string;
  type: string;
  line: number;
  message: string;
  file: string;
}

export interface ChatMessage {
  text: string;
  from: 'user' | 'agent';
  metadata?: TurnMetadata;
}
export interface ChatMessageResponse {
  text: string;
  from: 'user' | 'agent';
}

export interface LayoutElement {
  id: string;
  position: {
    x: number;
    y: number;
  };
}

export interface Layout {
  elements: LayoutElement[];
}

export interface Entity {
  id: string;
  short_description?: string;
  description: string;
  example_predictions?: {
    text: string;
    predictions: {
      start: number;
      end: number;
      value: number | string;
    }[];
  }[];
  code_description: string;
  example_code?: string;
  PII_by_default: string;
  output_type: number | string;
  default_slot_name?: string;
  extractor_config?: {
    extractor: string;
  };
  proposer_config?: {
    extractor_as_proposer: {
      extractor: string;
    };
  };
  enabled: boolean;
  category: string;
}

export interface Lock {
  project_id: string;
  locked: boolean;
  caller_holds_lock: boolean;
  version: string;
}

export type UnLockResponse = {
  success: boolean;
  version: string;
};
export interface Locks {
  [project_id: string]: Lock;
}

export interface PhoneNumberCreateRequestBodyType {
  client_env: string;
  country_code: string;
  voice_name: string;
}

export interface PhoneNumberType {
  client_env: string;
  asr_lang_code: string;
  country_code: string;
  number: string;
  variant_id: string;
}

export interface PhoneNumbersType {
  phone_numbers: PhoneNumberType[];
}

export interface MetricMetadata {
  name: string;
  type: string;
}

export interface AutocompleteData {
  label: string;
  type: string;
  detail?: string;
}

export interface SpeechToSpeechResponse {
  generated_wav: string;
}

export interface ResembleVoicesResponse {
  resemble_voices: { id: string; name: string }[];
}

interface TelephonyRouteExtraInfo {
  telephony: {
    asr_lang_code: string;
    tts_lang_code: string;
    fallback?: string;
  };
}
export interface TelephonyRoute {
  account_id: string;
  project_id: string;
  extension: string;
  integration: string;
  connector_id: string; // Always in the format 00000000-000....
  id: string; // This is the connector_id
  variant_id: string;
  client_env: string;
  fallback_route: string;
  extra_info: TelephonyRouteExtraInfo;
  public_facing_number: string;
  call_notes: string;
}

export interface TelephonyRouteResponse {
  fallback: TelephonyFallbackResponse;
  num_of_fallbacks: number;
  routes: TelephonyRoute[];
}

export interface TelephonyFallbackResponse {
  audio_location: string;
  fallback_id: string;
  refer_domain: string;
  routes: {
    [key: string]: string;
  };
  use_sip_refer: boolean;
}

export enum LOGGER_TYPE {
  DEPLOYMENT = 'deploymentLogger',
  ACCOUNT = 'accountLogger',
  CONVERSATIONS = 'conversationsLogger',
  DATA_STORE = 'dataStoreLogger',
  ENTITIES = 'entitiesLogger',
  FLOWS = 'flowsLogger',
  PROJECT_SETTINGS = 'projectSettingsLogger',
  LOCK = 'lockLogger',
  NLU = 'nluLogger',
  NODE = 'nodeLogger',
  NOTIFICATIONS = 'notificationLogger',
  PROJECT = 'projectLogger',
  PROMPTS = 'promptsLogger',
  AUDIO = 'audioLogger',
  SCENARIOS = 'scenariosLogger',
  METRICS = 'metricsLogger',
  FEATURES = 'featuresLogger',
  API_INTEGRATIONS = 'apiIntegrationsLogger',
  UTILITIES = 'utilitiesLogger',
  ANALYTICS = 'analyticsLogger',
  REDUX_STORAGE = 'reduxStorageLogger',
  REACT_ERROR = 'reactError',
  TELEPHONY = 'telephonyLogger',
  USER_MANAGEMENT = 'userManagementLogger',
  BATTLEBOTS = 'battlebot',
}

export * from '@polyai/common/types/api';
