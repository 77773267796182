/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HashIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M12 4h-2v6H4v2h6v8H4v2h6v6h2v-6h8v6h2v-6h6v-2h-6v-8h6v-2h-6V4h-2v6h-8V4Zm8 16h-8v-8h8v8Z" />
  </BaseIcon>
);
