import React, { ComponentProps, ForwardedRef, forwardRef } from "react";

import { ColorName } from "styles/config/colors";
import {
  HoverAnchor,
  HoverButton,
  HoverLink,
  HoverProps,
} from "../HoverTarget";
import { IconSize } from "../Icons/BaseIcon";

type AnchorProps = {
  to: string;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> &
  HoverProps;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ClickableProps = (
  | ({ to?: undefined } & ButtonProps)
  | AnchorProps
) & {
  hoverTarget?: boolean;
  hoverSize?: keyof typeof IconSize;
  hoverTargetColor?: ColorName;
  hoverIconColor?: ColorName;
  circle?: boolean;
};

const externalProps = { target: "_blank", rel: "noopener noreferrer" };

const getPathname = (to: string) => to ?? "";

const Anchor = ({
  to,
  className,
  style,
  children,
  target,
  onClick,
  linkRef,
  ...p
}: AnchorProps & { linkRef: ComponentProps<typeof HoverLink>["ref"] }) => {
  const props = { className, style, target, onClick, ...p };
  // internal links
  if (/^[.#/]/.test(getPathname(to))) {
    return (
      <HoverLink {...props} ref={linkRef} to={to}>
        {children}
      </HoverLink>
    );
  }

  // external links
  if (/^https?:/.test(to)) {
    return (
      <HoverAnchor {...p} {...props} href={to} {...externalProps} ref={linkRef}>
        {children}
      </HoverAnchor>
    );
  }
  // fallback for anything strange
  return (
    <HoverAnchor {...p} {...props} ref={linkRef} href={to}>
      {children}
    </HoverAnchor>
  );
};

const Clickable = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ClickableProps
>(
  (
    {
      to,
      circle,
      hoverTarget,
      hoverSize,
      hoverTargetColor,
      hoverIconColor,
      ...props
    },
    ref
  ) => {
    const hoverProps = {
      $circle: circle,
      $hoverTarget: hoverTarget,
      $hoverSize: hoverSize,
      $hoverTargetColor: hoverTargetColor,
      $hoverIconColor: hoverIconColor,
    };
    if (!to)
      return (
        <HoverButton
          {...(props as ButtonProps)}
          {...hoverProps}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      );
    return (
      <Anchor
        {...(props as AnchorProps)}
        to={to}
        {...hoverProps}
        linkRef={ref}
      />
    );
  }
);

export default Clickable;
