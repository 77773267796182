import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ShieldIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M17 21V11h-2v10h2Z" />
    <path d="M26 7.994 16 3.92 6 7.994V18.75c0 1.389.747 2.65 1.653 3.687.921 1.056 2.122 2.013 3.28 2.81A34.174 34.174 0 0 0 16 28.1a34.174 34.174 0 0 0 5.067-2.854c1.158-.796 2.359-1.753 3.28-2.809C25.252 21.4 26 20.139 26 18.75V7.994ZM8 18.75V9.34l8-3.26 8 3.26v9.41c0 .674-.378 1.476-1.16 2.372-.766.878-1.815 1.726-2.907 2.476a32.186 32.186 0 0 1-2.998 1.811c-.38.204-.701.366-.935.482a32.89 32.89 0 0 1-.935-.482 32.186 32.186 0 0 1-2.998-1.811c-1.092-.75-2.141-1.598-2.907-2.476C8.378 20.226 8 19.424 8 18.75Z" />
  </BaseIcon>
);
