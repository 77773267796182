// merge two arrays of objects + dedupe using defined prop

import { ReactElement } from "react";

// for arrays of objects 1-level deep e.g. [{foo: 'foo', bar: 'bar'}]
export const mergeArraysByProp = (
  a: Record<string, any>[],
  b: Record<string, any>[],
  prop: string
) => {
  // get all elements from old array that are not in the new array
  const filtered = a.filter(
    (
      aitem // find items in the first array that do not have the same value as in the second array, i.e. do not match
    ) =>
      !b.find((bitem) => {
        if (!!aitem[prop] && !!bitem[prop]) {
          // if both objects have the prop
          return aitem[prop] === bitem[prop];
        } else {
          return false; // just keep both
        }
      })
  );
  // add new items to the filtered array
  return filtered.concat(b);
};

export const getUrlParameters = (url: string) => {
  const urlParams: any = {};
  let match;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s: string) {
    return decodeURIComponent(s.replace(pl, " "));
  };
  const query = String(url).split("?")[1];
  if (query) {
    while ((match = search.exec(query))) {
      if (decode(match[1]) in urlParams) {
        if (!Array.isArray(urlParams[decode(match[1])])) {
          urlParams[decode(match[1])] = [urlParams[decode(match[1])]];
        }
        urlParams[decode(match[1])].push(decode(match[2]));
      } else {
        urlParams[decode(match[1])] = [decode(match[2])];
      }
    }
    return urlParams;
  }
  return {};
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}

const REGEX_ONLY_SPECIAL_LETTERS = /^[^a-zA-Z0-9]+$/;
const REGEX_VALID_STRING = /^[a-zA-Z0-9\s!\?@#\$%\^\&*\)\("'+=.,_-]+$/g;

export const isStringInvalid = (str: string) =>
  !str || REGEX_ONLY_SPECIAL_LETTERS.test(str);

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

export const isValidString = (str: string) => {
  REGEX_VALID_STRING.lastIndex = 0;
  return !!str && REGEX_VALID_STRING.test(str);
};

export const prettifyTimeString = (
  time: number,
  options?: { fixedDecimals?: number }
) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60) % 60;
  const seconds =
    !options || typeof options?.fixedDecimals === undefined
      ? time % 60
      : (time % 60).toFixed(options.fixedDecimals);
  let str = "";
  if (hours) str += `${hours}h `;
  if (minutes || hours) str += `${minutes}m `;
  str += `${seconds}s`;
  return str;
};

/*
  Iterate through node layers and return the text as a string
*/
export const getNodeText = (node: ReactElement): string => {
  if (["string", "number", "boolean"].includes(typeof node))
    return node.toString();
  if (Array.isArray(node)) return node.map(getNodeText).join(" ");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
  return "";
};
