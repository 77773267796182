/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const HealthcareIcon: FC<IconProps> = (props) => (
  <BaseIcon {...props}>
    <path d="M17 11v1h1v2h-1v1h-2v-1h-1v-2h1v-1h2Zm-5 7h2v2h-2v-2Zm5 0v2h-2v-2h2Zm-2 4h2v2h-2v-2Zm-1 0h-2v2h2v-2Zm4-4h2v2h-2v-2Zm2 4h-2v2h2v-2Z" />
    <path d="M8 3h16v4h2v2h-2v18h5v2H3v-2h5V9H6V7h2V3Zm2 2v2h12V5H10Zm0 4v18h12V9H10Z" />
  </BaseIcon>
);
