import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";

export const Label = styled.label<{ $fullWidth?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const RightLabel = styled.span``;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${themeSpacing.xs1};

  font-size: ${text.size.body.body4};
  color: ${themeColors.textSecondary};
`;

export const LabelWithHelp = styled.div`
  display: flex;
  border-bottom: 1px dashed ${themeColors.borderUnderline};
`;

export const InputWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  align-items: center;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const ErrorWrapper = styled.div`
  margin-top: ${themeSpacing.xs2};
`;
