import {
  authoriseUser,
  getAccount,
  getAccountAccess,
  getAccounts,
} from './resources/account';
import * as agentConfig from './resources/agentConfig';
import {
  deleteAnalyticsDashboards,
  getAnalyticsDashboard,
  getAnalyticsDashboards,
  getAnalyticsSettings,
  upsertAnalyticsDashboards,
} from './resources/analytics';
import {
  deleteApiIntegration,
  getApiIntegration,
  getApiIntegrations,
  patchApiIntegration,
  postApiIntegration,
} from './resources/apiIntegrations/index';
import { getBattlebots, postBattlebots } from './resources/battlebots';
import {
  createConversation,
  getCallRecording,
  getCallRecordingAudio,
  getConversation,
  getConversationAudio,
  getConversationLogMetricsFilters,
  getConversationMetadata,
  getConversations,
  getConversationsLog,
  getConversationsLogXlsx,
  getLiveConversations,
  getReviewsXlsx,
  getSaferideApiLogJson,
  putConversationMetadata,
  putTurnReviews,
  sendMessage,
} from './resources/conversation';
import {
  getDailyPlaylistDetails,
  getDailyPlaylists,
} from './resources/dailyPlaylists';
import {
  deleteFiles,
  getFileDetails,
  getFiles,
  updateFileDetails,
  upsertFiles,
} from './resources/data';
import {
  deleteDeployment,
  deployDraft,
  deployMain,
  getDeployment,
  getLatestDeployment,
  publish,
  validate,
} from './resources/deploy';
import {
  disableEntity,
  enableEntity,
  getBuiltInEntities,
  getCatalogEntities,
} from './resources/entity';
import {
  createFeature,
  deleteFeature,
  getFeatures,
  updateFeature,
} from './resources/features';
import {
  createFlow,
  deleteFlow,
  deleteFlows,
  detachFlow,
  duplicateFlow,
  duplicateFlows,
  getFlow,
  getFlows,
  putFlows,
  updateFlow,
} from './resources/flows';
import {
  activateLanguage,
  deactivateLanguage,
  editLanguageVoiceId,
  getAllLanguages,
  getLanguage,
} from './resources/language';
import { getLockStatus, lock, unlock } from './resources/lock';
import {
  createIntegration,
  deleteIntegration,
  getCallFlowsData,
  getIntegration,
  getLuzmoAuthToken,
  updateIntegration,
  updateIntegrationConfig,
} from './resources/luzmoAnalytics';
import { getMetricsMetadata } from './resources/metrics';
import {
  deleteIntentFromDataset,
  getNluDataset,
  getNluDatasetCSV,
  patchIntentCSV,
  patchIntentFromDataset,
  patchNluDataset,
  putIntentCSV,
  putNluDataset,
} from './resources/nluDataset';
import {
  createNode,
  deleteNode,
  deleteNodes,
  duplicateNode,
  getNodesMetadata,
  moveNode,
  moveNodes,
  updateNode,
} from './resources/node';
import {
  addNotificationActivities,
  createNotification,
  getNotifications,
} from './resources/notifications';
import {
  allocatePhoneNumber,
  getPhoneNumbers,
  releasePhoneNumber,
  updatePhoneNumber,
} from './resources/phoneNumber';
import {
  createProject,
  deleteProject,
  exportProject,
  getEnabledFeatures,
  getProject,
  importProject,
  updateProject,
} from './resources/project';
import {
  deleteAudioTags,
  deletePrompts,
  getAudio,
  getPrompt,
  getPrompts,
  getTTSCoverageForText,
  patchPrompt,
  patchSlot,
  putAudioTags,
  putPrompts,
  registerAudios,
} from './resources/prompts';
import {
  deleteScenarios,
  getScenario,
  getScenarioResults,
  getScenarios,
  getScenariosCSV,
  patchScenariosCSV,
  putScenariosCSV,
} from './resources/scenarios';
import {
  getResembleVoices,
  postAudioRecording,
} from './resources/speech2speech';
import {
  createTelephonyFallback,
  createTelephonyRoute,
  deleteTelephonyRoute,
  getTelephonyRoutes,
  updateTelephonyFallback,
  updateTelephonyRoute,
} from './resources/telephony';
import { deleteUsers, getUsers, patchUsers } from './resources/userManagement';
import { getUtilities, getUtility, putUtility } from './resources/utilities';
import {
  createVariant,
  deleteVariant,
  getVariant,
  getVariants,
  updateFlowsVariants,
  updateVariant,
} from './resources/variant';
export * as types from './types';

export const api = {
  activateLanguage,
  addNotificationActivities,
  allocatePhoneNumber,
  authoriseUser,
  createConversation,
  createFeature,
  createFlow,
  createIntegration,
  createNode,
  createNotification,
  createProject,
  createTelephonyFallback,
  createTelephonyRoute,
  createVariant,
  deactivateLanguage,
  deleteAnalyticsDashboards,
  deleteApiIntegration,
  deleteAudioTags,
  deleteDeployment,
  deleteFeature,
  deleteFiles,
  deleteFlow,
  deleteFlows,
  deleteIntegration,
  deleteIntentFromDataset,
  deleteNode,
  deleteNodes,
  deleteProject,
  deletePrompts,
  deleteScenarios,
  deleteTelephonyRoute,
  deleteUsers,
  deleteVariant,
  deployDraft,
  deployMain,
  detachFlow,
  disableEntity,
  duplicateFlow,
  duplicateFlows,
  duplicateNode,
  editLanguageVoiceId,
  enableEntity,
  exportProject,
  getAccount,
  getAccountAccess,
  getAccounts,
  getAllLanguages,
  getAnalyticsDashboard,
  getAnalyticsDashboards,
  getAnalyticsSettings,
  getApiIntegration,
  getApiIntegrations,
  getAudio,
  getBuiltInEntities,
  getCallFlowsData,
  getCallRecording,
  getCallRecordingAudio,
  getCatalogEntities,
  getConversation,
  getConversationAudio,
  getConversationMetadata,
  getConversations,
  getConversationsLog,
  getConversationLogMetricsFilters,
  getConversationsLogXlsx,
  getDailyPlaylists,
  getDailyPlaylistDetails,
  getLiveConversations,
  getLuzmoAuthToken,
  getDeployment,
  getEnabledFeatures,
  getFeatures,
  getFileDetails,
  getFiles,
  getFlow,
  getFlows,
  getIntegration,
  getLanguage,
  getLatestDeployment,
  getLockStatus,
  getMetricsMetadata,
  getNluDataset,
  getNluDatasetCSV,
  getNodesMetadata,
  getNotifications,
  getPhoneNumbers,
  getProject,
  getPrompt,
  getPrompts,
  getResembleVoices,
  getReviewsXlsx,
  getSaferideApiLogJson,
  getScenario,
  getScenarioResults,
  getScenarios,
  getScenariosCSV,
  getTelephonyRoutes,
  getTTSCoverageForText,
  getUsers,
  getUtilities,
  getUtility,
  getVariant,
  getVariants,
  importProject,
  lock,
  moveNode,
  moveNodes,
  patchApiIntegration,
  patchIntentCSV,
  patchIntentFromDataset,
  patchNluDataset,
  patchPrompt,
  patchScenariosCSV,
  patchSlot,
  patchUsers,
  postApiIntegration,
  postAudioRecording,
  publish,
  putAudioTags,
  putConversationMetadata,
  putFlows,
  putIntentCSV,
  putNluDataset,
  putPrompts,
  putScenariosCSV,
  putTurnReviews,
  putUtility,
  registerAudios,
  releasePhoneNumber,
  sendMessage,
  unlock,
  updateFeature,
  updateFileDetails,
  updateFlow,
  updateFlowsVariants,
  updateIntegration,
  updateIntegrationConfig,
  updateNode,
  updatePhoneNumber,
  updateProject,
  updateTelephonyFallback,
  updateTelephonyRoute,
  updateVariant,
  upsertAnalyticsDashboards,
  upsertFiles,
  validate,
  getBattlebots,
  postBattlebots,
  ...agentConfig,
};

export default api;
