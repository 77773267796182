/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const VariantAttributesIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props} viewboxSize="md">
      <path d="M7.5 2.5V3.75H3.75L3.75 16.25H7.5V17.5H3.75C3.05964 17.5 2.5 16.9404 2.5 16.25V3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H7.5Z" />
      <path d="M17.5 3.75C17.5 3.05964 16.9404 2.5 16.25 2.5H12.5V3.75H16.25V16.25H12.5V17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V3.75Z" />
      <path d="M12.9419 7.05806C12.6979 6.81398 12.3021 6.81398 12.0581 7.05806L10 9.11612L7.94194 7.05806C7.69786 6.81398 7.30214 6.81398 7.05806 7.05806C6.81398 7.30214 6.81398 7.69786 7.05806 7.94194L9.11612 10L7.05806 12.0581C6.81398 12.3021 6.81398 12.6979 7.05806 12.9419C7.30214 13.186 7.69786 13.186 7.94194 12.9419L10 10.8839L12.0581 12.9419C12.3021 13.186 12.6979 13.186 12.9419 12.9419C13.186 12.6979 13.186 12.3021 12.9419 12.0581L10.8839 10L12.9419 7.94194C13.186 7.69786 13.186 7.30214 12.9419 7.05806Z" />
    </BaseIcon>
  );
};
