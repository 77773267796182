/* eslint-disable max-len */
import React, { FC } from 'react';

import { BaseIcon, IconProps } from './BaseIcon';

export const TerminalIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M9.276 22.69a1 1 0 0 1 .034-1.414l3.408-3.252-3.414-3.306a1 1 0 0 1 1.392-1.436l3.915 3.791.014.015c.5.524.5 1.355 0 1.88l-.016.017-3.919 3.739a1 1 0 0 1-1.414-.034ZM16 22a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z" />
      <path d="M4 5a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4Zm1 2h22v2H5V7Zm0 18V11h22v14H5Z" />
    </BaseIcon>
  );
};
