export const safeResizeObserver = (
  callback: (entries: ResizeObserverEntry[]) => void
) =>
  new ResizeObserver((entries) => {
    // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      callback(entries);
    });
  });
