import React from "react";

import styled, { useTheme } from "styled-components";

import { ComponentWithChildren } from "@polyai/common/types/helpers";

import { ColorName } from "styles/config/colors";

type SVGProps = React.SVGProps<SVGSVGElement>;
type CustomSVGProps = Omit<SVGProps, "height" | "width" | "stroke" | "fill">;

export enum IconSize {
  xs = 12,
  sm = 16,
  md = 20,
  lg = 24,
  xl = 32,
  xxl = 40,
  xxxl = 48,
}

export interface IconProps extends CustomSVGProps {
  size?: keyof typeof IconSize;
  color?: ColorName;
  customFill?: boolean;
  className?: string;
  viewboxSize?: keyof typeof IconSize;
}

const SVGWrapper = styled.div<{ fill: string; customFill: boolean }>`
  display: contents;

  svg {
    ${({ customFill, fill }) =>
      !customFill &&
      `
        fill: ${fill} !important;
      `}
    stroke: none !important;
  }

  svg > * {
    ${({ customFill }) =>
      !customFill &&
      `
        fill: inherit !important
    `}
    stroke: inherit !important;
  }
`;

export const BaseIcon: ComponentWithChildren<IconProps> = ({
  children,
  size = "md",
  color,
  customFill = false,
  className,
  viewboxSize = "xl",
  ...rest
}) => {
  const {
    activeColorScheme: { colors: activeColors },
  } = useTheme();

  return (
    <SVGWrapper
      className={className}
      customFill={customFill}
      fill={color ? activeColors[color] : "currentColor"}
    >
      <svg
        fill="none"
        height={IconSize[size]}
        viewBox={`0 0 ${IconSize[viewboxSize]} ${IconSize[viewboxSize]}`}
        width={IconSize[size]}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {children}
      </svg>
    </SVGWrapper>
  );
};
