import { useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";

type PushUrlArgs = <T>(url: string, state?: T) => void;

export const useRouter = () => {
  const navigate = useNavigate();

  const pushUrl: PushUrlArgs = useCallback(
    (url, state) => {
      navigate(url, { state });
      window.scrollTo(0, 0);
    },
    [navigate]
  );

  const replaceUrl = useCallback(
    (url: string) => navigate(url, { replace: true }),
    [navigate]
  );

  return { pushUrl, replaceUrl };
};

export const usePathname = () => {
  const { pathname } = useLocation();

  return pathname;
};
