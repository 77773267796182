import { doDelete, doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from '../../../reduxStore';
import { DeployResponse, ValidateResponse } from './types';

export const getDeployment = (deploymentId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/deployments/${deploymentId}`,
    LOGGER_TYPE.DEPLOYMENT,
  );
};

export const getLatestDeployment = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/deployments/latest`,
    LOGGER_TYPE.DEPLOYMENT,
  );
};

export const deleteDeployment = (deploymentId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doDelete(
    `${accountId}/${projectId}/deployments/${deploymentId}`,
    LOGGER_TYPE.DEPLOYMENT,
  );
};

export const validate = ({ version }: { version: string }) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPost<ValidateResponse>(
    `${accountId}/${projectId}/validate`,
    LOGGER_TYPE.DEPLOYMENT,
    {
      version,
    },
  );
};

export const deployMain = ({ version }: { version: string }) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPost<DeployResponse>(
    `${accountId}/${projectId}/deploy/main`,
    LOGGER_TYPE.DEPLOYMENT,
    {
      version,
    },
  );
};

export const deployDraft = ({
  version,
  overrideConflicts = false,
}: {
  version: string;
  overrideConflicts: boolean;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPost<DeployResponse>(
    `${accountId}/${projectId}/deploy/draft`,
    LOGGER_TYPE.DEPLOYMENT,
    {
      version,
      merge_override: overrideConflicts,
    },
  );
};

export const publish = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPost<DeployResponse>(
    `${accountId}/${projectId}/publish`,
    LOGGER_TYPE.DEPLOYMENT,
    {
      version,
    },
  );
};
