import { doDelete, doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { VariantResponse, VariantsResponse, VariantType } from './types';

export const getVariants = (accountId: string, projectId: string) => {
  return doGet<VariantsResponse>(
    `${accountId}/${projectId}/variants`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const createVariant = ({
  name,
  sourceVariant,
}: {
  name: string;
  sourceVariant: string;
}) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPost<VariantType>(
    `${accountId}/${projectId}/variants`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      name: name,
      source_variant_id: sourceVariant,
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
    },
  );
};

export const updateFlowsVariants = (
  variants: string[],
  flows: { active: string[]; inactive: string[] },
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/variants`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      account_id: accountId,
      project_id: projectId,
      version: gitVersion,
      variants: variants,
      flows: flows,
    },
  );
};

export const getVariant = (variantId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doGet(
    `${accountId}/${projectId}/variants/${variantId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
  );
};

export const updateVariant = (variantId: string, data: VariantResponse) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const gitVersion = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/variants/${variantId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    {
      variant: {
        ...data,
      },
      version: gitVersion,
    },
  );
};

export const deleteVariant = (variantId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doDelete(
    `${accountId}/${projectId}/variants/${variantId}`,
    LOGGER_TYPE.PROJECT_SETTINGS,
    { version },
  );
};
