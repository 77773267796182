/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const InformationIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path
        d="M16 26c-5.523 0-10-4.477-10-10S10.477 6 16 6s10 4.477 10 10-4.477 10-10 10Zm0 2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
        fill="#D9D9EE"
      />
      <path
        d="M26 16c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10Z"
        fill="#3C6BC1"
      />
      <path
        d="M17.25 11.25a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0ZM14 14a1 1 0 1 0 0 2h1v4h-2a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-2v-5a1 1 0 0 0-1-1h-2Z"
        fill="#fff"
      />
    </BaseIcon>
  );
};
