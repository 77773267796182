import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const DeploymentsIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M19 19V25H25V19H19ZM14 17H6C5.44772 17 5 17.4477 5 18V26C5 26.5523 5.44772 27 6 27H14C14.5523 27 15 26.5523 15 26V18C15 17.4477 14.5523 17 14 17ZM17 18C17 17.4477 17.4477 17 18 17H26C26.5523 17 27 17.4477 27 18V26C27 26.5523 26.5523 27 26 27H18C17.4477 27 17 26.5523 17 26V18ZM7 19H13V25H7V19ZM7 7H13V13H7V7ZM6 5C5.44772 5 5 5.44772 5 6V14C5 14.5523 5.44772 15 6 15H14C14.5523 15 15 14.5523 15 14V6C15 5.44772 14.5523 5 14 5H6Z" />
    </BaseIcon>
  );
};
