/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ContainmentIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="m13.646 15.646-3.792-3.792a.5.5 0 0 0-.854.353V15H5a1 1 0 1 0 0 2h4v2.793a.5.5 0 0 0 .854.353l3.792-3.792a.5.5 0 0 0 0-.708Zm4.708 0 3.792-3.792a.5.5 0 0 1 .854.353V15h4a1 1 0 1 1 0 2h-4v2.793a.5.5 0 0 1-.854.353l-3.792-3.792a.5.5 0 0 1 0-.708ZM17 5a1 1 0 1 0-2 0v4h-2.793a.5.5 0 0 0-.353.854l3.792 3.792a.5.5 0 0 0 .708 0l3.792-3.792A.5.5 0 0 0 19.793 9H17V5Zm-.646 13.354 3.792 3.792a.5.5 0 0 1-.353.854H17v4a1 1 0 1 1-2 0v-4h-2.793a.5.5 0 0 1-.353-.854l3.792-3.792a.5.5 0 0 1 .708 0Z" />
    </BaseIcon>
  );
};
