import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

interface VisibilityIconProps extends IconProps {
  disabled?: boolean;
}

export const VisibilityIcon: FC<VisibilityIconProps> = ({
  disabled = false,
  ...props
}) => (
  <BaseIcon {...props}>
    {disabled ? (
      <>
        <path d="M5 26.506 25.506 6l1.414 1.414-2.058 2.058C27.166 11.227 29.095 13.512 30 16c-2 5.5-9 10-14 10-1.773 0-3.922-.628-6.003-1.662L6.414 27.92 5 26.506ZM20.505 13.83a5 5 0 0 1-6.676 6.676l-2.331 2.332C13.158 23.586 14.76 24 16 24c1.97 0 4.579-.92 6.989-2.583 2.154-1.488 3.92-3.413 4.849-5.417-.864-1.864-2.453-3.66-4.405-5.099l-2.928 2.928Zm-1.566 1.566-3.543 3.544a3 3 0 0 0 3.544-3.544Zm1.46-6.289C18.817 8.385 17.275 8 16 8c-1.97 0-4.579.92-6.989 2.584-2.2 1.52-3.996 3.495-4.908 5.546.536 1.572 2.085 3.385 4.32 4.952L6.99 22.516C4.475 20.687 2.469 18.344 2 16c2-5.5 9-10 14-10 1.814 0 3.89.592 5.896 1.61L20.4 9.107Z" />
        <path d="M16 13c.16 0 .316.012.47.037l1.592-1.593a5 5 0 0 0-6.619 6.619l1.594-1.594A3 3 0 0 1 16 13Z" />
      </>
    ) : (
      <>
        <path d="M16 19a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 2a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" />
        <path d="M27.838 16c-.93 2.004-2.695 3.929-4.85 5.417C20.58 23.08 17.97 24 16 24c-1.953 0-4.811-1.03-7.373-2.777-2.345-1.6-3.972-3.474-4.524-5.093.912-2.05 2.707-4.027 4.908-5.547C11.421 8.92 14.03 8 16 8c1.97 0 4.579.92 6.989 2.583 2.154 1.488 3.92 3.413 4.849 5.417ZM16 26c5 0 12-4.5 14-10-2-5.5-9-10-14-10S4 10.5 2 16c1 5 9 10 14 10Z" />
      </>
    )}
  </BaseIcon>
);
