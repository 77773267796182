/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const OverviewAnalyticsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M15 5a1 1 0 1 1 2 0v1h10a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1h-8.238l3.878 3.232a1 1 0 0 1-1.28 1.536L16 23.302l-5.36 4.466a1 1 0 0 1-1.28-1.536L13.238 23H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h10V5ZM6 8v13h20V8H6Z" />
      <path d="M11 15a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0v-3Zm4-2a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0v-5Zm4-2a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0v-7Z" />
    </BaseIcon>
  );
};
