/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const TimeIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M17 10a1 1 0 1 0-2 0v5.586A2 2 0 0 0 15.586 17l2.707 2.707a1 1 0 0 0 1.414-1.414L17 15.586V10Z" />
      <path d="M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12Zm-2 0c0-5.523-4.477-10-10-10S6 10.477 6 16s4.477 10 10 10 10-4.477 10-10Z" />
    </BaseIcon>
  );
};
