import { ComponentWithChildren } from "@polyai/common/types/helpers";

type OldBaseIconProps = React.SVGProps<SVGSVGElement>;

export const OldBaseIcon: ComponentWithChildren<OldBaseIconProps> = ({
  children,
  ...rest
}: OldBaseIconProps) => {
  return (
    <svg
      fill={rest.fill || "none"}
      height={rest.height || "20"}
      width={rest.width || "20"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </svg>
  );
};
