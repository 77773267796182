import React from "react";

import Tooltip from "components/atoms/Tooltip";
import StickySidebar, {
  SidebarScrollableContent,
} from "components/molecules/StickySidebar";

import * as Styled from "./AccountsSidebar.styled";

export type SidebarAccount = {
  id: string;
  name: string;
  abbreviation: string;
};

export type AccountsSidebarProps = {
  accounts: SidebarAccount[];
  activeAccountId: string;
};

const AccountsSidebar: React.FC<AccountsSidebarProps> = ({
  accounts,
  activeAccountId,
}) => {
  return (
    <StickySidebar id="account-panel" isMainNavOpen={false} width={70}>
      {({ isScrolling, scrollableRef }) => (
        <SidebarScrollableContent ref={scrollableRef}>
          <Styled.AccountsWrapper>
            {accounts &&
              accounts.map((account) => {
                const isSelected = account.id === activeAccountId;

                return (
                  <Tooltip
                    key={account.id}
                    content={account.name}
                    disabled={isScrolling}
                    placement="right"
                  >
                    <Styled.AccountLink
                      $isActive={isSelected}
                      aria-label={account.name}
                      data-dd-action="Account: open"
                      data-test-id="select-account-btn"
                      title={account.name}
                      to={`/${account.id}`}
                    >
                      {account.abbreviation}
                    </Styled.AccountLink>
                  </Tooltip>
                );
              })}
          </Styled.AccountsWrapper>
        </SidebarScrollableContent>
      )}
    </StickySidebar>
  );
};

export default AccountsSidebar;
