import { ReactElement } from "react";

export enum ClientEnvironment {
  SANDBOX = "sandbox",
  LIVE = "live",
  PRE_RELEASE = "pre-release",
}

export enum UploadMethod {
  ADD = "add",
  UPDATE = "update",
  SKIP = "skip",
  REPLACE = "replace",
}

export enum PanelTitle {
  CHOOSE_FILE = "Choose file",
  CHOOSE_FILES = "Choose files",
  PREVIEW_CHANGES = "Preview changes",
  CHOOSE_ENV = "Choose environment",
  UPLOAD_FILE = "Upload file",
}

export enum FileStatus {
  DELETE = "delete",
  CREATE = "create",
  UPDATE = "update",
  NO_CHANGE = "no change",
  OVERWRITE = "overwrite",
  PRESENT = "present",
  MISSING = "missing",
  VALID = "valid",
  INVALID = "invalid",
  ERROR = "error",
  ADDED = "added",
  UPDATED = "updated",
  DELETED = "deleted",
}

export interface MenuItem {
  title: PanelTitle;
  completed?: boolean;
  warning?: boolean;
  subtitle?: ReactElement;
  isDisabled?: boolean;
}

export interface DataItem {
  title: string;
  status: FileStatus;
  notes?: string[];
}
