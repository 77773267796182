/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const MinusIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <path d="M24 15a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h16Z" />
    </BaseIcon>
  );
};
