import { doGet } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import {
  AccountAccessResponse,
  AccountData,
  AccountResponse,
  AuthoriseResponse,
} from './types';

export const getAccounts = () => {
  return doGet<AccountData[]>(`accounts`, LOGGER_TYPE.ACCOUNT);
};

export const getAccount = (accountId: string) => {
  return doGet<AccountResponse>(`accounts/${accountId}`, LOGGER_TYPE.ACCOUNT);
};

export const getAccountAccess = (accountId: string) => {
  return doGet<AccountAccessResponse>(
    `accounts/access/${accountId}`,
    LOGGER_TYPE.ACCOUNT,
  );
};

export const authoriseUser = () => {
  return doGet<AuthoriseResponse>(`authorise`, LOGGER_TYPE.ACCOUNT);
};
