import { useCallback, useMemo } from 'react';

import { jwtDecode } from 'jwt-decode';

import useAuthToken from 'hooks/useAuthToken';

export enum GLOBAL_PERMISSION {
  PLATFORM_ADMIN = 'platform_admin',
}

export const useGlobalPermissions = () => {
  const { token, isTokenLoading, isTokenError } = useAuthToken();

  const permissions: GLOBAL_PERMISSION[] = useMemo(() => {
    if (!token) {
      return [];
    }

    const decodedToken = jwtDecode(token) as any;
    if (!('permissions' in decodedToken)) {
      return [];
    }

    return decodedToken['permissions'];
  }, [token]);

  const userHasGlobalPermission = useCallback(
    (permission: GLOBAL_PERMISSION) => permissions?.includes(permission),
    [permissions],
  );

  return {
    userHasGlobalPermission,
    isPermissionsLoading: isTokenLoading,
    isPermissionsError: isTokenError,
  };
};
