import { doDelete, doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

export const lock = (
  projectId?: string,
  accountId?: string,
  version?: string,
) => {
  if (!projectId || !accountId || !version) {
    return Promise.reject(Error('api.lock: Invalid params'));
  }

  return doPost('lock', LOGGER_TYPE.LOCK, {
    account_id: accountId,
    project_id: projectId,
    version,
  });
};

export const unlock = (projectId?: string, accountId?: string) => {
  if (!projectId || !accountId) {
    return Promise.reject(Error('api.lock: Invalid params'));
  }

  return doDelete('lock', LOGGER_TYPE.LOCK, {
    account_id: accountId,
    project_id: projectId,
  });
};

export const getLockStatus = () => {
  const projectId = store.getState().projectInfo.id;
  const accountId = store.getState().accountInfo.id;

  return doGet('lock', LOGGER_TYPE.LOCK, {
    account_id: accountId,
    project_id: projectId,
  });
};
