/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ResizeIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M15 6a1 1 0 1 1 2 0v20a1 1 0 1 1-2 0V6Zm-5.293 5.293a1 1 0 0 1 0 1.414L7.414 15H12.5a1 1 0 1 1 0 2H7.414l2.293 2.293a1 1 0 0 1-1.414 1.414L4.295 16.71a.997.997 0 0 1-.002-1.416l4-4a1 1 0 0 1 1.414 0Zm12.586 0a1 1 0 0 0 0 1.414L24.586 15H19.5a1 1 0 1 0 0 2h5.086l-2.293 2.293a1 1 0 0 0 1.414 1.414l3.998-3.998.022-.022a.997.997 0 0 0-.02-1.394l-4-4a1 1 0 0 0-1.414 0Z" />
  </BaseIcon>
);
