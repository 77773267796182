/* eslint-disable max-len */
import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const UserIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0 2a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM8.161 26h15.678c-.785-4.831-4.225-8-7.839-8-3.614 0-7.053 3.169-7.839 8Zm-2.023.006C6.929 20.327 11.043 16 16 16s9.071 4.327 9.863 10.006C26.015 27.099 25.105 28 24 28H8c-1.104 0-2.015-.9-1.862-1.994Z" />
    </BaseIcon>
  );
};
