import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadType } from 'components/organisms/UploadPanel/UploadPanel.constants';

import {
  closeAllPanels,
  closeOtherPanels,
  createNewChat,
  createNewDeployment,
  createNewDraftChat,
  projectInit,
} from 'actions';

export interface ChatPanelState {
  isChatPanelOpen: boolean;
}

const initialState: ChatPanelState = {
  isChatPanelOpen: false,
};

const chatPanelSlice = createSlice({
  name: 'chatPanel',
  initialState,
  reducers: {
    openChatPanel: (state) => {
      state.isChatPanelOpen = true;
    },
    closeChatPanel: (state) => {
      state.isChatPanelOpen = false;
    },
    toggleChatPanel: (state) => {
      state.isChatPanelOpen = !state.isChatPanelOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectInit, () => {
        return { ...initialState };
      })
      .addCase(closeAllPanels, () => {
        return { ...initialState };
      })
      .addCase(
        closeOtherPanels,
        (state, data: PayloadAction<UploadType | undefined>) => {
          if (data.payload) {
            state.isChatPanelOpen = false;
          }
        },
      )
      .addCase(createNewDeployment, (state) => {
        state.isChatPanelOpen = true;
      })
      .addCase(createNewChat, (state) => {
        state.isChatPanelOpen = true;
      })
      .addCase(createNewDraftChat, (state) => {
        state.isChatPanelOpen = true;
      });
  },
});

export const { openChatPanel, closeChatPanel, toggleChatPanel } =
  chatPanelSlice.actions;

export default chatPanelSlice.reducer;
