/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const ApiIntegrationsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M6 13a4 4 0 0 1 4-4 .02.02 0 0 0 .02-.022 5.563 5.563 0 0 1-.019-.365v-.259a5.5 5.5 0 0 1 10.734-1.547A.28.28 0 0 0 21 7a5 5 0 0 1 0 10h-4v4c0 .042-.003.083-.008.124a4.007 4.007 0 0 1 2.884 2.884c.04-.005.082-.008.124-.008h7a1 1 0 1 1 0 2h-7a1.02 1.02 0 0 1-.124-.008 4.002 4.002 0 0 1-7.752 0c-.04.005-.082.008-.124.008H5a1 1 0 1 1 0-2h7c.042 0 .083.003.124.008a4.007 4.007 0 0 1 2.884-2.884A1.017 1.017 0 0 1 15 21v-4h-5a4 4 0 0 1-4-4Zm4 2h11a3 3 0 1 0 0-6 2.28 2.28 0 0 1-2.169-1.578 3.5 3.5 0 0 0-6.818 1.384A2.02 2.02 0 0 1 10 11a2 2 0 1 0 0 4Zm6 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    </BaseIcon>
  );
};
