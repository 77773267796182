import styled, { css } from "styled-components";

import { HoverButton } from "../HoverTarget";
import { IconSize } from "../Icons/BaseIcon";

export const Button = styled(HoverButton)<{
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;

export const LoadingWrapper = styled.div<{ $size: keyof typeof IconSize }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ $size }) => IconSize[$size]}px;
  height: ${({ $size }) => IconSize[$size]}px;
`;
