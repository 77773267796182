/* eslint-disable max-len */
import { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const MinimiseIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <BaseIcon {...props}>
      <path d="M27.3138 6.10066L20.536 12.8784H25.1924C25.7447 12.8784 26.1924 13.3261 26.1924 13.8784C26.1924 14.4307 25.7447 14.8784 25.1924 14.8784H18.1504C17.8849 14.8861 17.6169 14.7886 17.4143 14.5859C17.2974 14.469 17.2155 14.3304 17.1685 14.183C17.1379 14.087 17.1213 13.9846 17.1213 13.8784L17.1214 13.8715V6.80762C17.1214 6.25533 17.5691 5.80762 18.1214 5.80762C18.6736 5.80762 19.1214 6.25533 19.1214 6.80762V11.4646L25.8996 4.68645C26.2901 4.29592 26.9232 4.29592 27.3138 4.68645C27.7043 5.07697 27.7043 5.71014 27.3138 6.10066Z" />
      <path d="M14.8786 18.1214C14.8786 18.1069 14.8783 18.0925 14.8777 18.0782C14.8674 17.8367 14.77 17.5983 14.5857 17.414C14.3845 17.2128 14.119 17.1153 13.8554 17.1214L6.80758 17.1214C6.25529 17.1214 5.80758 17.5691 5.80758 18.1214C5.80758 18.6736 6.25529 19.1214 6.80758 19.1214H11.4641L4.6862 25.8993C4.29568 26.2898 4.29568 26.923 4.6862 27.3135C5.07673 27.704 5.70989 27.704 6.10042 27.3135L12.8786 20.5353L12.8786 25.1922C12.8786 25.7444 13.3263 26.1922 13.8786 26.1922C14.4309 26.1922 14.8786 25.7444 14.8786 25.1922V18.1282L14.8786 18.1214Z" />
    </BaseIcon>
  );
};
