import { MetricInputType } from '@polyai/ui/components/molecules/Filter/Filter.constants';

export interface Conversation {
  account_id: string;
  client_env: string;
  conversation_id: string;
  tags: string[];
  created_at: string;
  duration: number;
  from_number: string;
  to_number: string;
  in_progress: boolean;
  language: string;
  project_id: string;
  reviewers: string[] | null;
  tags_last_updated: string;
}

export interface Metric {
  name: string;
  type: MetricInputType;
  operators: string[];
  allowed_values?: string[];
}

export type ConversationsResponse = {
  conversations: Conversation[];
  count: number;
  metrics: Metric[];
};

export interface TranscriptDetails {
  text: string;
  intents?: string[];
}

export interface TurnMetadata {
  current_node?: string;
  intents?: string[];
  entities?: {
    name: string;
    value: string | number | (string | number)[] | Record<string, any>;
  }[];
  top_transcripts: TranscriptDetails[];
  node_trace?: string[];
}

export interface TurnReview {
  issue_type: ConversationTag;
  issue: string;
  correction?: string;
  reviewed_at: string;
  reviewer: string;
  conversation_id: string;
  turn_id: number;
}

export interface ConversationTurn {
  turn_id: number;
  user_input: string;
  translated_user_input: string;
  user_input_dtmf: string;
  user_input_dtmf_finish_key?: string;
  user_input_datetime: string;
  agent_response: string;
  english_agent_response: string;
  agent_response_datetime: string;
  metadata: TurnMetadata;
  reviews: TurnReview[];
  user_input_audio_url?: string;
  metrics?: ConversationMetric[];
}

export interface LiveConversationMetadata {
  id: string;
  from_number: string;
  timestamp_utc: string;
}

export enum ConversationTag {
  ENTITY = 'ENTITY',
  INTENT = 'INTENT',
  INTERRUPTION = 'INTERRUPTION',
  NO_ISSUE = 'NO_ISSUE',
  TRANSCRIPTION = 'TRANSCRIPTION',
  LISTENING = 'LISTENING',
  POLICY = 'POLICY',
  VOICE_ACTOR = 'VOICE_ACTOR',
  CLARITY = 'CLARITY',
  LATENCY = 'LATENCY',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
}

export interface ConversationAlert {
  message: string;
  type: 'error' | 'success';
}

export interface ConversationsRequestFilter {
  start_time?: string;
  end_time?: string;
}

export interface ConversationData {
  account_id: string;
  project_id: string;
  audio_url: string;
  client_env: string;
  conversation_id: string;
  created_at: string;
  duration: number;
  language: string;
  from_number: string;
  to_number: string;
  tags: ConversationTag[];
  tags_last_updated: string;
  turns: ConversationTurn[];
  version: string;
  variant_id: string | null;
  metrics: ConversationMetric[];
  in_progress: boolean;
}

export type CallRecordingData = Omit<ConversationData, 'turn' | 'metric'>;

export interface CallRecording
  extends Omit<
    ConversationData,
    | 'turns'
    | 'metrics'
    | 'variant_id'
    | 'version'
    | 'tags'
    | 'tags_last_updated'
  > {
  reviewed: boolean;
  call_metrics: {
    [key: string]: string;
  };
}

export interface ConversationsLogResponse {
  call_recordings: CallRecording[];
  count: number;
  limit: number;
  offset: number;
  metrics: Metric[];
  custom_headers: string[];
}

export interface CsatSummaryData {
  predicted_csat_score?: string;
  predicted_csat?: 'DISSATISFIED' | 'SATISFIED';
  dialogue_summary?: string;
  dialogue_explanation?: string;
}

export interface ConversationMetric {
  name: string;
  value: string | null;
  timestamp?: string;
  expected?: string | null;
  isMissing?: boolean;
}

export interface ConversationResponse {
  conversations: ConversationData[];
}

export interface CreateConversationResponse {
  conversation_id: string;
  version: string;
}

export interface CreateMessageResponse {
  response: string;
  conversation_ended: boolean;
  metadata: TurnMetadata;
  delayed_response: boolean;
}

export interface ConversationMetadata {
  dialogue_rating: number;
  updated_by: string;

  /**
   * Extra metadata can contain any additional information
   * However, this will require updating the API validation
   */
  extra_metadata?: { [key: string]: any };
}

export interface ConversationMetadataResponse extends ConversationMetadata {
  account_id: string;
  project_id: string;
  conversation_id: string;

  updated_at?: string;
}
