/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const FlowIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M4 2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H9v8a1 1 0 0 0 1 1h8v-3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-3h-8a3 3 0 0 1-3-3v-8H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm8 2H4v8h8V4Zm16 16h-8v8h8v-8Z" />
    </BaseIcon>
  );
};
