import styled from 'styled-components';

import LoadingIcon from '@polyai/ui/components/atoms/LoadingIcon';
import themeColors from '@polyai/ui/styles/config/colors';
import sizes from '@polyai/ui/styles/config/sizes';
import themeSpacing from '@polyai/ui/styles/config/spacing';

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const LoadingSideBar = styled.div`
  flex-shrink: 0;

  height: 100vh;
  padding: ${themeSpacing.sm3} ${themeSpacing.sm4};

  color: ${themeColors.textHelper};

  background-color: ${themeColors.backgroundInverse};
`;

export const LoadingLogo = styled.div`
  color: ${themeColors.iconBrand};
`;

export const LoadingBlockWrapper = styled.div`
  margin-top: ${themeSpacing.md1};
  margin-bottom: ${themeSpacing.lg1};

  div {
    height: 20px;
    margin-bottom: ${themeSpacing.sm1};
    opacity: 0.17;
    background-color: ${themeColors.background};
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoadingHeader = styled.div`
  display: flex;

  width: 100%;
  height: ${sizes.headerHeight}px;

  background-color: ${themeColors.background};
  box-shadow: 0 1px 1px 0 ${themeColors.shadow};
`;

export const PageLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${sizes.headerHeight}px);
`;

export const LoadingSpinner = styled(LoadingIcon)`
  margin-bottom: ${themeSpacing.sm1};
  color: ${themeColors.iconBrand};
`;
