import { ReactElement } from 'react';

import { datadogLogs, StatusType } from '@datadog/browser-logs';

import {
  ALERT_LOGGER_TYPE,
  AlertMessageType,
} from '@polyai/common/hooks/useBaseAlert';
import { getNodeText } from '@polyai/common/lib/miscHelper';

import { store } from 'reduxStore';
import { LOG_ERROR_CATEGORY, LOGGER_TYPE } from '../api/types';

enum ERROR_CATEGORY {
  NORMAL = 20,
  USER = 40,
  SYSTEM = 50,
  UI = 55,
}

const checkE = (e: string, E: ERROR_CATEGORY) => e.startsWith(E.toString());

export const getErrorCategory = (errorID?: string, statusCode?: number) => {
  if (statusCode === 403) return LOG_ERROR_CATEGORY.NORMAL;
  if (statusCode === 404) return LOG_ERROR_CATEGORY.UI;
  if (!errorID) return LOG_ERROR_CATEGORY.UNDEFINED;

  if (checkE(errorID, ERROR_CATEGORY.NORMAL)) return LOG_ERROR_CATEGORY.NORMAL;
  if (checkE(errorID, ERROR_CATEGORY.USER)) return LOG_ERROR_CATEGORY.USER;
  if (checkE(errorID, ERROR_CATEGORY.SYSTEM)) return LOG_ERROR_CATEGORY.SYSTEM;
  if (checkE(errorID, ERROR_CATEGORY.UI)) return LOG_ERROR_CATEGORY.UI;

  return LOG_ERROR_CATEGORY.UNDEFINED;
};

interface Message {
  props: {
    title: string;
    children: string | Message | string[];
  };
}

/* Get the text from an alert message component.*/
export const getAlertMessage = (
  message: ReactElement<AlertMessageType>,
): { innerText: string; title: string } => {
  const { props } = message.valueOf() as Message;

  return {
    innerText: getNodeText(message),
    title: props.title,
  };
};

export const logToDataDog = (
  loggerType: ALERT_LOGGER_TYPE | LOGGER_TYPE,
  statusType: StatusType,
  message: string,
  body: Record<string, any>,
) => {
  const ddLogger = datadogLogs.getLogger(loggerType);
  if (!!ddLogger) {
    // We always want to log these things
    const project_id = store.getState().projectInfo.id || '*';
    const account_id = store.getState().accountInfo.id || '*';
    const user = store.getState().auth.user || {};

    const logBody = {
      project_id,
      account_id,
      user_email: user.email || 'Unknown user',
      ...body,
    };

    switch (statusType) {
      case 'error':
        return ddLogger.error(message, logBody);
      case 'warn':
        return ddLogger.warn(message, logBody);
      case 'debug':
        return ddLogger.debug(message, logBody);
      case 'info':
      default:
        return ddLogger.info(message, logBody);
    }
  }
};
