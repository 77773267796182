/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const NodeEndIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M24.996 6.632C23.596 5.832 20.191 4.5 16 4.5c-4.191 0-7.596 1.331-8.996 2.132-.98.56-1.72 1.505-2.21 2.448C4.304 10.024 4 11.09 4 12v.056c0 .57 0 1.137.074 1.621.081.532.266 1.077.719 1.53.224.224.52.348.696.416.217.082.467.155.727.217.523.127 1.159.233 1.795.303.635.07 1.3.107 1.88.086a5.6 5.6 0 0 0 .84-.086c.242-.047.553-.13.824-.31 1.085-.724 1.279-1.886 1.397-2.59l.017-.101c.142-.83.242-1.103.545-1.284.521-.312.947-.358 2.486-.358 1.539 0 1.965.046 2.486.358.303.181.403.453.545 1.284l.017.101c.118.704.312 1.866 1.397 2.59.27.18.582.263.825.31.262.05.55.076.84.086.58.021 1.245-.016 1.88-.086.635-.07 1.27-.176 1.794-.303.26-.063.51-.135.727-.217.177-.068.472-.192.696-.416.453-.453.638-.998.719-1.53.074-.484.074-1.05.074-1.621V12c0-.91-.304-1.976-.794-2.92-.49-.943-1.23-1.888-2.21-2.448Zm-5.482 3.51C18.448 9.504 17.46 9.5 16 9.5c-1.461 0-2.448.004-3.514.642-1.155.692-1.356 1.877-1.476 2.59l-.013.073c-.14.82-.232 1.135-.525 1.345a.95.95 0 0 1-.12.029 3.641 3.641 0 0 1-.534.052 11.418 11.418 0 0 1-1.588-.076c-.57-.063-1.12-.156-1.544-.259a4.21 4.21 0 0 1-.523-.158c-.039-.059-.081-.161-.112-.364C6.003 13.058 6 12.645 6 12c0-.514.188-1.264.569-1.998.381-.734.89-1.327 1.427-1.634C9.126 7.723 12.191 6.5 16 6.5c3.809 0 6.874 1.223 8.004 1.868.536.307 1.046.9 1.427 1.634.381.734.569 1.484.569 1.998 0 .645-.003 1.058-.051 1.374-.031.203-.073.305-.112.364l-.038.016c-.11.042-.272.09-.485.142-.424.103-.973.196-1.544.259a11.42 11.42 0 0 1-1.588.076 3.641 3.641 0 0 1-.535-.052.95.95 0 0 1-.119-.03c-.293-.209-.385-.524-.525-1.344l-.012-.072c-.121-.714-.322-1.899-1.477-2.591Z" />
      <path d="M17 17a1 1 0 1 0-2 0v7.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L17 24.586V17Z" />
    </BaseIcon>
  );
};
