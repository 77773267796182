/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CheckIcon: FC<IconProps> = ({ ...props }) => (
  <BaseIcon {...props}>
    <path d="M26.707 8.293a1 1 0 0 1 0 1.414l-13.5 13.5a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414l5.293 5.293L25.293 8.293a1 1 0 0 1 1.414 0Z" />
  </BaseIcon>
);
