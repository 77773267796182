import { useEffect } from "react";

const useLockScroll = (shouldLock: boolean = true) => {
  useEffect(() => {
    if (shouldLock) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [shouldLock]);
};

export default useLockScroll;
