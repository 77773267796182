import { ComponentProps } from "react";

import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import { IconSize } from "../Icons/BaseIcon";
import { KeyboardSafeInput, KeyboardSafeTextArea } from "../KeyboardSafeInputs";
import LoadingIcon from "../LoadingIcon";
import { CaptionStrong } from "../Text";

export const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const LabelWithHelp = styled.div`
  display: flex;
  border-bottom: 1px dashed ${themeColors.borderUnderline};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BaseInputStyles = css`
  font-size: ${({ theme }) => theme.text.size.body.body3};
  font-weight: ${({ theme }) => theme.text.weight.semibold};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
`;

export const SharedInputStyles = css`
  padding: ${themeSpacing.xs3} ${themeSpacing.sm1};
  ${BaseInputStyles}
`;

type InputStylesProps = {
  $fullWidth?: boolean;
  $autoExpand?: boolean;
  $focus?: boolean;
  $withError?: boolean;
  $hasIcon?: boolean;
  $hintWidth?: number;
  placeholder?: ComponentProps<typeof KeyboardSafeInput>["placeholder"];
  value?: ComponentProps<typeof KeyboardSafeInput>["value"];
};

export const InputStyles = css<InputStylesProps>`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  border: 1px solid ${themeColors.borderStrong};
  border-radius: ${themeSpacing.radiusSmall};

  &:focus {
    border-color: ${({ $withError }) =>
      $withError ? themeColors.borderDanger : themeColors.borderPrimary};
  }

  &:disabled {
    color: ${themeColors.textDisabled};
    background-color: ${themeColors.backgroundPage};
  }

  ::placeholder {
    color: ${themeColors.textPlaceholder};
    opacity: 1; /* Firefox */
  }

  ${({ placeholder, value, $autoExpand }) =>
    !value &&
    placeholder &&
    $autoExpand &&
    css`
      @supports not (-moz-appearance: none) {
        padding-bottom: 0;

        ::placeholder {
          padding-bottom: ${themeSpacing.xs3};
        }
      }
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  ${({ $autoExpand }) =>
    $autoExpand &&
    css`
      overflow: hidden;
      width: 100%;
      margin-top: ${themeSpacing.xs1};
    `}

  ${({ $focus }) =>
    $focus &&
    css`
      border: 1px solid ${themeColors.borderBrand};
    `}

  ${({ $withError }) =>
    $withError &&
    css`
      border-color: ${themeColors.borderDanger};
    `}

  ${({ $hasIcon }) =>
    $hasIcon &&
    css`
      padding-left: ${IconSize.md * 2}px;
    `}
  
  ${({ $hintWidth }) =>
    $hintWidth &&
    css`
      padding-right: ${$hintWidth + 32}px;
    `}
`;

export const TextArea = styled(KeyboardSafeTextArea)<InputStylesProps>`
  ${SharedInputStyles}
  ${InputStyles}
  field-sizing: content;
`;

export const Input = styled(KeyboardSafeInput)<InputStylesProps>`
  ${SharedInputStyles}
  ${InputStyles}
`;

export const InputContainer = styled.div<{ $fullWidth?: boolean }>`
  position: relative;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const AutoExpandInputWrapper = styled.div`
  display: inline-grid;
  align-items: center;
  vertical-align: top;

  &::after,
  ${InputContainer} {
    resize: none;
    grid-area: 1 / 1;
    width: auto;
    min-width: 1em;
  }

  &::after {
    ${BaseInputStyles}
    content: attr(data-value);
    padding: 0 ${themeSpacing.sm1};
    white-space: nowrap;
    visibility: hidden;
  }
`;

export const LoadingSpinner = styled(LoadingIcon)`
  position: absolute;
  right: ${themeSpacing.sm1};
  bottom: 50%;
  transform: translate(0, 50%);
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: ${themeSpacing.xs3};
  transform: translate(0, 50%);

  display: flex;
`;

export const HintWrapper = styled.div`
  position: absolute;
  right: ${themeSpacing.sm1};
  bottom: 50%;
  transform: translate(0, 50%);

  display: flex;

  width: fit-content;
`;

export const ErrorCaption = styled(CaptionStrong)`
  margin-top: ${themeSpacing.xs2};
  color: ${themeColors.errorText01};
`;

export const MaxLengthCaption = styled(CaptionStrong)<{ $withError?: boolean }>`
  margin-top: ${themeSpacing.xs1};
  color: ${themeColors.textHelper};
  text-align: right;

  ${({ $withError }) =>
    $withError &&
    css`
      color: ${themeColors.errorText01};
    `}
`;
