import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import useRequiredParams from '@polyai/common/hooks/useRequiredParams';
import { usePathname, useRouter } from '@polyai/common/hooks/useRouter';

import api from 'api';
import { AccountData } from 'api/resources/account/types';
import { setAccountId, setAccounts } from 'ducks/accountInfo/accountInfoSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

const useAccounts = () => {
  const { accountId } = useRequiredParams<{ accountId: string }>();
  const { accounts } = useAppSelector((state) => state.accountInfo);

  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const { replaceUrl } = useRouter();

  useQuery<AccountData[], Error>(['accounts'], () => api.getAccounts(), {
    retry: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    onSuccess: (accounts) => {
      let reorderedAccounts = accounts;
      const accountPlatform = accounts.find(
        (account) => account.account_id === 'PLATFORM',
      );
      if (accountPlatform) {
        reorderedAccounts = [
          accountPlatform,
          ...reorderedAccounts.filter(
            (account) => account.account_id !== 'PLATFORM',
          ),
        ];
      }
      if (accounts.length && !accountId && pathname === '/')
        replaceUrl(`/${reorderedAccounts[0]['account_id']}`);

      dispatch(setAccounts(reorderedAccounts));
    },
  });

  useEffect(() => {
    if (accountId) {
      dispatch(setAccountId(accountId));
    }
  }, [accountId, dispatch]);

  return { accounts };
};

export default useAccounts;
