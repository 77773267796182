import { useCallback } from "react";

import { matchPath } from "react-router-dom";

import { usePathname } from "./useRouter";

const useMatchPath = () => {
  const pathname = usePathname();

  return useCallback(
    (expectedRoute: string, exact: boolean = true) =>
      matchPath({ path: expectedRoute, end: exact }, pathname),
    [pathname]
  );
};

export default useMatchPath;
