/* eslint-disable max-len */
import React from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const CloseCircleIcon: React.FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M16 24a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm-3.293-11.293a1 1 0 0 1 1.414 0l1.793 1.793 1.793-1.793a1 1 0 0 1 1.414 1.414l-1.793 1.793 1.743 1.743a1 1 0 0 1-1.414 1.414l-1.743-1.743-1.742 1.743a1 1 0 0 1-1.415-1.414l1.743-1.743-1.793-1.793a1 1 0 0 1 0-1.414Z" />
    </BaseIcon>
  );
};
