import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AlertMessage } from '@polyai/ui/components/atoms/Alert/AlertMessage';

import api from 'api';
import { FeaturesResponse } from 'api/resources/features';
import { useAlert } from '../useAlert';
import { useIsFeatureOn } from './useIsFeatureOn';

const DEFAULT_FEATURES = [] as FeaturesResponse;

export const useFeatureSwitch = () => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { isFeatureOn, features, isLoadingFeatures } = useIsFeatureOn();

  const { mutate: createFeature, isLoading: isLoadingCreate } = useMutation(
    api.createFeature,
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['features']);
        queryClient.invalidateQueries(['enabledFeature']);
      },
      onError: (error: Error, { name }) => {
        let message = error.message;
        if (features?.some((feature) => feature.name === name)) {
          message = `Feature named "${name}" already exists.`;
        }
        alert.error(
          <AlertMessage title="Failed to create new feature">
            {message}
          </AlertMessage>,
        );
      },
    },
  );

  const { mutate: updateFeature, isLoading: isLoadingUpdate } = useMutation(
    api.updateFeature,
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['features']);
        queryClient.invalidateQueries(['enabledFeature']);
      },
      onError: (error: Error) => {
        alert.error(
          <AlertMessage title="Failed to update feature">
            {error.message}
          </AlertMessage>,
        );
      },
    },
  );

  const { mutate: deleteFeature, isLoading: isLoadingDelete } = useMutation(
    api.deleteFeature,
    {
      retry: false,
      onSuccess: (_, { name }) => {
        queryClient.setQueryData(
          ['features'],
          features?.filter((feature) => feature.name !== name),
        );
        queryClient.invalidateQueries(['enabledFeature']);
      },
      onError: (error: Error) => {
        alert.error(
          <AlertMessage title="Failed to update feature">
            {error.message}
          </AlertMessage>,
        );
      },
    },
  );

  return {
    features: features ?? DEFAULT_FEATURES,
    isLoadingFeatures,
    createFeature,
    updateFeature,
    deleteFeature,
    isFeatureOn,
    isLoadingCreate,
    isLoadingUpdate,
    isLoadingDelete,
  };
};
