import { Logo } from '@polyai/ui/components/atoms/Logo';
import sizes from '@polyai/ui/styles/config/sizes';

import * as Styled from './ProjectLoading.styled';

const ProjectLoading = ({ loadingText }: { loadingText: string }) => {
  return (
    <Styled.LoadingWrapper>
      <Styled.LoadingSideBar
        style={{
          width: sizes.mainNavWidth,
        }}
      >
        <Styled.LoadingLogo>
          <Logo dotsColor="textPrimaryInverse" />
        </Styled.LoadingLogo>
        <Styled.LoadingBlockWrapper>
          <div style={{ width: 70 }} />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Styled.LoadingBlockWrapper>
        <Styled.LoadingBlockWrapper>
          <div style={{ width: 70 }} />
          <div />
          <div />
        </Styled.LoadingBlockWrapper>
        <Styled.LoadingBlockWrapper>
          <div style={{ width: 70 }} />
          <div />
          <div />
          <div />
        </Styled.LoadingBlockWrapper>
      </Styled.LoadingSideBar>
      <Styled.LoadingContent>
        <Styled.LoadingHeader />
        <Styled.PageLoader>
          <Styled.LoadingSpinner size={40} strokeWidth={3} />
          <div>Hang tight!</div>
          {loadingText}
        </Styled.PageLoader>
      </Styled.LoadingContent>
    </Styled.LoadingWrapper>
  );
};

export default ProjectLoading;
