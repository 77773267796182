import { CSSProperties, useCallback, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Auth0Wrapper } from 'Auth0Wrapper';
import dynamic from 'next/dynamic';
import { positions, Provider as AlertProvider } from 'react-alert';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { extractDomainFromEmail } from '@polyai/common/lib/emailHelper';
import debounce from '@polyai/common/utils/debounce';
import { Alert, ALERT_TIMEOUT } from '@polyai/ui/components/atoms/Alert';
import Layout from '@polyai/ui/components/atoms/Layout';
import { Loader } from '@polyai/ui/components/atoms/Loader';
import ScreenContentWrapper from '@polyai/ui/components/atoms/ScreenContentWrapper';
import sizes from '@polyai/ui/styles/config/sizes';
import { spacing } from '@polyai/ui/styles/config/spacing';

import ProjectLoading from 'components/atoms/ProjectLoading';
import LandingLayout from 'components/organisms/LandingLayout';
import { UploadType } from 'components/organisms/UploadPanel/UploadPanel.constants';

import { closeOtherPanels } from 'actions';
import queryClient from 'api/queryClient';
import { LOGGER_TYPE } from 'api/types';
import { Auth } from 'auth';
import MasterProvider from 'contexts';
import { closeMainNav, openMainNav } from 'ducks/layout/layoutSlice';
import { saveTableState } from 'ducks/table/persist';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { store } from 'reduxStore';
import PrivatePage from 'routes/PrivatePage';
import ErrorPage, { ErrorStatus } from 'screens/ErrorPage';
import GlobalStyle from '../styles/global';
import { defaultTheme } from '../styles/theme';

import 'wipe.css';

const BattlebotsScreen = dynamic(() => import('screens/BattlebotsScreen'), {
  ssr: false,
});
const CallbackPage = dynamic(() => import('screens/CallbackScreen'), {
  ssr: false,
});
const ChatReportsScreen = dynamic(() => import('screens/ChatReportsScreen'), {
  ssr: false,
});
const FeatureSwitchScreen = dynamic(
  () => import('screens/FeatureSwitchScreen'),
  { ssr: false },
);
const ProjectNavigation = dynamic(
  () => import('components/organisms/ProjectNavigation'),
  { ssr: false },
);
const ProjectPage = dynamic(() => import('./ProjectPage'), { ssr: false });
const UserSettingsScreen = dynamic(() => import('screens/UserSettingsScreen'), {
  ssr: false,
});
const WidgetDemoScreen = dynamic(() => import('screens/WidgetDemoScreen'), {
  ssr: false,
});

console.log('PolyAI environment:', process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT);

declare global {
  interface Window {
    Cypress: any;
  }
}

store.subscribe(
  debounce(() => {
    saveTableState(store.getState().table);
  }, 1000),
);

const App = () => {
  const isAuthorised = useAppSelector((state) => state.auth.isAuthorised);
  const showUnauthorised = useAppSelector(
    (state) => state.auth.showUnauthorised,
  );
  const { user } = useAuth0();

  const isChatPanelOpen = useAppSelector(
    (state) => state.chatPanel.isChatPanelOpen,
  );

  const openUploadPanels = useAppSelector(
    (state) => state.uploadPanel.openUploadPanels,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isChatPanelOpen) {
      dispatch(closeOtherPanels());
    }
  }, [dispatch, isChatPanelOpen]);

  useEffect(() => {
    if (openUploadPanels.audio) {
      dispatch(closeOtherPanels(UploadType.AUDIO));
    }
  }, [dispatch, openUploadPanels.audio]);

  useEffect(() => {
    if (openUploadPanels.data_store) {
      dispatch(closeOtherPanels(UploadType.DATA_STORE));
    }
  }, [dispatch, openUploadPanels.data_store]);

  useEffect(() => {
    if (openUploadPanels.intents) {
      dispatch(closeOtherPanels(UploadType.INTENTS));
    }
  }, [dispatch, openUploadPanels.intents]);

  useEffect(() => {
    if (openUploadPanels.scenarios) {
      dispatch(closeOtherPanels(UploadType.SCENARIOS));
    }
  }, [dispatch, openUploadPanels.scenarios]);

  useEffect(() => {
    if (
      openUploadPanels.intents ||
      openUploadPanels.data_store ||
      openUploadPanels.scenarios
    ) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [
    openUploadPanels.intents,
    openUploadPanels.data_store,
    openUploadPanels.scenarios,
  ]);

  useEffect(() => {
    const region = process.env.NEXT_PUBLIC_POLYAI_REGION;
    if (!window.parent.Cypress) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        env: process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT!,
        version: process.env.NEXT_PUBLIC_APP_VERSION!,
        site: region === 'us-1' ? 'datadoghq.com' : 'datadoghq.eu',
        service: 'portal-ui',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        actionNameAttribute: 'data-dd-action',
        defaultPrivacyLevel: 'allow',
        trackViewsManually: true,
      });

      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_LOGS_CLIENT_TOKEN!,
        version: process.env.NEXT_PUBLIC_APP_VERSION!,
        site: region === 'us-1' ? 'datadoghq.com' : 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: 'portal-ui',
      });

      for (const logger of Object.values(LOGGER_TYPE)) {
        datadogLogs.createLogger(logger, {
          level: 'info',
          handler: 'http',
          context: {
            env: process.env.NEXT_PUBLIC_POLYAI_ENVIRONMENT!,
          },
        });
      }

      datadogRum.startSessionReplayRecording();
    }
  }, []);

  useEffect(() => {
    datadogRum.setUser({
      name: user?.name,
      email: user?.email,
      email_domain: extractDomainFromEmail(user?.email),
    });
  }, [user]);

  if (isAuthorised) {
    return (
      <MasterProvider>
        <Routes>
          <Route element={<CallbackPage />} path="/callback" />
          <Route
            element={
              <PrivatePage>
                <LandingLayout />
              </PrivatePage>
            }
          >
            <Route element={<ProjectNavigation />} path="/:accountId?" />
            <Route element={<UserSettingsScreen />} path="/settings/user" />
          </Route>
          <Route element={<FeatureSwitchScreen />} path="/settings/features" />
          <Route
            element={<WidgetDemoScreen />}
            path="/:accountId/:projectId/widget"
          />
          <Route
            element={
              <ScreenContentWrapper>
                <ChatReportsScreen />
              </ScreenContentWrapper>
            }
            path="/:accountId/:projectId/chat-reports"
          />
          <Route
            element={
              <PrivatePage
                loader={
                  <ProjectLoading loadingText="We're fetching your project" />
                }
              >
                <ProjectPage />
              </PrivatePage>
            }
            path="/:accountId/:projectId/*"
          />
          <Route
            element={
              <PrivatePage
                loader={<ProjectLoading loadingText="Loading battlebots" />}
              >
                <BattlebotsScreen />
              </PrivatePage>
            }
            path="/battlebots"
          />
          <Route element={<ErrorPage type={ErrorStatus.NOT_FOUND} />} />
        </Routes>
      </MasterProvider>
    );
  }

  if (showUnauthorised) {
    return <ErrorPage type={ErrorStatus.UNAUTHORIZED} />;
  }

  return <Loader inProjectPage={false} fullScreen spinner />;
};

const AppContent = () => {
  const dispatch = useAppDispatch();
  const handleOpenMainNav = useCallback(
    () => dispatch(openMainNav()),
    [dispatch],
  );
  const handleCloseMainNav = useCallback(
    () => dispatch(closeMainNav()),
    [dispatch],
  );

  const alertContainerStyles: CSSProperties = {
    left: 'unset',
    right: 0,
    maxHeight: '100vh',
    pointerEvents: 'auto',
    overflow: 'visible',
    zIndex: 9999,
    justifyContent: 'start',
    alignItems: 'end',
    width: sizes.alertWidth,
    margin: `0 ${spacing.xs3}`,
    gap: spacing.xs2,
  };

  return (
    <Layout
      handleCloseMainNav={handleCloseMainNav}
      handleOpenMainNav={handleOpenMainNav}
    >
      <AlertProvider
        containerStyle={alertContainerStyles}
        position={positions.TOP_RIGHT}
        template={Alert}
        timeout={ALERT_TIMEOUT}
      >
        <App />
      </AlertProvider>
    </Layout>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyle />
          <Auth0Wrapper>
            <Auth>
              <QueryClientProvider client={queryClient}>
                <AppContent />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Auth>
          </Auth0Wrapper>
        </ThemeProvider>
      </Provider>
    </Router>
  );
};

export default AppWrapper;
