import React from "react";

import LoadingIcon from "../LoadingIcon";
import { InputText } from "../Text";

import * as Styled from "./Loader.styled";

export interface LoaderProps {
  spinner?: boolean;
  fullScreen?: boolean;
  inProjectPage?: boolean;
  spinnerText?: string;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  spinner = false,
  fullScreen = false,
  inProjectPage = true,
  spinnerText = "Loading",
  className,
}) => (
  <Styled.Loader
    $fullScreen={fullScreen}
    $inProjectPage={inProjectPage}
    $spinner={spinner}
    className={className}
  >
    {spinner ? (
      <>
        <LoadingIcon />
        <InputText>{spinnerText}</InputText>
      </>
    ) : (
      `Loading...`
    )}
  </Styled.Loader>
);
