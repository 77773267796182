import { useEffect } from "react";

import useIsomorphicLayoutEffect from "@polyai/common/hooks/useIsomorphicLayoutEffect";
import { ComponentWithChildren } from "@polyai/common/types/helpers";

import breakpoints from "styles/config/breakpoints";

interface LayoutProps {
  handleCloseMainNav: () => void;
  handleOpenMainNav: () => void;
}

const Layout: ComponentWithChildren<LayoutProps> = ({
  handleCloseMainNav,
  handleOpenMainNav,
  children,
}) => {
  useIsomorphicLayoutEffect(() => {
    if (window.innerWidth < breakpoints.sm) {
      handleCloseMainNav();
    }
  }, []);

  useEffect(() => {
    let width = window.innerWidth;
    const handleWindowResize = () => {
      const newWidth = window.innerWidth;
      if (width !== newWidth) {
        if (newWidth < breakpoints.sm) {
          handleCloseMainNav();
        } else {
          handleOpenMainNav();
        }
      }
      width = newWidth;
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleCloseMainNav, handleOpenMainNav]);

  return <>{children}</>;
};

export default Layout;
