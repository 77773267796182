import { ValueType } from 'rsuite/esm/DateRangePicker';

import {
  LuzmoAuthToken,
  LuzmoDashboard,
} from '@polyai/common/api/resources/luzmo/types';

import { doGet, doPost, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';

type LuzmoItemParameters = {
  dataset_id: string;
  column_id: string;
};
export type LuzmoQueryFilter = {
  expression: string;
  parameters: [LuzmoItemParameters, any];
};

export type CategoryName = string;
export type OutcomeName = string;

type CallCount = number;
export enum CallCountDeltaStatus {
  DECREASE = -1,
  UNCHANGED = 0,
  INCREASE = 1,
} // exact call count delta not necessary

type AverageDurationSeconds = number;
type TotalDurationMinutes = number;
type SuccessMetric = number;
type Contained = number;
export type KPIAggregationRow = [
  CallCount,
  AverageDurationSeconds,
  TotalDurationMinutes,
  SuccessMetric | Contained,
];

export type CategoryKPIAggregationRow = KPIAggregationRow;
export type OutcomeKPIAggregationRow = KPIAggregationRow;
export type CategoryKPIAggregationsSplitByOutcome = {
  [outcome: OutcomeName]: OutcomeKPIAggregationRow;
};

export type KPIName =
  | 'Total calls'
  | 'Avg handle time'
  | 'Total live minutes'
  | 'Contained'
  | string;
type KPIAggregation = number;
type KPIPerformanceDelta = number;

export type KPIDataRow = [KPIName, KPIAggregation, KPIPerformanceDelta];
export type CategoryDataRow = [
  CategoryName,
  CategoryKPIAggregationRow,
  CategoryKPIAggregationsSplitByOutcome,
  CallCountDeltaStatus,
];
type OutcomeDataRow = [OutcomeName, CallCount, CallCountDeltaStatus];

export type CallFlowsData = {
  kpis: KPIDataRow[];
  categories: CategoryDataRow[];
  outcomes: OutcomeDataRow[];
};

export type AnalyticsIntegration = {
  id: string;
  dashboards: {
    call_flows: {
      enabled: boolean; // the db is correctly configured to show the dashboard
      hidden: boolean; // toggled by the user to override enabled flag and hide
    };
  };
  embed_dashboards: LuzmoDashboard[];
  filter_by_project?: boolean;
};

export const getLuzmoAuthToken = (
  integrationId: string,
  suborganisation: string = 'PolyAI',
  theme?: { [style: string]: any },
  css: string = '',
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  return doPost<LuzmoAuthToken>(
    `${accountId}/${projectId}/cumulio/auth`,
    LOGGER_TYPE.ANALYTICS,
    {
      integration_id: integrationId,
      suborganisation,
      theme,
      css,
      metadata: { account_id: accountId, project_id: projectId },
    },
  );
};

export const getIntegration = (projectId: string) => {
  const accountId = store.getState().accountInfo.id;

  return doGet<AnalyticsIntegration>(
    `${accountId}/${projectId}/cumulio/integrations`,
    LOGGER_TYPE.ANALYTICS,
  );
};

export const createIntegration = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPost(
    `${accountId}/${projectId}/cumulio/integrations`,
    LOGGER_TYPE.ANALYTICS,
    { version },
  );
};

export const updateIntegration = (integrationId: string) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/cumulio/integrations`,
    LOGGER_TYPE.ANALYTICS,
    { integration_id: integrationId, version },
  );
};

export const updateIntegrationConfig = (
  integrationId: string,
  filterByProject?: boolean,
  hideCallFlows?: boolean,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/cumulio/integrations/${integrationId}`,
    LOGGER_TYPE.ANALYTICS,
    {
      filter_by_project: filterByProject,
      hide_call_flows: hideCallFlows,
      version,
    },
  );
};

export const deleteIntegration = () => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;
  const version = store.getState().version.currentPolicyVersion;

  return doPut(
    `${accountId}/${projectId}/cumulio/integrations`,
    LOGGER_TYPE.ANALYTICS,
    { version },
  );
};

export const getCallFlowsData = (
  dateRange: ValueType | null,
  timezone: string,
  filterByProject: boolean,
) => {
  const accountId = store.getState().accountInfo.id;
  const projectId = store.getState().projectInfo.id;

  const dateRangeParams = dateRange
    ? { start: dateRange[0], end: dateRange[1] }
    : {};

  return doPost<CallFlowsData>(
    `${accountId}/${projectId}/cumulio/data/call-flows`,
    LOGGER_TYPE.ANALYTICS,
    {
      ...dateRangeParams,
      timezone,
      filter_by_project: filterByProject,
    },
  );
};
