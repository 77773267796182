import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  XYPosition,
} from 'reactflow';

interface CanvasState {
  isFlowNavVisible: boolean;
  canvasNodes: Node[];
  canvasEdges: Edge[];
  shouldAutoLayout: boolean;
  chartHasBeenLaidOut: boolean;
  isInitialFlowRenderComplete: boolean;
  isHeaderMinimized: boolean;
}

const initialState: CanvasState = {
  isFlowNavVisible: true,
  canvasNodes: [],
  canvasEdges: [],
  shouldAutoLayout: false,
  chartHasBeenLaidOut: false,
  isInitialFlowRenderComplete: false,
  isHeaderMinimized: false,
};

const canvasSlice = createSlice({
  name: 'canvasSlice',
  initialState,
  reducers: {
    toggleFlowNavVisible: (state) => {
      state.isFlowNavVisible = !state.isFlowNavVisible;
    },
    updateGraph: (
      state,
      {
        payload: { nodes, edges },
      }: PayloadAction<{ nodes?: Node[]; edges?: Edge[] }>,
    ) => {
      if (nodes) {
        state.canvasNodes = nodes;
      }
      if (edges) {
        state.canvasEdges = edges;
      }
    },
    clearGraph: (state) => {
      state.canvasNodes = [];
      state.canvasEdges = [];
    },
    addCanvasNodes: (state, { payload: nodes }: PayloadAction<Node[]>) => {
      state.canvasNodes.push(...nodes);
    },
    moveCanvasNode: (
      state,
      {
        payload: { id, position },
      }: PayloadAction<{ id: string; position: XYPosition }>,
    ) => {
      const node = state.canvasNodes.find((n) => n.id === id);
      if (node) {
        node.position = position;
      }
    },
    onNodesChange: (
      state,
      { payload: changes }: PayloadAction<NodeChange[]>,
    ) => {
      state.canvasNodes = applyNodeChanges(changes, state.canvasNodes);
    },
    onEdgesChange: (
      state,
      { payload: changes }: PayloadAction<EdgeChange[]>,
    ) => {
      state.canvasEdges = applyEdgeChanges(changes, state.canvasEdges);
    },
    setSelectedNodes: (
      state,
      { payload: nodeIds }: PayloadAction<string[]>,
    ) => {
      state.canvasNodes.forEach(
        (node) => (node.selected = nodeIds.includes(node.id)),
      );
    },
    setShouldAutoLayout: (
      state,
      { payload: shouldAutoLayout }: PayloadAction<boolean>,
    ) => {
      state.shouldAutoLayout = shouldAutoLayout;
    },
    setChartHasBeenLaidOut: (
      state,
      { payload: hasBeenLayouted }: PayloadAction<boolean>,
    ) => {
      state.chartHasBeenLaidOut = hasBeenLayouted;
    },
    setIsInitialFlowRenderComplete: (
      state,
      { payload: isInitialFlowRenderComplete }: PayloadAction<boolean>,
    ) => {
      state.isInitialFlowRenderComplete = isInitialFlowRenderComplete;
    },
    expandCanvasHeader: (state) => {
      state.isHeaderMinimized = false;
    },
    minimizeCanvasHeader: (state) => {
      state.isHeaderMinimized = true;
    },
    resetCanvasState: (state) => {
      state.chartHasBeenLaidOut = false;
      state.shouldAutoLayout = false;
      state.isHeaderMinimized = true;
    },
  },
});

export const {
  addCanvasNodes,
  clearGraph,
  expandCanvasHeader,
  minimizeCanvasHeader,
  moveCanvasNode,
  onEdgesChange,
  onNodesChange,
  resetCanvasState,
  setChartHasBeenLaidOut,
  setIsInitialFlowRenderComplete,
  setSelectedNodes,
  setShouldAutoLayout,
  toggleFlowNavVisible,
  updateGraph,
} = canvasSlice.actions;

export default canvasSlice.reducer;
