import { DateRangePicker as RSuiteDateRangePicker } from "rsuite";
import styled, { css } from "styled-components";

import themeColors from "styles/config/colors";
import themeSpacing from "styles/config/spacing";
import text from "styles/config/text";

export const DateRangePicker = styled(RSuiteDateRangePicker)`
  /* stylelint-disable selector-class-pattern */

  * {
    font-family: ${text.font.body};
    color: ${themeColors.textPrimary};
  }

  .rs-picker-toggle {
    font-size: ${text.size.body.body3};
    border-radius: ${themeSpacing.radiusSmall};

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      border-color: ${themeColors.borderBrand} !important;
    }
  }

  .rs-picker-toggle-active {
    border-color: ${themeColors.borderBrand} !important;
    box-shadow: none !important;
  }

  .rs-btn-close {
    &:hover {
      svg path {
        stroke: ${themeColors.iconBrand} !important;
      }
    }
  }
`;

export const DateRangePickerStyles = css`
  .rs-picker-daterange-menu {
    margin-top: ${themeSpacing.xs2};
    font-family: ${text.font.body};

    .rs-picker-daterange-header {
      display: none;
    }

    .rs-picker-daterange-predefined {
      gap: ${themeSpacing.xs3} !important;

      height: 323px;
      padding: ${themeSpacing.sm3};

      border-right: none;
      border-bottom: 1px solid ${themeColors.borderSubtle};

      .rs-btn-link {
        color: ${themeColors.textPrimary} !important;

        &:hover {
          color: ${themeColors.textBrand} !important;
        }
      }
    }

    .rs-calendar-header-title {
      color: ${themeColors.textPrimary};

      &:hover {
        background: ${themeColors.backgroundHover};
      }
    }

    .rs-calendar-header-error {
      &:hover,
      &:active,
      &:focus {
        background: ${themeColors.backgroundHover} !important;
      }
    }

    .rs-calendar-month-view
      .rs-calendar-header-title-date.rs-calendar-header-error {
      color: ${themeColors.textPrimary} !important;

      &:focus {
        color: ${themeColors.textPrimary} !important;
      }
    }

    .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
      box-shadow: inset 0 0 0 1px ${themeColors.borderBrand};
    }

    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
      background: ${themeColors.backgroundBrand};

      &:hover,
      &:focus,
      &:active {
        color: ${themeColors.textPrimaryInverse} !important;
        background: ${themeColors.backgroundBrand} !important;
      }
    }

    .rs-calendar-month-dropdown-cell .rs-calendar-month-dropdown-cell-content {
      &:hover,
      &:focus,
      &:active {
        color: ${themeColors.textBrand} !important;
        background: ${themeColors.backgroundBrandInverse} !important;
      }
    }

    .rs-picker-daterange-content {
      padding: ${themeSpacing.sm3};
      font-weight: ${text.weight.semibold};

      .rs-calendar-table-cell .rs-calendar-table-cell-content {
        &:hover {
          color: ${themeColors.textBrand};
          background: ${themeColors.backgroundBrandInverse};
        }

        &:active {
          color: ${themeColors.textPrimaryInverse};
          background: ${themeColors.backgroundBrand};
        }
      }

      .rs-calendar-table-cell {
        &:hover {
          .rs-calendar-table-cell-content {
            color: ${themeColors.textBrand};
            background: ${themeColors.backgroundBrandInverse};
          }
        }
      }

      .rs-calendar-table-cell-in-range::before {
        background: ${themeColors.backgroundBrandInverse};
      }

      .rs-calendar-month-dropdown-year-active {
        color: ${themeColors.textBrand};
      }

      .rs-calendar-month-dropdown-cell-active
        .rs-calendar-month-dropdown-cell-content {
        background: ${themeColors.backgroundBrand};
      }
    }

    .rs-calendar:first-child {
      border: none;
    }

    .rs-picker-toolbar {
      border-top: 1px solid ${themeColors.borderSubtle};

      .rs-picker-toolbar-right {
        .rs-btn-primary {
          height: 32px;
          padding: ${themeSpacing.xs2} ${themeSpacing.sm3};

          font-weight: ${text.weight.semibold};

          background: ${themeColors.backgroundBrand};
          border-radius: ${themeSpacing.radiusFull};
        }
      }
    }
  }
`;
