/* eslint-disable max-len */
import React, { FC } from "react";

import { BaseIcon, IconProps } from "./BaseIcon";

export const IntentsIcon: FC<IconProps> = (props) => {
  return (
    <BaseIcon {...props}>
      <path d="M8.787 20.692H27V7H5v16.785l3.787-3.093ZM3 28V7a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v13.692a2 2 0 0 1-2 2H9.5L3 28Z" />
    </BaseIcon>
  );
};
