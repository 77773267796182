import dayjs from 'dayjs';

import {
  BooleanOperator,
  EnvironmentType,
  MultiSelectionOperator,
  NumberOperator,
  ParsedFilterItem,
  StandardFilterType,
} from '@polyai/ui/components/molecules/Filter/Filter.constants';

const defaultConversationDate = new Date();
// convert date to UTC using current date - 7 days
const utcConversationDate = Date.UTC(
  defaultConversationDate.getFullYear(),
  defaultConversationDate.getMonth(),
  defaultConversationDate.getDate() - 7,
  0,
  0,
  0,
);

export const defaultConversationFilters: ParsedFilterItem[] = [
  {
    name: StandardFilterType.CLIENT_ENV,
    operator: MultiSelectionOperator.eq,
    value: EnvironmentType.LIVE,
    category: 'standard',
  },
  {
    name: StandardFilterType.CREATED_AT,
    operator: NumberOperator.ge,
    value: dayjs(new Date(utcConversationDate)).format('YYYY-MM-DD'),
    category: 'standard',
  },
  {
    name: StandardFilterType.IN_PROGRESS,
    operator: BooleanOperator.eq,
    value: 'false',
    category: 'standard',
  },
];
